<template>
  <section id="WindRoseDispatchReleaseSection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                <td colspan="18" style="text-align:center!important;" class="cls_002"><span class="cls_002">DISPATCH RELEASE</span></td>
              </tr>
              <tr style="height:15px;"></tr>
              <tr>
                <td colspan="18" style="text-align:center!important;" class="cls_002"><span class="cls_002">(COMPUTED {{ result.computed_at }}/MET PRGS 100012 100015Z/CFP {{ result.id }})</span></td>
              </tr>
              <tr>
                <td colspan="18" style="text-align:center!important;" class="cls_002"><span class="cls_002">{{result.flight.flight_release}} /{{result.generated_at_ddmmyy}} {{result.aircraft.fleet.name}}/{{result.aircraft.aircraft.tail_number}}/<span class="missingdata">SELCAL</span> {{result.flight.route.departure}}({{result.flight.route.departure_iata}}){{result.flight.etd}}/{{result.flight.route.destination}}({{result.flight.route.destination_iata}}) {{result.flight.eta}} {{ result.flight.flight_type }}/<span class="missingdata">scheduled S/N</span></span></td>
              </tr>
                <tr style="height:30px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
               <tr> 
                <td colspan="18" class="cls_002 missingdata"><span class="cls_002">PAX A000/M000/F000/C000/I000 0KGS C/M 0KGS (A89/M98/F84/C48/I0 KGS)</span></td>
               </tr>
                <tr style="height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
               <tr>
                <td class="cls_002"><span class="cls_002">PLD</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">(CALC.)</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{result.flight.payload_weight}} KGS</span></td>
                <td colspan="3"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">TRIP</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.trip_time }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.trip_fuel }} KGS</span></td>
               </tr>
               <tr>
                <td class="cls_002"><span class="cls_002">DOW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">(CALC.)</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{ result.aircraft.eow }} KGS</span></td>
                <td colspan="3"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">EXTRA</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.xfuel_time }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.xfuel_fuel }} KGS</span></td>
               </tr>
               <tr>
                <td class="cls_002"><span class="cls_002">ZFW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">(CALC.)</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{ result.flight.zero_fuel_weight }} KGS</span></td>
                <td colspan="3"></td>
                <td colspan="3" class="cls_002 missingdata"><span class="cls_002">ADDITIONAL</span></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">00.00</span></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">0 KGS</span></td>
               </tr>
               <tr>
                <td class="cls_002"><span class="cls_002">TOW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">(CALC.)</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{result.plan_header.brwt}} KGS</span></td>
                <td colspan="3"></td>
                <td colspan="3"><span class="cls_002">TOTAL RESERVE</span></td>
                <td colspan="2"></td>
                <td colspan="2" class="cls_002 right">
                  <span class="cls_002">
                    {{ result.aircraft.fleet.contingency_weight }} KGS
                  </span>
                  <!-- Se indicó en Respuestas Windrose 2020.08.24 que TOTAL RESERVE era Contingency weight-->
                </td>
               </tr>
               <tr>
                <td class="cls_002"><span class="cls_002">LDW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">(CALC.)</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{ result.plan_header.ldgwt }} KGS</span></td>
                <td colspan="3"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">FOB</span></td>
                <td colspan="2"></td>
                <td colspan="2" class="cls_002 right">{{ result.nav_logs[1].footer.fob }}<span class="comentario"><br>Es el fuel on board calculado para el nav log alternativo 1, estará bien?</span></td>
               </tr>
                <tr style="height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
               <tr>
                <td></td>
                <td class="cls_002"><span class="cls_002">DEP</span></td>
                <td class="cls_002"><span class="cls_002">TALT</span><span class="comentario"><br>Altitud de transición</span></td>
                <td class="cls_002"><span class="cls_002">DES</span></td>
                <td class="cls_002"><span class="cls_002">1ALT</span></td>
                <td class="cls_002"><span class="cls_002">2ALT</span></td>
                <td class="cls_002"><span class="cls_002">3ALT</span></td>
                <td class="cls_002"><span class="cls_002">4ALT</span></td>
                <td colspan="3"></td>
                <td colspan="7" class="cls_002"><span class="cls_002">CREW: CAP. {{result.flight.captain}}</span></td>
               </tr>
               <tr>
                <td class="cls_002"><span class="cls_002">AD</span></td>
                <td class="cls_002"><span class="cls_002">{{result.flight.route.departure}}</span></td>
                <td class="cls_002"><span class="cls_002">NR</span></td>
                <td class="cls_002"><span class="cls_002">{{result.flight.route.destination}}</span></td>
                <td class="cls_002"><span class="cls_002">{{result.alternate_airports[0].route.destination}}</span></td>
                <td class="cls_002"><span class="cls_002">{{result.alternate_airports[1] ? result.alternate_airports[1].route.destination : 'NR'}}</span></td>
                <td class="cls_002"><span class="cls_002">{{result.alternate_airports[2] ? result.alternate_airports[2].route.destination : 'NR'}}</span></td>
                <td class="cls_002"><span class="cls_002">{{result.alternate_airports[3] ? result.alternate_airports[3].route.destination : 'NR'}}</span></td>
                <td colspan="3">
                <td colspan="7" class="cls_002"><span class="cls_002">F/O.</span></td>
               </tr>
               <tr>
                <td colspan="11"></td>
                <td colspan="7" class="cls_002"><span class="cls_002">CREW COMP.: {{ result.aircraft.version.version_code }}  <span class="missingdata">DOI: ...</span></span></td>
               </tr>
               <tr style="height:10px;">
                    <td colspan="18"></td>
               </tr>
               <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
               </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">ROUTE ID {{ result.flight.route.id }}<span class="comentario"><br>Route.id nuestro?</span></span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002">
                  <span class="cls_002">
                    <span v-for="waypoint in result.flight.route.waypoints" :key="waypoint.id">
                      {{waypoint.name}} {{waypoint.airway}}
                    </span>
                  </span></td>
              </tr>
               <tr style="height:10px;">
                    <td colspan="18"></td>
               </tr>
               <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
               </tr>
               <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">NTM INFORMATION/METEOROLOGICAL DOCS/BRIEFING DOCS ARE ATTACHED TO THE CFP</span></td>
               </tr>
               <tr style="height:10px;">
                    <td colspan="18"></td>
               </tr>
               <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
               </tr>
               <tr class="comentario">
                   <td colspan="18">Contact Data (no lo tenemos)</td>
              </tr>
               <tr class="comentario">
                   <td colspan="18">DMI (no lo tenemos)</td>
              </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "WindRoseDispatchRelease",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style>
@media print
{
  #WindRoseDispatchReleaseSection {
    font-size: 16px;
  }
}
</style>
