<template>
  <section id="LatamAlternateSummarySection">
     <!-- ALTERNATE SUMMARY -->
    <div class="columns" style="border-top:solid">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="13" class="cls_003" style="text-align:center!important;"><span class="cls_003">ALTERNATE SUMMARY</span></td>
        </tr>
        <tr> 
          <td class="cls_002"><span class="cls_002">ALTN</span></td>
          <td class="cls_002 right"><span class="cls_002">DIST</span></td>
          <td class="cls_002"><span class="cls_002">ROUTE</span></td>
          <td colspan="5"></td>
          <td class="cls_002 right"><span class="cls_002">FL</span></td>
          <td class="cls_002 right"><span class="cls_002">WC</span></td>
          <td class="cls_002 right"><span class="cls_002">TIME</span></td>
          <td class="cls_002 right"><span class="cls_002">FUEL</span></td>
          <td class="cls_002 right"><span class="cls_002">MFOD</span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">SCCF</span></td>
          <td class="cls_002 right"><span class="cls_002">145</span></td>
          <td colspan="6">
              <span class="cls_002">SCFA&nbsp;&nbsp;</span>
              <span class="cls_002">TATIO1B&nbsp;&nbsp;</span>
              <span class="cls_002">TATIO&nbsp;&nbsp;</span>
              <span class="cls_002">TATIO2B&nbsp;&nbsp;</span>
              <span class="cls_002">SCCF&nbsp;&nbsp;</span>
          </td>
          <td class="cls_002 right"><span class="cls_002">290</span></td>
          <td class="cls_002 right"><span class="cls_002">T010</span></td>
          <td class="cls_002 right"><span class="cls_002">0028</span></td>
          <td class="cls_002 right"><span class="cls_002">1325</span></td>
          <td class="cls_002 right"><span class="cls_002">2398</span></td>
        </tr>
      </table>
    </div>
    <div class="columns">
      <table class="tabla-sin-bordes" style="margin-top:15px;">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="13" class="cls_002"><span class="cls_002">Additional Alternates</span></td>          <td></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">ALTN</span></td>
          <td class="cls_002 right"><span class="cls_002">DIST</span></td>
          <td class="cls_002"><span class="cls_002">ROUTE</span></td>
          <td colspan="5"></td>
          <td class="cls_002 right"><span class="cls_002">FL</span></td>
          <td class="cls_002 right"><span class="cls_002">WC</span></td>
          <td class="cls_002 right"><span class="cls_002">TIME</span></td>
          <td class="cls_002 right"><span class="cls_002">FUEL</span></td>
          <td class="cls_002 right"><span class="cls_002">MFOD</span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">SCDA</span></td>
          <td class="cls_002 right"><span class="cls_002">219</span></td>
          <td colspan="6"></td>
          <td class="cls_002 right"><span class="cls_002">300</span></td>
          <td class="cls_002 right"><span class="cls_002">T004</span></td>
          <td class="cls_002 right"><span class="cls_002">0039</span></td>
          <td class="cls_002 right"><span class="cls_002">1669</span></td>
          <td class="cls_002 right"><span class="cls_002">2742</span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">SCAT</span></td>
          <td class="cls_002 right"><span class="cls_002">274</span></td>
          <td colspan="6"></td>
          <td class="cls_002 right"><span class="cls_002">390</span></td>
          <td class="cls_002 right"><span class="cls_002">H012</span></td>
          <td class="cls_002 right"><span class="cls_002">0047</span></td>
          <td class="cls_002 right"><span class="cls_002">2039</span></td>
          <td class="cls_002 right"><span class="cls_002">3112</span></td>
        </tr> 
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamAlternateSummary",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
};
</script>
<style>
@media print
{
  #LatamAlternateSummarySection {
    font-size: 16px;
  }
}
</style>
