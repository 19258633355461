<template>
  <section id="LatamHeaderSection">
    <div class="columns">
      <div class="column is-12 cls_002 has-text-centered">
        <span class="cls_002">LATAM FLIGHT RELEASE</span>
      </div>
    </div>
    <div class="columns">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr> 
          <td><span class="cls_002">{{result.flight.flight_release}}</span></td>
          <td colspan="2"><span class="cls_002">{{result.generated_at_dmy}}</span></td>
          <td colspan="2"><span class="cls_002">{{result.flight.route.departure}}</span></td>
          <td colspan="2"><span class="cls_002">{{result.flight.route.destination}}</span></td>
          <td colspan="2"><span class="cls_002">{{result.aircraft.tail_number}}</span></td>
          <td><span class="cls_002">{{result.aircraft.aircraft.weight_unit}}</span></td>
          <td nowrap="nowrap"><span class="cls_002">{{result.aircraft.fleet.name}}</span></td>
          <td colspan="2"><span class="cls_002">{{result.aircraft.aircraft.motor}}</span></td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td colspan="2"><span class="cls_002">{{result.flight.route.departure_iata}}</span></td>
          <td colspan="2"><span class="cls_002">{{result.flight.route.destination_iata}}</span></td>
          <td class="missingdata" colspan="2"><span class="cls_002">DEG +2.4</span></td>
          <td><span class="cls_002">{{ result.flight.flight_type }}</span></td>
          <td class="missingdata"><span class="cls_002">RLS02</span></td>
          <td colspan="2"><span class="cls_002">07:37 05JAN2018</span></td>
        </tr>
      </table>
    </div> 
    <div class="columns" style="margin-top:30px;">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td class="missingdata" colspan="3"><span class="cls_002">STD 05JAN 09:28</span></td>
          <td class="missingdata" colspan="4"><span class="cls_002">ETD 09:28/09:43<span class="comentario">(margen de error?)</span></span></td>
          <td colspan="4"><span class="cls_002">ATD_____:_____</span></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="missingdata" colspan="3"><span class="cls_002">STA 05JAN 11:28<span class="comentario">A la fecha le sumo 1 si hora inferior a departure, horas maximas vuelo? escalas?</span></span></td>
          <td class="missingdata" colspan="4"><span class="cls_002">ETA 11:21/11:26<span class="comentario">(margen de error?)</span></span></td>
          <td coslpan="4"><span class="cls_002">ATA_____:_____</span></td>
          <td colspan="2"></td>
        </tr>
      </table>
    </div>
    <div class="columns" style="margin-top:15px;">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="6"><span class="cls_002">PIC: {{result.flight.captain}}</span></td>
          <td colspan="3"><span class="cls_002">SIGNATURE:__________________________</span></td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="6"><span class="cls_002">FLT DISP: {{result.flight.flight_dispatcher}}</span></td>
          <td colspan="7"></td>
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamHeader",
  props: ['result'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #LatamHeaderSection {
    font-size: 16px;
  }
}
</style>
