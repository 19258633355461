<template>
  <section id="LatamWindSummarySection">
    <div class="columns is-multiline is-mobile">
      <div class="column is-one-quarter has-text-centered"
           v-for="(pointsByName, index) in result.wind_summary"
           :key="'wind_summary_' + index">
        <h2 class="is-4">{{ Object.keys(pointsByName)[0] }}</h2>
        <div v-for="(pointsByFlv, index) in pointsByName" :key="'wind_summary_named_' + index">
          <div v-for="point in pointsByFlv" class="is-size-6">
            {{ point.flvl }}&nbsp;{{ point.wind }}&nbsp;{{ point.temp }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "LatamWindSummary",
  props: ['result'],
  watch: {
    'result': function (plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style scoped>
#LatamWindSummarySection {
  font-size: 20px;
}

#LatamWindSummarySection .columns {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 2;
}

#LatamWindSummarySection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 2px;
}

#LatamWindSummarySection .tabla-sin-bordes {
  border-bottom: 0px !important;
  border: 0px !important;
}

#LatamWindSummarySection .tabla-sin-bordes th, .tabla-sin-bordes td {
  border: 1px !important;
  padding-top: 0em;
  padding-bottom: 0em;
}

#LatamWindSummarySection .tabla-sin-bordes tr td {
  border: 1px !important;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 1em;
  padding-right: 1.1em;
  text-align: right;
}

#LatamWindSummarySection .tabla-sin-bordes tr td:first-child {
  font-weight: bolder;
  text-align: left !important;
}

@media print {
  #LatamWindSummarySection {
    font-size: 14px;
  }
}
</style>
