<template>
  <section id="LatamWeightAnalysisSection">
    <div class="columns missingdata" style="border-top:solid;">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="13" class="cls_003" style="text-align:center!important;"><span class="cls_003">WEIGHT ANALYSIS</span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">A -</span></td>
          <td colspan="10" class="cls_002"><span class="cls_002">MAX ALLOWED TAKEOFF WEIGHT (MALTOW)</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">77000</span></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="12" class="cls_002"><span class="cls_002">STRUCTURAL</span></td>
        </tr>
        <tr>
          <td v-for="i in 13" :key="i">. .&nbsp;&nbsp;&nbsp;&nbsp;. .</td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">B -</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">MAX TAXI WEIGHT (MALTXW)</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">77000</span></td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="7" class="cls_002"><span class="cls_002">TAXI OUT FUEL</span></td>
          <td class="cls_002"><span class="cls_002">MINUS</span></td>
          <td class="cls_002 right"><span class="cls_002">180</span></td>
          <td class="cls_002"><span class="cls_002">EQ</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">76820</span></td>
        </tr>
        <tr height="30px;"></tr>
        <tr>
          <td class="cls_002"><span class="cls_002">C -</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">MAX ZERO FUEL WEIGHT (MAXZFW)</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">62500</span></td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="7" class="cls_002"><span class="cls_002">TAKEOFF FUEL</span></td>
          <td class="cls_002"><span class="cls_002">PLUS</span></td>
          <td class="cls_002 right"><span class="cls_002">6586</span></td>
          <td class="cls_002"><span class="cls_002">EQ</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">69086</span></td>
        </tr>
        <tr height="30px;"></tr>
        <tr>
          <td class="cls_002"><span class="cls_002">D -</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">MAX ALLOWED LANDING WEIGHT (MALLAW)</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">66000</span></td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="7" class="cls_002"><span class="cls_002">TRIP FUEL</span></td>
          <td class="cls_002"><span class="cls_002">PLUS</span></td>
          <td class="cls_002 right"><span class="cls_002">3761</span></td>
          <td class="cls_002"><span class="cls_002">EQ</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">69761</span></td>
        </tr>
        <tr height="30px;"></tr>
        <tr>
          <td class="cls_002"><span class="cls_002">E -</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">MAX ALLOWED TAKEOFF WEIGHT (MALTOW)</span></td>
          <td colspan="2"></td>
          <td class="cls_002"><span class="cls_002">EQ</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">77000</span></td>
        </tr>
        <tr height="30px;"></tr>
        <tr>
          <td class="cls_002"><span class="cls_002">F -</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">OFP LIMITED ZERO FUEL WEIGHT (ZFW)</span></td>
          <td colspan="5"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="7" class="cls_002"><span class="cls_002">FUEL TANKAGE</span></td>
          <td class="cls_002"><span class="cls_002">PLUS</span></td>
          <td class="cls_002 right"><span class="cls_002">6766</span></td>
          <td class="cls_002"><span class="cls_002">EQ</span></td>
        </tr>
        <tr>
          <td v-for="i in 13" :key="i">. .&nbsp;&nbsp;&nbsp;&nbsp;. .</td>
        </tr>
        <tr>
          <td colspan="11" class="cls_002"><span class="cls_002">OPERATIONAL ALLOWED WEIGHT FOR TAKEOFF</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">69086</span></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="8" class="cls_002"><span class="cls_002">(THE LEAST OF B C D E F)</span></td>
        </tr>
        <tr>
          <td v-for="i in 13" :key="i">. .&nbsp;&nbsp;&nbsp;&nbsp;. .</td>
        </tr>
        <tr>
          <td colspan="11" class="cls_002"><span class="cls_002">OPERATIONAL ALLOWED WEIGHT FOR TAKEOFF</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">69086</span></td>
        </tr>
        <tr height="30px;"></tr>
        <tr>
          <td colspan="4"></td>
          <td colspan="4" class="cls_002 right"><span class="cls_002">TAKEOFF FUEL</span></td>
          <td colspan="3"></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">6586</span></td>
        </tr>
        <tr>
          <td colspan="4"></td>
          <td colspan="4" class="cls_002 right"><span class="cls_002">ALLOWED TRAFFIC LOAD</span></td>
          <td colspan="3"></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">19485</span></td>
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamWeightAnalysis",
  props: ['result'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #LatamWeightAnalysisSection {
    font-size: 16px;
  }
}
</style>
