<template>
  <section id="RuslineAlternateSummarySection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 13" :key="index" class="col-1">
              <tr>
                <td colspan="5"></td>
                <td colspan="3" class="cls_002" style="text-align:center!important"><span class="cls_002">ALTERNATES</span></td>
                <td colspan="5"></td>
              </tr>
                <tr style="height:10px;">
                    <td colspan="13"></td>
                </tr>
                <tr>
                    <td colspan="5"></td>
                    <td colspan="3">
                        <table class="tabla-sin-bordes">
                        <tr style="border-top:dashed;border-width:3px;height:10px;">
                            <td style="min-width:100%;"></td>
                        </tr>
                        </table>
                    </td>
                    <td colspan="5"></td>
                </tr>
              <tr>
                <td colspan="4"> 
                <td class="cls_002 right missingdata"><span class="cls_002">MSA<span class="comentario"><br>Minimum safe altitude? tenemos esto?</span></span></td>
                <td class="cls_002 right missingdata"><span class="cls_002">TTK</span></td>
                <td class="cls_002 right"><span class="cls_002">DIST</span></td>
                <td class="cls_002 right"><span class="cls_002">FL</span></td>
                <td class="cls_002 right"><span class="cls_002">W/C</span></td>
                <td class="cls_002 right"><span class="cls_002">TIME</span></td>
                <td class="cls_002 right"><span class="cls_002">FUEL</span></td>
                <td colspan="2"></td>
               </tr>
               <template v-for="(navLog, index) in navLogs">
                <tr v-if="index > 1" :key="navLog.footer.apto + index">
                    <td colspan="4" class="cls_002"><span class="cls_002">ALTERNATE - {{ index }}  {{ navLog.footer.apto }}</span></td>
                    <td class="cls_002 right missingdata"><span class="cls_002">026</span></td>
                    <td class="cls_002 right missingdata"><span class="cls_002">341</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ navLog.footer.dist }}</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ navLog.footer.fl }}</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ navLog.footer.average_wcomp }}</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ navLog.footer.time }}</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ navLog.footer.t_fuel }}</span></td>
                    <td colspan="2"></td>
                </tr>
               </template>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "RuslineAlternateSummary",
  props: ['result', 'navLogs'],
  data() {
    return {
    }
  },
  computed: {

  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #RuslineAlternateSummarySection {
    font-size: 16px;
  }
}
</style>
