<template>
  <section id="NavigationLogSection">
    <table class="table is-fullwidth is-hoverable">
      <caption v-if="isAlternate">ALTERNATE ROUTE {{ alternateIndex }}</caption>
      <thead>
        <tr>
          <th><abbr title="Position">NAME<br>FREQ</abbr></th>
          <th><abbr title="Position">LAT<br>FLVL&nbsp;-&nbsp;WIND</abbr></th>
          <th><abbr title="Position">LON<br>TEMP</abbr></th>
          <th><abbr title="Position">WSR<br>WCOMP</abbr></th>
          <th><abbr title="Position">MCRS<br>MHDG&nbsp;-&nbsp;GS</abbr></th>
          <th><abbr title="Position">MACH<br>TAS</abbr></th>
          <th><abbr title="Position">ZND<br>DIST</abbr></th>
          <th><abbr title="Position">ZNT<br>TIME</abbr></th>
          <th><abbr title="Position">ETO/RTO<br>ATO</abbr></th>
          <th><abbr title="Position">FUEL<br>REMAN</abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(point, index) in navLog.points" :key="index">
          <td>
            <b-tag class="is-medium" style="width:100%;"><strong>{{ point.name }}</strong></b-tag><br>
            <span v-if="point.freq > 0">{{ point.freq }}</span>
          </td>
          <td>
            {{ point.latitude }}<br>
            <span class="is-pulled-left">{{ point.flvl }}</span>&nbsp;&nbsp;&nbsp;<span >{{ point.wind }}</span>
          </td>
          <td>
            {{ point.longitude }}<br>
            {{ point.temp }}
          </td>
          <td>
            {{ point.wsr }}<br>
            {{ point.wcomp }}
          </td>
          <td>
            {{ point.mcrs }}<br>
            {{ point.mhdg }}&nbsp;&nbsp;&nbsp;{{ point.gs }}
          </td>
          <td>
            {{ point.mach }}
            <br>{{ point.tas }}
          </td>
          <td>
            {{ point.znd }}<br>
            {{ point.dist }}
          </td>
          <td>
            {{ point.znt }}<br>
            {{ point.time }}
          </td>
          <td>_____/_____<br>___________</td>
          <td>{{ point.ttl_fuel }}<br>___________</td>
        </tr>
      </tbody>
    </table>

  </section>
</template>
<script>

export default {
  name: "NavigationLog",
  props: ['navLog', 'navKey'],
  computed: {
    isAlternate() {
      return this.navLog.apto !== null;
    },
    alternateIndex() {
      return Math.ceil((this.navKey + 1) / 2);
    }
  }
};
</script>
<style scoped>
#NavigationLogSection {
  font-size: 16px;
}
#NavigationLogSection .card-header{
  background-color: lightgrey;
}
#NavigationLogSection .card {
  min-height: 100%;
}
@media print
{
  #NavigationLogSection {
    font-size: 15px;
  }
}
</style>
