<template>
  <section id="RuslineHeaderSection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">OFP INPUT MESSAGE DATE TIME REF {{result.generated_at_dhs}}</span></td>
              </tr>
              <tr> 
                <td colspan="18" class="cls_002"><span class="cls_002">START OF OFP/{{result.flight.flight_number}}/{{result.flight.route.departure}} {{result.flight.route.destination}}/DATABASE: {{result.flight.route.code}}/{{result.id}}<span class="comentario"> Código temporal/plan.id</span></span></td>
              </tr>
              <tr style="height:20px"></tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">RUSLINE AIRLINES <span class="missingdata">FORMAT/JEPPESEN</span> DATAPLAN REQUEST NO. {{result.id}}</span></td>
              </tr>
              <tr>
                <td colspan="13"></td>                  
                <td colspan="2" class="cls_002"><span class="cls_002">DIST/T</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">{{result.flight.distance_to_go}}NM/{{result.flight.distance_to_go_km}}KM</span></td>
              </tr>
              <tr>
                <td class="cls_002"><span class="cls_002">FLT</span></td>
                <td colspan="4" class="cls_002"><span class="cls_002">RLU835</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{result.flight.route.departure}}/{{result.flight.route.departure_iata}}</span></td>
                <td class="cls_002"><span class="cls_002">TO</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">{{result.flight.route.destination}}/{{result.flight.route.destination_iata}}</span></td>
                <td colspan="2"></td>
                <td colspan="2" class="cls_002 missingdata"><span class="cls_002">AIR/D</span></td>
                <td colspan="3" class="cls_002 missingdata"><span class="cls_002">0840NM/1555KM</span></td>
              </tr>
              <tr>
                <td class="cls_002"><span class="cls_002">CRZ</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">{{result.flight.cruise_speed}}</span></td>
                <td class="cls_002"><span class="cls_002">ELEV</span></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">768FT</span></td>
                <td></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">837FT</span></td>
                <td colspan="3"></td>
                <td colspan="2" class="cls_002"><span class="cls_002">AVG FF</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">{{result.plan_header.avg_ff}}<span class="comentario"><br>Trip Fuel / Trip time, en minutos? horas?No me cuadra (o no sé cuadrar) esa división en el "rusline archivo explained"</span></span></td>
              </tr>
              <tr>
                <td colspan="13"></td>
                <td colspan="2" class="cls_002"><span class="cls_002">W/C</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">{{result.nav_logs[0].average_wcomp}}</span></td>
              </tr>
              <tr>
                <td colspan="3" class="cls_002"><span class="cls_002">DATE</span></td>
                <td colspan="4" class="cls_002"><span class="cls_002">AIRCRAFT/REG</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">DEG.FACTOR</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ result.aircraft.aircraft.tail_number }}</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">/{{ result.aircraft.aircraft.motor }}</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">9.0 PCT</span></td>
              </tr>
              <tr> 
                <td colspan="3" class="cls_002"><span class="cls_002">{{ result.generated_at_dmyslash }}</span></td>
                <td colspan="4" class="cls_002"><span class="cls_002">{{ result.aircraft.aircraft.mmid }}/{{ result.aircraft.aircraft.tail_number }}<span class="comentario"><br>MMID/Tail Number</span></span></td>
                <td colspan="11"></td>
              </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "RuslineHeader",
  props: ['result'],
  data() {
    return {
    }
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
};
</script>
<style>
@media print
{
  #RuslineHeaderSection {
    font-size: 16px;
  }
}
</style>
