<template>
  <section id="WindRoseTemperatureAltitudeCorrectionSection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                <td colspan="6">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td colspan="6" class="cls_002 center"><span class="cls_002">ALTITUDE CORRECTION</span></td>
                <td colspan="6">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="margin-top:19px;border-bottom: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">ALTITUDE CORRECTION MUST BE APPLIED FOR ISA-15C (OAT 0C) AND BELOW.</span></td>
              </tr>
              <tr style="height:15px;"></tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">TARGET ALTITUDE AND DISTANCE TO BE TAKEN FROM APPROPRIATE APPROACH CHART.</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">------------------------------+-------+-------+-------+-------+-------+-------+-------+</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002">
                    <span class="cls_002">
                        DISTANCE NM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+
                    </span>
                </td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">------------------------------+-------+-------+-------+-------+-------+-------+-------+</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002">
                    <span class="cls_002">
                        TARGET ALT. (HEIGHT) FT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+
                    </span>
                </td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">------------------------------+-------+-------+-------+-------+-------+-------+-------+</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002">
                    <span class="cls_002">
                        CALCULATED ALT. (HEIGHT)FT:&nbsp;&nbsp;&nbsp;:
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+
                    </span>
                </td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">------------------------------+-------+-------+-------+-------+-------+-------+-------+</span></td>
              </tr>
              <tr style="height:30px;"></tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "WindRoseTemperatureAltitudeCorrection",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    deg(deg) {
      if (deg < 0) {
        return 'M'+Math.abs(deg);
      }
      if (deg > 0) {
        return 'P'+deg;
      }
      return 0;
    }
  }
};
</script>
<style>
@media print
{
  #WindRoseTemperatureAltitudeCorrectionSection {
    font-size: 16px;
  }
}
</style>
