<template>
  <section id="LatamMelCdlItemsSection">
    <div class="columns" style="border-top:solid">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="13" class="cls_003" style="text-align:center!important;"><span class="cls_003">MEL/CDL ITEMS</span></td>
        </tr>
        <tr>
          <td colspan="13" class="cls_002" style="text-align:center!important;"><span class="cls_002">NO DATA</span></td>
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamMelCdlItems",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #LatamMelCdlItemsSection {
    font-size: 16px;
  }
}
</style>
