<template>
  <section id="RuslineNavigationLogSection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <thead>
              <tr>
                <td colspan="16">
                </td>
                <td colspan="2" style="text-align:center!important;" class="cls_002"><span class="cls_002">FUEL</span></td>
              </tr>
              <tr>
                <td colspan="2" class="cls_002"><span class="cls_002">AWY</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">ROUTE</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">LAT</span></td>
                <td class="cls_002 missingdata"><span class="cls_002">MTK<span class="comentario"><br>Air metack??</span></span></td>
                <td class="cls_002"><span class="cls_002">DIST</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">FL/SR</span></td>
                <td class="cls_002"><span class="cls_002">TAS</span></td>
                <td class="cls_002"><span class="cls_002">GS</span></td>
                <td class="cls_002"><span class="cls_002">WD</span></td>
                <td class="cls_002"><span class="cls_002">ET</span></td>
                <td class="cls_002"><span class="cls_002">EST</span></td>
                <td class="cls_002"><span class="cls_002">ETO</span></td>
              </tr>
              <tr>
                <td colspan="2" class="cls_002 missingdata"><span class="cls_002">MORA<span class="comentario"><br>Minimum off route altitude??</span></span></td>
                <td class="cls_002 missingdata"><span class="cls_002">RNT<span class="comentario"><br>Parece repetir lo que dice route ( point . name)</span></span></td>
                <td colspan="2" class="cls_002 missingdata right"><span class="cls_002">FREQY<span class="comentario"><br>En el ejemplo que tenemos parece que no lo completan, qué es?</span></span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">LONG</span></td>
                <td class="cls_002 missingdata"><span class="cls_002">TTK</span></td>
                <td class="cls_002"><span class="cls_002">DTGO<span class="comentario"><br>dist to go? en pdf dice que es elevation</span></span></td>
                <td colspan="2" class="cls_002 missingdata"><span class="cls_002">T/DEV<span class="comentario"><br>&nbsp;&nbsp;&nbsp;&nbsp;Temp y qué mas? será wcomp?</span></span></td>
                <td class="cls_002"><span class="cls_002">MAC</span></td>
                <td class="cls_002 missingdata"><span class="cls_002">FIR</span></td>
                <td class="cls_002"><span class="cls_002">WS</span></td>
                <td class="cls_002"><span class="cls_002">TT</span></td>
                <td class="cls_002"><span class="cls_002">ACT</span></td>
                <td class="cls_002"><span class="cls_002">ATO</span></td>
              </tr>
              <tr>
                <td colspan="15"></td>
                <td class="cls_002"><span class="cls_002">DT</span></td>
                <td class="cls_002"><span class="cls_002">USED</span></td>
                <td></td>
              </tr>
            </thead>
              <!-- INICIO -->
            <tr style="height:10px;">
                <td colspan="18"></td>
            </tr>
            <tr style="border-top:dashed;border-width:3px;height:10px;">
                <td colspan="18"></td>
            </tr>
            <tr>
                <td colspan="2" class="cls_002"><span class="cls_002">USSS</span></td>
                <td colspan="4" class="cls_002"><span class="cls_002">KOLTSOVO</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">DEPART</span></td>
                <td colspan="7"></td>
            </tr>
            <tr style="height:10px;">
                <td colspan="18"></td>
            </tr>
            <tr style="border-top:dashed;border-width:3px;height:10px;">
                <td colspan="18"></td>
            </tr>
            <tr>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">KOLTSOVO</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">N5644.6</span></td>
                <td colspan="8"></td>
                <td class="cls_002 right"><span class="cls_002">6.2</span></td>
                <td class="cls_002"><span class="cls_002">..</span></td>
            </tr>
            <tr>
                <td colspan="5"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">E06048.2</span></td>
                <td></td>
                <td class="cls_002 right"><span class="cls_002">0897</span></td>
                <td colspan="6"></td>
                <td class="cls_002"><span class="cls_002">..</span></td>
                <td class="cls_002"><span class="cls_002">...</span></td>
            </tr>
            <tr> 
                <td colspan="15"></td>
                <td class="cls_002"><span class="cls_002">...</span></td> 
                <td class="cls_002"><span class="cls_002">0.1</span></td>
                <td></td>
            </tr>
            <tr class="comentario">
                <td colspan="18">Lo de arriba vendría siendo equivalente a nuestra información de que sigue a continuación, del airport o el primer punto que tenemos en el navigation_log.  Por qué no marcaban mas información ellos? no se marca? nosotros tenemos la información para completarlo, es otro punto distinto al airport de departure? o no en todos los casos se tiene la información?,  La primera fila sería como un inicio del log solamente? la que dice "DEPARTURE" las dudas son mas que nada con la 2da y 3era filas de arriba, que tienen poca información</td>
            </tr>
            <template v-for="(point, index) in navLog.points">
                <tr :key="point.name + 'tr1'" style="height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr :key="point.name + 'tr2'" style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr :key="point.name + 'tr3'">
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.airway == '' ? '...' : point.airway }}</span></td>
                    <td colspan="3" class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                    <td colspan="3" class="cls_002"><span class="cls_002">{{ point.latitude }}</span></td>
                    <td class="cls_002 missingdata"><span class="cls_002">248</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ point.znd }}</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? point.mach : point.flvl}}/{{ point.wsr }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.tas }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.gs }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ Math.round(point.wspeed) }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.znt }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ (point.ttl_fuel / 1000).toFixed(1) }}</span></td>
                    <td class="cls_002"><span class="cls_002">..</span></td>
                </tr>
                <tr :key="point.name + 'tr4'">
                    <td colspan="2" class="cls_002"><span class="cls_002 missingdata">...</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                    <td colspan="2" class="missingdata"></td>
                    <td colspan="3" class="cls_002"><span class="cls_002">{{ point.longitude }}</span></td>
                    <td class="cls_002 missingdata"><span class="cls_002">263</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ point.distance_to_go }}</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.temp }}/{{ point.wcomp }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.mach }}</span></td>
                    <td class="missingdata"></td>
                    <td class="cls_002"><span class="cls_002">{{ point.wsr }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.time }}</span></td>
                    <td class="cls_002"><span class="cls_002">..</span></td>
                    <td class="cls_002"><span class="cls_002">...</span></td>
                </tr>
                <tr :key="point.name + 'tr5'">
                    <td colspan="15"></td>
                    <td class="cls_002"><span class="cls_002">...</span></td>
                    <td class="cls_002"><span class="cls_002">0.1</span></td>
                    <td></td>
                </tr>
            </template>
            <tr style="height:10px;">
                <td colspan="18"></td>
            </tr>
            <tr style="border-top:dashed;border-width:3px;height:10px;">
                <td colspan="18"></td>
            </tr>
            <tr class="missingdata">
                <td colspan="18" class="cls_002"><span class="cls_002">GREAT CIRCLE DISTANCE 00807 NM.</span></td>
            </tr>
            <tr class="missingdata">
                <td colspan="18" class="cls_002"><span class="cls_002">FLT PLAN BASED ON 0312UK  PROGS</span></td>
            </tr>
            <tr style="height:10px;">
                <td colspan="18"></td>
            </tr>
            <tr style="border-top:dashed;border-width:3px;height:10px;">
                <td colspan="18"></td>
            </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "RuslineNavigationLog",
  props: ['result', 'navLog', 'navKey'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style>
@media print
{
  #RuslineNavigationLogSection {
    font-size: 16px;
  }
}
</style>
