<template>
  <section id="RuslineTimeAndFuelSection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                <td colspan="10" class="cls_002"><span class="cls_002">NOTE THAT ALL SPEEDS/DISTANCES IN KTS/NMS</span></td>
                <td colspan="4" class="cls_002"><span class="cls_002">(ALL WEIGHTS KGS)</span></td>
              </tr>
              <tr style="height:15px;"></tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">ALTN {{ result.alternate_airports[0].route.destination }} DIST</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.nav_logs[1].distance_to_go }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">TIME</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">FUEL</span></td>
                <td class="cls_002"><span class="cls_002">OWE</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.aircraft.eow }}</span></td>
                <td class="cls_002"><span class="cls_002">PYLD</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.flight.payload_weight}}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">APLD ..  ..</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">FL {{ result.flight.flight_level }} WC {{ result.nav_logs[0].average_wcomp }}</span></td>
                <td colspan="2"></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.alternate_time) }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.alternate_fuel }}</span></td>
                <td class="cls_002"><span class="cls_002">EZFW</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.flight.zero_fuel_weight }}</span></td>
                <td class="cls_002"><span class="cls_002">MZFW</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.aircraft.aircraft.mzfw }}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">AZFW ..  ..</span></td>
              </tr>
              <tr> 
                <td colspan="4" class="cls_002"><span class="cls_002">MFT HOLD ALTN</span></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">1500<span class="comentario"><br>(Meter Fix Crossing?)</span></span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{formatTime(result.plan_header.holding_time)}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.holding_fuel}}</span></td>
                <td class="cls_002"><span class="cls_002">ETOW</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.brwt}}</span></td>
                <td class="cls_002"><span class="cls_002">MTOW</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.aircraft.aircraft.mtow}}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">ATOW ..  ..</span></td>
              </tr>
              <tr>
                <td colspan="5" class="cls_002"><span class="cls_002">MIN FUEL RQ.MIS APP<span class="comentario"><br>Por ahora usando el total fuel del ultimo punto</span></span></td>
                <td></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.nav_logs[0].points[result.nav_logs[0].points.length - 1].time) }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.nav_logs[0].points[result.nav_logs[0].points.length - 1].ttl_fuel }}</span></td>
                <td class="cls_002"><span class="cls_002">ELDW</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.ldgwt }}</span></td>
                <td class="cls_002"><span class="cls_002">MLDW</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.aircraft.aircraft.mlw }}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">ALDW ..  ..</span></td>
              </tr>
              <tr style="height:20px;"></tr>
              <tr>
                <td colspan="6" class="cls_002"><span class="cls_002">ROUTE RES 5 P/C</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{formatTime(result.plan_header.contingency_time)}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.contingency_fuel }}</span></td>
                <td></td>
                <td colspan="9" class="cls_002"><span class="cls_002">NOTE-LDGWT INCLUDES RESERVE FUEL</span></td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002"><span class="cls_002">EXTRA</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.xfuel_time)}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.xfuel_fuel }}</span></td>
                <td colspan="8"></td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002">
                  <span class="cls_002 missingdata">
                    TOTAL RESERVES
                  </span>
                </td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.total_reserve_time) }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.total_reserve_fuel }}</span></td>
                <td colspan="8"></td>
              </tr>
              <tr class="comentario">
                <td colspan="18">
                    <span class="comentario">
                      Sería holding_fuel + alternate_fuel + contingency_fuel + xfuel_fuel
                    </span>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002"><span class="cls_002">TRIP FUEL</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.trip_time) }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.trip_fuel }}</span></td>
                <td colspan="8"></td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002">
                  <span class="cls_002 missingdata">
                    ENDURANCE
                  </span>
                </td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ enduranceFuel.time }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ enduranceFuel.fuel }}</span></td>
                <td colspan="8"></td>
              </tr>
              <tr class="comentario">
                <td colspan="18"><span class="comentario">Sería Total Reserves + trip</span></td>
              </tr>
              <tr> 
                <td colspan="8" class="cls_002"><span class="cls_002">TAXI</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.taxi_out_fuel }}</span></td>
                <td></td>
                <td colspan="3" class="cls_002"><span class="cls_002">FLIGHT TIMES</span></td>
                <td class="cls_002"><span class="cls_002">ETD</span></td>
                <td class="cls_002 right"><span class="cls_002">0130</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">ATD ..  ..</span></td>
              </tr>
              <tr> 
                <td colspan="6" class="cls_002">
                  <span class="cls_002 missingdata">
                    FUEL ON BOARD
                  </span>
                </td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ fuelOnBoard.time }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ fuelOnBoard.fuel }}</span></td>
                <td colspan="4"></td>
                <td class="cls_002"><span class="cls_002">ETA</span></td>
                <td class="cls_002 right"><span class="cls_002">0335</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">ATA ..  ..</span></td>
              </tr>
              <tr class="comentario">
                <td colspan="18"><span class="comentario">Sería Endurance + Taxi out, pero no debería quizás coincidir con el total fuel que tenemos de antes (igual al reqd) que es: {{ result.plan_header.total_fuel }} o con el FUEL ON BOARD que calculamos para ruta principal mas 1st alternate (que no se calcula con tiempo, solo fuel, que lo tenemos como: {{ result.nav_logs[1].footer.fob }}?? </span></td>
              </tr>
              <tr> 
                <td colspan="8" class="cls_002"><span class="cls_002">ACTUAL FUEL</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">..  ..</span></td>
                <td collspan="8"></td>
              </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "RuslineTimeAndFuel",
  props: ['result'],
  computed: {
    totalReserveFuel () {
      return {
        fuel: (this.result.plan_header.holding_fuel
              + this.result.plan_header.alternate_fuel
              + this.result.plan_header.contingency_fuel
              + this.result.plan_header.xfuel_fuel),
        time: 'pendiente'
      }
    },
    enduranceFuel () {
      return {
        fuel: this.totalReserveFuel.fuel + this.result.plan_header.trip_fuel,
        time: 'pendiente'
      }
    },
    fuelOnBoard () {
      return {
        fuel: this.enduranceFuel.fuel + this.result.plan_header.taxi_out_fuel,
        time: 'pendiente'
      }
    }
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    formatTime (time) {
      time = String(time)
      return time.substring(0, 2) + '.' + time.substring(2);
    }
  },
};
</script>
<style>
@media print
{
  #RuslineTimeAndFuelSection {
    font-size: 16px;
  }
}
</style>
