<template>
  <section id="LatamWeightCalculationsSection">
    <div class="columns" style="border-top:solid;">
      <div class="column is-12  has-text-centered cls_003"><span class="cls_003">TAKE OFF WEIGHT CALCULATIONS BASED ON</span></div>
    </div>
    <div class="columns missingdata">
      <table class="tabla-sin-bordes">
        <col class="col-1-5">
        <col class="col-1-5">
        <col class="col-3">
        <col class="col-2">
        <col class="col-1-5">
        <col class="col-1-5">
        <col class="col-2">
        <tr>
          <td><span class="cls_002">OAT:17</span></td>
          <td><span class="cls_002">QNH:1018</span></td>
          <td><span class="cls_002">WIND:160/07</span></td>
          <td><span class="cls_002">RWY:17R<span class="comentario"> ....(tenemos un sid_rwy, será eso?)....</span></span></td>
          <td><span class="cls_002">AC: OFF</span></td>
          <td><span class="cls_002">AI:OFF</span></td>
          <td><span class="cls_002">FLAPS:</span></td>
        </tr>
      </table>
    </div>
    <div class="columns missingdata" style="margin-bottom:15px;">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="3"><span class="cls_002">CG:STANDARD</span></td>
          <td colspan="2"><span class="cls_002">COND:DRY</span></td>
          <td colspan="8"></td>  
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamWeightCalculations",
  props: ['result'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #LatamWeightCalculationsSection {
    font-size: 16px;
  }
}
</style>
