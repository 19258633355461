var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"RuslineAlternateSummarySection"}},[_c('div',{staticClass:"columns"},[_c('table',{staticClass:"tabla-sin-bordes"},[_vm._l((13),function(index){return _c('col',{key:index,staticClass:"col-1"})}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._l((_vm.navLogs),function(navLog,index){return [(index > 1)?_c('tr',{key:navLog.footer.apto + index},[_c('td',{staticClass:"cls_002",attrs:{"colspan":"4"}},[_c('span',{staticClass:"cls_002"},[_vm._v("ALTERNATE - "+_vm._s(index)+"  "+_vm._s(navLog.footer.apto))])]),_vm._m(4,true),_vm._m(5,true),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v(_vm._s(navLog.footer.dist))])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v(_vm._s(navLog.footer.fl))])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v(_vm._s(navLog.footer.average_wcomp))])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v(_vm._s(navLog.footer.time))])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v(_vm._s(navLog.footer.t_fuel))])]),_c('td',{attrs:{"colspan":"2"}})]):_vm._e()]})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"5"}}),_c('td',{staticClass:"cls_002",staticStyle:{"text-align":"center!important"},attrs:{"colspan":"3"}},[_c('span',{staticClass:"cls_002"},[_vm._v("ALTERNATES")])]),_c('td',{attrs:{"colspan":"5"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"10px"}},[_c('td',{attrs:{"colspan":"13"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"5"}}),_c('td',{attrs:{"colspan":"3"}},[_c('table',{staticClass:"tabla-sin-bordes"},[_c('tr',{staticStyle:{"border-top":"dashed","border-width":"3px","height":"10px"}},[_c('td',{staticStyle:{"min-width":"100%"}})])])]),_c('td',{attrs:{"colspan":"5"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"cls_002 right missingdata"},[_c('span',{staticClass:"cls_002"},[_vm._v("MSA"),_c('span',{staticClass:"comentario"},[_c('br'),_vm._v("Minimum safe altitude? tenemos esto?")])])]),_c('td',{staticClass:"cls_002 right missingdata"},[_c('span',{staticClass:"cls_002"},[_vm._v("TTK")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("DIST")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("FL")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("W/C")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("TIME")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("FUEL")])]),_c('td',{attrs:{"colspan":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"cls_002 right missingdata"},[_c('span',{staticClass:"cls_002"},[_vm._v("026")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"cls_002 right missingdata"},[_c('span',{staticClass:"cls_002"},[_vm._v("341")])])
}]

export { render, staticRenderFns }