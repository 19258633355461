var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"WindRoseTemperatureAltitudeCorrectionSection"}},[_c('div',{staticClass:"columns"},[_c('table',{staticClass:"tabla-sin-bordes"},[_vm._l((18),function(index){return _c('col',{key:index,staticClass:"col-1outof18"})}),_vm._m(0),_vm._m(1),_c('tr',{staticStyle:{"height":"15px"}}),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('tr',{staticStyle:{"height":"30px"}})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"14px"}},[_c('tr',{staticStyle:{"border-top":"3px dashed"}},[_c('td')])])]),_c('td',{staticClass:"cls_002 center",attrs:{"colspan":"6"}},[_c('span',{staticClass:"cls_002"},[_vm._v("ALTITUDE CORRECTION")])]),_c('td',{attrs:{"colspan":"6"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"14px"}},[_c('tr',{staticStyle:{"margin-top":"19px","border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("ALTITUDE CORRECTION MUST BE APPLIED FOR ISA-15C (OAT 0C) AND BELOW.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TARGET ALTITUDE AND DISTANCE TO BE TAKEN FROM APPROPRIATE APPROACH CHART.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("------------------------------+-------+-------+-------+-------+-------+-------+-------+")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("\n                      DISTANCE NM                   :\n                            +       +       +       +       +       +       +\n                  ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("------------------------------+-------+-------+-------+-------+-------+-------+-------+")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("\n                      TARGET ALT. (HEIGHT) FT       :\n                            +       +       +       +       +       +       +\n                  ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("------------------------------+-------+-------+-------+-------+-------+-------+-------+")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("\n                      CALCULATED ALT. (HEIGHT)FT:   :\n                            +       +       +       +       +       +       +\n                  ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("------------------------------+-------+-------+-------+-------+-------+-------+-------+")])])])
}]

export { render, staticRenderFns }