<template>
  <section id="LatamOperationalImpactsSection">
    <div class="columns" style="margin-top:15px;border-top:solid;">
      <table class="tabla-sin-bordes missingdata">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="13" class="cls_003" style="text-align:center!important;"><span class="cls_003">OPERATIONAL IMPACTS</span></td>
        </tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">VARIATIONS</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">ZFW</span></td>
          <td class="cls_002 right"><span class="cls_002">TIME</span></td>
          <td class="cls_002 right"><span class="cls_002">BURN</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">FL STEPS</span></td>
        </tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">1000 KG</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">56000KG</span></td>
          <td class="cls_002 right"><span class="cls_002">0139</span></td>
          <td class="cls_002 right"><span class="cls_002">3792</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">EDRON/ 380  DOVRI/ 380</span></td>
        </tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">-1000 KG</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">54000KG</span></td>
          <td class="cls_002 right"><span class="cls_002">0138</span></td>
          <td class="cls_002 right"><span class="cls_002">3693</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">EDRON/ 380  DOVRI/ 380</span></td>
        </tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">FL +1</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">55000KG</span></td>
          <td class="cls_002 right"><span class="cls_002">0138</span></td>
          <td class="cls_002 right"><span class="cls_002">3733</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">EDRON/ 380  DOVRI/ 380</span></td>
        </tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">FL -1</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">55000KG</span></td>
          <td class="cls_002 right"><span class="cls_002">0138</span></td>
          <td class="cls_002 right"><span class="cls_002">3755</span></td>
          <td colspan="7" class="cls_002"><span class="cls_002">ITAVA/ 360  DOVRI/ 360</span></td>
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamOperationalImpacts",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
};
</script>
<style>
@media print
{
  #LatamOperationalImpactsSection {
    font-size: 16px;
  }
}
</style>
