<template>
  <section id="AviaSolutionsFormatSection" class="printable">
    <div class="no-print">
      <a style="position:fixed;right:30px;" class="button is-info is-medium is-rounded" @click="print"><i
          class="fa fa-print"></i>&nbsp;Print</a>
      <article class="message is-warning no-print" v-if="hasAlerts">
        <div class="message-body">
          <p v-for="(alert, key) in alerts" :key="key">{{ alert.description }}</p>
        </div>
      </article>
    </div>
    <div class="columns">
      <div class="column">
        <Header v-if="result.headers" :result="result" :header="result.headers[0]"></Header>
      </div>
    </div>
    <div class="columns" v-for="(navLog, key) in navLogs" :key="key">
      <div class="column is-paddingless" v-if="key < 2 || result.flight.display_alternate_navigation">
        <navigation-log :navLog="navLog" :navKey="key"></navigation-log>
      </div>
    </div>
    <div class="columns">
      <div class="column is-paddingless">
        <navigation-log-footer :logs="altLogs"></navigation-log-footer>
      </div>
    </div>
        <div class="columns">
          <div class="column">
            <airports-weather v-if="result.airports" :result="result"></airports-weather>
          </div>
        </div>
    <!--    <div class="columns">-->
    <!--      <div class="column is-offset-1 is-10 has-text-centered">-->
    <!--        <rusline-wind-summary v-if="result.wind_summary" :result="result"></rusline-wind-summary>-->
    <!--      </div>-->
    <!--    </div>-->
    <div>
      <h2 class="title is-5">WIND AND TEMPERATURE SUMMARY</h2>
      <latam-wind-summary v-if="result.wind_summary" :result="result"></latam-wind-summary>
    </div>
    <airports-notam v-if="result.airports" :result="result"></airports-notam>
  </section>
</template>
<script>

import NavigationLog from "../NavigationLog.vue";
import Header from "../Header.vue";
import AirportsWeather from "../AirportsWeather.vue";
import AirportsNotam from "../AirportsNotam.vue";
import LatamWindSummary from "../LatamWindSummary.vue";
import RuslineWindSummary from "../RuslineWindSummary.vue";
import NavigationLogFooter from "@/components/plans/plan-report/NavigationLogFooter.vue";

export default {
  name: "AviaSolutionsFormat",
  props: ['result'],
  data() {
    return {
      alerts: this.result.alerts,
      navLogs: this.result.nav_logs
    }
  },
  computed: {
    hasAlerts() {
      return this.alerts.length > 0;
    },
    altLogs() {
      return this.navLogs.filter((log) => log.apto !== null)
    }
  },
  watch: {
    'result': function (plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    print() {
      window.print();
    }
  },
  components: {
    NavigationLogFooter,
    NavigationLog,
    Header,
    AirportsWeather,
    AirportsNotam,
    LatamWindSummary,
    RuslineWindSummary
  }
};
</script>
<style>
#AviaSolutionsFormatSection .card-header {
  background-color: lightblue;
}

#AviaSolutionsFormatSection .card {
  min-height: 100%;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
</style>
