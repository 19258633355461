<template>
  <section id="RuslineAlternateNavigationLogSection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                    <td colspan="18" class="cls_002" style="text-align:center!important;"><span class="cls_002" style="border-bottom:dashed;border-width:2px;">ROUTE TO THE 1-ST ALTERNATE</span></td>
              </tr>
            <tr style="height:10px;">
                <td colspan="18"></td>
            </tr>
              <thead>
              <tr>
                <td colspan="16">
                </td>
                <td colspan="2" style="text-align:center!important;" class="cls_002"><span class="cls_002">FUEL</span></td>
              </tr>
              <tr>
                <td colspan="2" class="cls_002"><span class="cls_002">AWY</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">ROUTE</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">LAT</span></td>
                <td class="cls_002"><span class="cls_002">MTK</span></td>
                <td class="cls_002"><span class="cls_002">DIST</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">FL/SR</span></td>
                <td class="cls_002"><span class="cls_002">TAS</span></td>
                <td class="cls_002"><span class="cls_002">GS</span></td>
                <td class="cls_002"><span class="cls_002">WD</span></td>
                <td class="cls_002"><span class="cls_002">ET</span></td>
                <td class="cls_002"><span class="cls_002">EST</span></td>
                <td class="cls_002"><span class="cls_002">ETO</span></td>
              </tr>
              <tr>
                <td colspan="2" class="cls_002"><span class="cls_002">MORA</span></td>
                <td class="cls_002"><span class="cls_002">RNT</span></td>
                <td colspan="2" class="cls_002  right"><span class="cls_002">FREQY</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">LONG</span></td>
                <td class="cls_002"><span class="cls_002">TTK</span></td>
                <td class="cls_002"><span class="cls_002">DTGO</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">T</span></td>
                <td class="cls_002"><span class="cls_002">MAC</span></td>
                <td class="cls_002"><span class="cls_002">FIR</span></td>
                <td class="cls_002"><span class="cls_002">WS</span></td>
                <td class="cls_002"><span class="cls_002">TT</span></td>
                <td class="cls_002"><span class="cls_002">ACT</span></td>
                <td class="cls_002"><span class="cls_002">ATO</span></td>
              </tr>
              <tr>
                <td colspan="15"></td>
                <td class="cls_002"><span class="cls_002">DT</span></td>
                <td class="cls_002"><span class="cls_002">USED</span></td>
                <td></td>
              </tr>
            </thead>
              <!-- INICIO -->
            <template v-for="(point, index) in navLog.points">
                <tr :key="point.name + 'tr1'" style="height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr :key="point.name + 'tr2'" style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr :key="point.name + 'tr3'">
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.airway == '' ? '...' : point.airway }}</span></td>
                    <td colspan="3" class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                    <td colspan="3" class="cls_002"><span class="cls_002">{{ point.latitude }}</span></td>
                    <td class="cls_002"><span class="cls_002">248</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ point.znd }}</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? point.mach : point.flvl}}/{{ point.wsr }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.tas }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.gs }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ Math.round(point.wspeed) }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.znt }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ (point.ttl_fuel / 1000).toFixed(1) }}</span></td>
                    <td class="cls_002"><span class="cls_002">..</span></td>
                </tr>
                <tr :key="point.name + 'tr4'">
                    <td colspan="2" class="cls_002"><span class="cls_002">...</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                    <td colspan="2" class=""></td>
                    <td colspan="3" class="cls_002"><span class="cls_002">{{ point.longitude }}</span></td>
                    <td class="cls_002"><span class="cls_002">263</span></td>
                    <td class="cls_002 right "><span class="cls_002">{{ point.distance_to_go }}</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.temp }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.mach }}</span></td>
                    <td class=""></td>
                    <td class="cls_002"><span class="cls_002">{{ point.wsr }}</span></td>
                    <td class="cls_002"><span class="cls_002">{{ point.time }}</span></td>
                    <td class="cls_002"><span class="cls_002">..</span></td>
                    <td class="cls_002"><span class="cls_002">...</span></td>
                </tr>
                <tr :key="point.name + 'tr5'">
                    <td colspan="15"></td>
                    <td class="cls_002"><span class="cls_002">...</span></td>
                    <td class="cls_002"><span class="cls_002">0.1</span></td>
                    <td></td>
                </tr>
            </template>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "RuslineAlternateNavigationLog",
  props: ['result', 'navLog', 'navKey'],
  data() {
    return {
    }
  },
  computed: {

  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style>
@media print
{
  #RuslineAlternateNavigationLogSection {
    font-size: 16px;
  }
}
</style>
