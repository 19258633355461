<template>
  <section id="WindRoseFormatSection" class="printable" v-bind:class="{'showcomments' : showComments}">
    <div class="no-print">
      <a style="position:fixed;right:30px;" class="button is-info is-medium is-rounded" @click="print"><i class="fa fa-print"></i>&nbsp;Print</a>
      <article class="message is-warning no-print" v-if="hasAlerts">
        <div class="message-body">
          <p v-for="(alert, key) in alerts" :key="key">{{alert.description}}</p>
        </div>
      </article>
    </div>
    <button v-if="showComments" type="button" class="button is-block is-danger no-print" @click="toggleShowComments">Ocultar Comentarios</button>
    <button v-if="!showComments" type="button" class="button is-block is-success no-print" @click="toggleShowComments">Mostrar Comentarios</button>
    <wind-rose-dispatch-release v-if="flightPlan.plan_header" :result="result"></wind-rose-dispatch-release>
    <wind-rose-operational-flight-plan v-if="flightPlan.plan_header" :result="result"></wind-rose-operational-flight-plan>
    <wind-rose-takeoff-performance-calculations v-if="flightPlan.plan_header" :result="result"></wind-rose-takeoff-performance-calculations>
    <wind-rose-departure-atis-information v-if="flightPlan.plan_header" :result="result"></wind-rose-departure-atis-information>
    <wind-rose-enroute-data v-if="flightPlan.plan_header" :result="result" :navLog="navLogs[0]"></wind-rose-enroute-data>
    <wind-rose-arrival-atis-information v-if="flightPlan.plan_header" :result="result"></wind-rose-arrival-atis-information>
    <wind-rose-landing-performance-calculation v-if="flightPlan.plan_header" :result="result"></wind-rose-landing-performance-calculation>
    <wind-rose-temperature-altitude-correction v-if="flightPlan.plan_header" :result="result"></wind-rose-temperature-altitude-correction>
    <table class="tabla-sin-bordes">
    </table>
    <div class="columns" style="margin-top:15px;" v-if="flightPlan.client_wind_format != 'None'">
      <table class="tabla-sin-bordes">
        <col v-for="index in 18" :key="index" class="col-1outof18">
        <tr>
          <td colspan="6">
            <table class="tabla-sin-bordes" style="margin-top:14px;">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td colspan="6" class="cls_002 center"><span class="cls_002">WINDS/TEMPERATURE ALOFT DATA</span></td>
          <td colspan="6">
            <table class="tabla-sin-bordes" style="margin-top:14px;">
              <tr style="margin-top:19px;border-bottom: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="columns" v-if="flightPlan.client_wind_format == 'Rusline'">
      <div class="column is-12 has-text-centered">
        <rusline-wind-summary v-if="flightPlan.wind_summary" :result="result"></rusline-wind-summary>
      </div>
    </div>
    <div class="columns" v-if="flightPlan.client_wind_format == 'Latam'">
      <div class="column is-12 has-text-centered">
        <latam-wind-summary v-if="flightPlan.wind_summary" :result="result"></latam-wind-summary>
      </div>
    </div>
    <wind-rose-destination-alternate-data v-if="flightPlan.plan_header" :result="result" :navLogs="navLogs"></wind-rose-destination-alternate-data>
    <airports-weather v-if="flightPlan.airports" :result="result"></airports-weather>
    <airports-notam v-if="flightPlan.airports" :result="result"></airports-notam>
  </section>
</template>
<script>

import WindRoseDispatchRelease from "./windrose/WindRoseDispatchRelease.vue";
import WindRoseOperationalFlightPlan from "./windrose/WindRoseOperationalFlightPlan.vue";
import WindRoseTakeoffPerformanceCalculations from "./windrose/WindRoseTakeoffPerformanceCalculations.vue";
import WindRoseDepartureAtisInformation from "./windrose/WindRoseDepartureAtisInformation.vue";
import WindRoseEnrouteData from "./windrose/WindRoseEnrouteData.vue";
import WindRoseArrivalAtisInformation from "./windrose/WindRoseArrivalAtisInformation.vue";
import WindRoseLandingPerformanceCalculation from "./windrose/WindRoseLandingPerformanceCalculation.vue";
import WindRoseTemperatureAltitudeCorrection from "./windrose/WindRoseTemperatureAltitudeCorrection.vue";
import LatamWindSummary from "../LatamWindSummary.vue";
import RuslineWindSummary from "../RuslineWindSummary.vue";
import WindRoseDestinationAlternateData from "./windrose/WindRoseDestinationAlternateData.vue";
import AirportsWeather from "../AirportsWeather.vue";
import AirportsNotam from "../AirportsNotam.vue";

export default {
  name: "WindRoseFormat",
  props: ['flightPlan', 'result'],
  data() {
    return {
      alerts: this.flightPlan.alerts,
      navLogs: this.flightPlan.nav_logs,
      showComments: true
    }
  },
  computed: {
    hasAlerts() {
      return this.alerts.length > 0;
    }
  },
  watch: {
    'flightPlan': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    print () {
      window.print();
    },
    toggleShowComments () {
      this.showComments = !this.showComments
    }
  },
  components: {
    WindRoseDispatchRelease,
    WindRoseOperationalFlightPlan,
    WindRoseTakeoffPerformanceCalculations,
    WindRoseDepartureAtisInformation,
    WindRoseEnrouteData,
    WindRoseArrivalAtisInformation,
    WindRoseLandingPerformanceCalculation,
    WindRoseTemperatureAltitudeCorrection,
    WindRoseDestinationAlternateData,
    RuslineWindSummary,
    LatamWindSummary,
    AirportsWeather,
    AirportsNotam
  }
};
</script>
<style>
#WindRoseFormatSection {
  font-size: 18px;
}
#WindRoseFormatSection span.cls_002 {
    font-family:Courier New,serif;
    color:rgb(0,0,0);
    font-weight:normal;
    font-style:normal;
    text-decoration: none
}
#WindRoseFormatSection div.cls_002 {
    font-family:Courier New,serif;
    color:rgb(0,0,0);
    font-weight:normal;
    font-style:normal;
    text-decoration: none
}

#WindRoseFormatSection .card-header{
  background-color: lightgrey;
}
#WindRoseFormatSection .card {
  min-height: 100%;
}
#WindRoseFormatSection .columns {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:1;
}
#WindRoseFormatSection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:2px;
}
#WindRoseFormatSection .tabla-sin-bordes tr td {
  text-align: left !important;
  padding-right: 8px;
  padding-right:30px;
}
#WindRoseFormatSection .text-underlined-10 {
  border-bottom: 3px dashed #000;
  padding-bottom: 8px;
}
#WindRoseFormatSection .tabla-sin-bordes tr td:last-child {
  padding-right:2px!important;
}

#WindRoseFormatSection .tabla-sin-bordes {
  width:100%;
  text-align: left !important;
  table-layout: fixed;
  border-bottom:0px !important;
  border:0px !important;
}
#WindRoseFormatSection table.navlog {
  font-size:100%!important;
  border-spacing:0 !important;
  border-collapse: collapse !important;
  padding:0!important;
}
#WindRoseFormatSection table tr.dotted {
  background-image: radial-gradient(circle at 50% 50%, black 1px, transparent 2px);
  background-size: 8px 50px; /* size in y-axis is equal to td height + padding top + padding bottom */
  background-position: 2px 50px; /* position in y-axis is -1 * size/2 - 2px */
  background-repeat: round; /* makes dots repeat in round manner */
  border-collapse: collapse;
}

#WindRoseFormatSection.showcomments .missingdata {
  background-color: red;
}
#WindRoseFormatSection .comentario {
  background-color: greenyellow;
}
#WindRoseFormatSection:not(.showcomments) .comentario {
  display: none;
}
/* #WindRoseFormatSection .comentario {
  display: none;
}
#WindRoseFormatSection .missingdata {
  display: none;
}
#WindRoseFormatSection .columns.missingdata {
  background-color: white!important;
} */
#WindRoseFormatSection .tabla-sin-bordes tr td:first-child{
  font-weight: bolder;
  padding-left:0em;
  width:7.69%;
  margin-left: 0px;
  padding-left: 0px;
}
#WindRoseFormatSection table tr td.right {
  text-align: right!important;
}
#WindRoseFormatSection table tr td.center {
  text-align: center!important;
}
#WindRoseFormatSection col {
  padding-left:0em;
}
#WindRoseFormatSection .col-1 {
  width: 7.6923076923076925%;
}
#WindRoseFormatSection .col-1-5 {
  width: 11.538461538461538%;
}
#WindRoseFormatSection .col-2 {
  width: 15.384615384615385%;
}
#WindRoseFormatSection .col-3 {
  width: 23.076923076923077%;
}
#WindRoseFormatSection .col-4 {
  width: 30.76923076923077%;
}
#WindRoseFormatSection .col-5 {
  width: 38.46153846153846%;
}
#WindRoseFormatSection .col-6 {
  width: 46.15384615384615%;
}
#WindRoseFormatSection .col-7 {
  width: 53.84615384615385%;
}
#WindRoseFormatSection .col-8 {
  width: 61.53846153846154%;
}
#WindRoseFormatSection .col-1outof18 {
  width: 5.6%;
}
#WindRoseFormatSection .col-1outof16 {
  width: 6.25%;
}
#WindRoseFormatSection .level-item .heading{
  font-size:1em;
}
#WindRoseFormatSection .level-item .title{
  font-size:1em;
}
@media print
{
    #WindRoseFormatSection {
      font-size: 14px!important;
    }
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
