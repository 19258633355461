<template>
  <section id="ScatAlternateNavLogsSection">
    <div v-for="(navLog, index) in navLogs" :key="index" navclass="columns">
        <template v-if="index > 0">
        <table class="tabla-sin-bordes">
            <col v-for="index in 14" :key="index" class="col-1outof14">
            <tr>
                <td colspan="14" class="cls_002"><span class="cls_002">START OF ALTERNATE FLIGHT PLAN {{ index }}&nbsp;&nbsp;{{ result.alternate_airports[index - 1].route.departure }} TO {{ result.alternate_airports[index - 1].route.destination }}</span></td>
            </tr>
            <tr style="height:10px;"></tr>
            <tr>
                <td colspan="14" class="cls_002"><span class="cls_002">ROUTE:&nbsp;&nbsp;{{ result.alternate_airports[index -1].route.id }}</span></td>
            </tr>
            <tr style="height:10px;"></tr>
            <tr>
                <td colspan="2" class="cls_002"><span class="cls_002">POSITION</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">LAT</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">LONG</span></td>
            </tr>
            <tr>
                <td colspan="2" class="cls_002"><span class="cls_002">FREQY</span></td>
                <td colspan="4"></td>
                <td class="cls_002"><span class="cls_002">AWID</span></td>
                <td class="cls_002 right missingdata"><span class="cls_002">TRAKM</span></td>
                <td class="cls_002 right"><span class="cls_002">DIST</span></td>
                <td class="cls_002 right missingdata"><span class="cls_002">MORA</span></td>
                <td colspan="4"></td>
            </tr>
            <template v-for="point in navLog.points">
                <tr :key="point.name + 'tr1'">
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.latitude }}</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.longitude }}</span></td>
                </tr>
                <tr :key="point.name + 'tr2'">
                    <td colspan="2" class="cls_002"><span class="cls_002">{{ point.freq }}</span></td>
                    <td colspan="4"></td>
                    <td class="cls_002"><span class="cls_002">{{ point.airway }}</span></td>
                    <td class="cls_002 right missingdata"><span class="cls_002"></span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ point.znd }}</span></td>
                    <td class="cls_002 right missingdata"><span class="cls_002"></span></td>
                    <td colspan="4"></td>
                </tr>
            </template>
            <tr style="height:30px"></tr>
            <tr>
                <td colspan="5"></td>
                <td colspan="9" class="cls_002"><span class="cls_002">TOT DIST: {{ navLog.distance_to_go }}</span></td>
            </tr>
            <tr>
                <td colspan="14" class="cls_002"><span class="cls_002">END OF ALTERNATE FLIGHT PLAN {{index}}</span></td>
            </tr>
            <tr style="height:10px;"></tr>
        </table>
        </template>
    </div>
  </section>
</template>
<script>

export default {
  name: "ScatAlternateNavLogs",
  props: ['result', 'navLogs'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    pad (size, num) {
      var s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
};
</script>
<style>
@media print
{
  #ScatAlternateNavLogsSection {
    font-size: 20px;
  }
}
</style>
