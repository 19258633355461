<template>
  <section id="WindRoseOperationalFlightPlanSection">
      <div class="columns pagebreak">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                <td colspan="18" class="cls_002 center"><span class="cls_002">--- OPERATIONAL FLIGHT PLAN ---</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002 center"><span class="cls_002">SPEEDS (KTS)/DISTANCE(NMS)/WEIGHTS(KGS)/HEIGHTS(FT)</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002 center"><span class="cls_002">(COMPUTED {{ result.computed_at }}/MET PRGS 100012 100015Z/CFP {{ result.id }})</span></td>
              </tr>
              <tr style="height:20px;"></tr>
              <tr>
                <td colspan="7">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td colspan="4" class="cls_002 center"><span class="cls_002">GENERAL DATA</span></td>
                <td colspan="7">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="margin-top:19px;border-bottom: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style="height:20px;"></tr>
              <tr>
                <td colspan="2" class="cls_002"><span class="cls_002 text-underlined-10">FLT</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002 text-underlined-10">REG</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002 text-underlined-10">ROUTE</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002 text-underlined-10">DTD</span></td>
                <td class="cls_002"><span class="cls_002 text-underlined-10">STD</span></td>
                <td class="cls_002"><span class="cls_002 text-underlined-10">STA</span></td>
                <td class="cls_002"><span class="cls_002 text-underlined-10">TALT</span></td>
                <td class="cls_002"><span class="cls_002 text-underlined-10">1ALT</span></td>
                <td class="cls_002"><span class="cls_002 text-underlined-10">2ALT</span></td>
                <td class="cls_002"><span class="cls_002 text-underlined-10">3ALT</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002 text-underlined-10">RID</span></td>
              </tr>
              <tr style="height:10px;"></tr>
              <tr>
                <td colspan="2" class="cls_002"><span class="cls_002">{{result.flight.flight_release}}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ result.aircraft.aircraft.tail_number }}</span></td>
                <td class="cls_002"><span class="cls_002">{{result.flight.route.departure}}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{result.flight.route.destination}}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{result.generated_at_ddmmyy}}</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.flight.etd }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.flight.eta }}</span></td>
                <td class="cls_002"><span class="cls_002">NR</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.nav_logs[1] ? result.nav_logs[1].footer.apto : 'NR' }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.nav_logs[2] ? result.nav_logs[2].footer.apto : 'NR' }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.nav_logs[3] ? result.nav_logs[3].footer.apto : 'NR' }}</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">{{ result.flight.route.id }}<span class="comentario"><br>Route ID nuestro?</span></span></td>
              </tr>
              <tr>
                <td colspan="4"></td>
                <td class="cls_002"><span class="cls_002">({{result.flight.route.departure_iata}})</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">({{result.flight.route.destination_iata}})</span></td>
                <td colspan="5"></td>
                <td class="cls_002"><span class="cls_002">({{result.alternate_airports[0].route.destination_iata}})</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.alternate_airports[1] ? result.alternate_airports[1].route.destination_iata : 'NR' }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.alternate_airports[2] ? result.alternate_airports[2].route.destination_iata : 'NR' }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ result.alternate_airports[3] ? result.alternate_airports[3].route.destination_iata : 'NR' }}</span></td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="8">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td colspan="2" class="cls_002 center"><span class="cls_002">FMS DATA</span></td>
                <td colspan="8">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="margin-top:19px;border-bottom: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002"><span class="cls_002">DEGRADATION FACTOR</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ deg(result.aircraft.aircraft.deg) }}</span></td>
                <td colspan="2"></td>
                <td colspan="5" class="cls_002"><span class="cls_002">MAX SHEAR</span></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{ result.nav_logs[0].mwsr }}/{{ result.nav_logs[0].mwsr_point }}</span></td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002"><span class="cls_002">COST INDEX</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.flight.cruise_speed }}</span></td>
                <td colspan="2"></td>
                <td colspan="2" class="cls_002"><span class="cls_002">GROUND</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">DISTANCE</span></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{result.flight.distance_to_go}} NMS</span></td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002 missingdata"><span class="cls_002">AVERAGE TEMPERATURE</span></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">...</span></td>
                <td colspan="2"></td> 
                <td colspan="2" class="cls_002 missingdata"><span class="cls_002">AIR</span></td>
                <td colspan="3" class="cls_002 missingdata"><span class="cls_002">DISTANCE</span></td>
                <td colspan="3" class="cls_002 right missingdata"><span class="cls_002">... NMS</span></td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002"><span class="cls_002">AVERAGE CRUIZE WIND</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.nav_logs[0].average_wcomp}}</span></td>
                <td colspan="2"></td>
                <td colspan="5" class="cls_002"><span class="cls_002">AVERAGE FUEL FLOW</span></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">{{ result.plan_header.avg_ff }} KGS</span></td>
              </tr>
              <tr>
                <td colspan="3">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td nowrap="nowrap" colspan="2" class="cls_002 center"><span class="cls_002">FUEL PLAN</span></td>
                <td colspan="3">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="margin-top:19px;border-bottom: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td colspan="2"></td>
                <td colspan="3">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td colspan="2" class="cls_002 center"><span class="cls_002">WEIGHTS</span></td>
                <td colspan="3">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="margin-top:19px;border-bottom: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">*</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">TIME</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">FUEL</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">STRUCTURAL</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">CALCULATED</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">ACTUAL</span></td>
              </tr>
              <tr>
                
                <td colspan="3" class="cls_002"><span class="cls_002">ALTN FUEL</span></td>
                <td class="cls_002"><span class="cls_002">({{result.alternate_airports[0].route.destination}})</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.alternate_time) }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.alternate_fuel }}</span></td>
                <td colspan="2"></td>
                <td class="cls_002"><span class="cls_002">MTOW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{result.aircraft.aircraft.mtow}}</span></td>
                <td class="cls_002"><span class="cls_002">CTOW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{result.plan_header.brwt}}<span class="comentario"><br>Dudas, en respuestas, indica que tow es brwt, pero en plan de ejemplo el TOW de la primera páigna (TOW) difiere del CTOW que está acá)</span></span></td>
                <td class="cls_002"><span class="cls_002">ATOW</span></td>
                <td class="cls_002 right"><span class="cls_002">. . .</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">FINAL RESERVE<span class="comentario">Holdn?</span></span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{formatTime(result.plan_header.holding_time)}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.holding_fuel}}</span></td>
                <td colspan="2"></td>
                <td class="cls_002"><span class="cls_002">MLW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ result.aircraft.aircraft.mlw }}</span></td>
                <td class="cls_002"><span class="cls_002">CLDW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ result.plan_header.ldgwt }}</span></td>
                <td class="cls_002"><span class="cls_002">ALDW</span></td>
                <td class="cls_002 right"><span class="cls_002">. . .</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">CONTINGENCY</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{formatTime(result.plan_header.contingency_time)}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.contingency_fuel}}</span></td>
                <td colspan="2"></td>
                <td class="cls_002"><span class="cls_002">MZFW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ result.aircraft.aircraft.mzfw }}</span></td>
                <td class="cls_002"><span class="cls_002">CZFW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ result.flight.zero_fuel_weight }}</span></td>
                <td class="cls_002"><span class="cls_002">AZFW</span></td>
                <td class="cls_002 right"><span class="cls_002">. . .</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">EXTRA</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.xfuel_time)}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.xfuel_fuel }}</span></td>
                <td colspan="2"></td> 
                <td class="cls_002 missingdata"><span class="cls_002">MPLD</span></td>
                <td colspan="2" class="cls_002 missingdata"><span class="cls_002">pendiente</span></td>
                <td class="cls_002"><span class="cls_002">CPLD</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{result.flight.payload_weight}}<span class="comentario"><br>Idem a tow, en plan de ejemplo, este valor difiere con el payload de la primera página</span></span></td>
                <td class="cls_002"><span class="cls_002">APLD</span></td>
                <td class="cls_002 right"><span class="cls_002">. . .</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002 missingdata"><span class="cls_002">ADDITIONAL</span></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">pendiente</span></td>
                <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">pendiente</span></td>
                <td colspan="2"></td>
                <td class="cls_002"><span class="cls_002">DOW</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ result.aircraft.eow }}</span></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="3" class="cls_002"><span class="cls_002">TOTAL RESERVES</span></td>
                <td class="cls_002"><span class="cls_002">(FOD)</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.total_reserve_time) }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.total_reserve_fuel }}</span></td>
                <td colspan="2"></td>
                <td colspan="8" class="cls_002">
                  <span class="cls_002">
                    TANKS {{ result.aircraft.mfuel }} KGS
                  </span>
                  <!-- En Windrose Respuestas 2020.08.24 indican que es mfuel de aircrafts -->
                </td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">TRIP FUEL</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{formatTime(result.plan_header.trip_time)}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.trip_fuel}}</span></td>
                <td colspan="2"></td>
                <td colspan="2">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td colspan="4" class="cls_002 center"><span class="cls_002">FLIGHT TIMES</span></td>
                <td colspan="2">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="margin-top:19px;border-bottom: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="cls_002"><span class="cls_002">ENDURANCE</span></td>
                <td class="cls_002"><span class="cls_002">(TOF)</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ formatTime(result.plan_header.endurance_time) }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.endurance_fuel }}</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">BLK ON ...:...</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">LDG ...:...</span></td>
                <td class="cls_002"><span class="cls_002">SLOT</span></td>
                <td class="cls_002 right"><span class="cls_002">. . .</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">TAXI</span></td>
                <td colspan="2"></td> 
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.taxi_out_fuel }}</span></td>
                <td colspan="2"></td>
                <td colspan="3" class="cls_002"><span class="cls_002">BLK OFF...:...</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">T/O ...:...</span></td>
                <td class="cls_002"><span class="cls_002">CTOT</span></td>
                <td class="cls_002 right"><span class="cls_002">. . .</span></td>
              </tr>
              <tr>
                <td colspan="6" class="cls_002">
                  <span class="cls_002">
                    FOB <span class="missingdata">0.785 KGS/CM3 (Densidad, pendiente)</span>
                    <span class="comentario">
                      <br>Tenemos un fob calculado, que es el que va arriba, fuel on board, pero en cambio este otro fob dice ("total calculated amount fuel on board before engine start (endurance + taxi)" y eso se usó, endurance + taxi out. Estará bien?"
                      </span>
                  </span>
                </td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.fob }}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">KGS</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">BLK TTL...:...</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">FLT ...:...</span></td>
                <td class="cls_002"><span class="cls_002">RFOB</span></td>
                <td class="cls_002 right"><span class="cls_002">. . .</span></td>
              </tr>
              <tr>
                <td colspan="5" class="cls_002"><span class="cls_002">ACTUAL FUEL ON BOARD</span></td>
                <td colspan="3" class="cls_002 right"><span class="cls_002">. . .  .</span></td>
                <td colspan="2"></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">PAX . . .</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">/B.  .  .  .</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">/C.  .  .  .</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">/M.  .  .  .</span></td>
              </tr>
              <tr style="height:15px;"></tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">* = 3PCT ENROUTE, 3PCT/5PCT RECLEAR, 5PCT NORMAL<span class="comentario">Tal cual?</span></span></td>
              </tr>
              <tr class="comentario" style="height:15px;"></tr>
              <tr class="comentario">
                <td colspan="18" class="cls_002 missingdata"><span class="cls_002">ETP PENDIENTE</span></td>
              </tr>
              <tr class="comentario" style="height:15px;"></tr>
              <tr class="comentario">
                <td colspan="18" class="cls_002 missingdata"><span class="cls_002">DIVERSION WINDOW PENDIENTE</span></td>
              </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "WindRoseOperationalFlightPlan",
  props: ['result'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    deg(deg) {
      if (deg < 0) {
        return 'M'+Math.abs(deg);
      }
      if (deg > 0) {
        return 'P'+deg;
      }
      return 0;
    },
    formatTime (time) {
      time = String(time)
      return time.substring(0, 2) + '.' + time.substring(2);
    }
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #WindRoseOperationalFlightPlanSection {
    font-size: 16px;
  }
}
</style>
