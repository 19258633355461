<template>
  <section id="RuslineFormatSection" class="printable" v-bind:class="{'showcomments' : showComments}">
    <div class="no-print">
      <a style="position:fixed;right:30px;" class="button is-info is-medium is-rounded" @click="print"><i class="fa fa-print"></i>&nbsp;Print</a>
      <article class="message is-warning no-print" v-if="hasAlerts">
        <div class="message-body">
          <p v-for="(alert, key) in alerts" :key="key">{{alert.description}}</p>
        </div>
      </article>
    </div>
    <button v-if="showComments" type="button" class="button is-block is-danger no-print" @click="toggleShowComments">Ocultar Comentarios</button>
    <button v-if="!showComments" type="button" class="button is-block is-success no-print" @click="toggleShowComments">Mostrar Comentarios</button>
    <rusline-header v-if="flightPlan.plan_header" :result="result"></rusline-header>
    <rusline-time-and-fuel v-if="flightPlan.plan_header" :result="result" style="margin-top:15px;"></rusline-time-and-fuel>
    <rusline-summary v-if="flightPlan.plan_header" :result="result" style="margin-top:15px;"></rusline-summary>
    <rusline-navigation-log v-if="flightPlan.plan_header" :result="result" :navLog="navLogs[0]" :navKey="0" style="margin-top:15px;"></rusline-navigation-log>
    <rusline-alternate-navigation-log v-if="flightPlan.plan_header" :result="result" :navLog="navLogs[1]" :navKey="1" style="margin-top:15px;"></rusline-alternate-navigation-log>
    <rusline-alternate-summary v-if="flightPlan.plan_header" :result="result" :navLogs="navLogs" style="margin-top:15px;"></rusline-alternate-summary>
    <div class="columns" style="margin-top:15px;" v-if="flightPlan.client_wind_format != 'None'">
      <table class="tabla-sin-bordes">
          <col v-for="index in 13" :key="index" class="col-1">
          <tr>
            <td colspan="1"></td>
            <td colspan="11" class="cls_002" style="text-align:center!important"><span class="cls_002">START OF WIND AND TEMPERATURE SUMMARY {{flightPlan.flight.route.departure}} TO {{flightPlan.flight.route.destination}}</span></td>
            <td colspan="1"></td>
          </tr>
            <tr style="height:10px;">
                <td colspan="13"></td>
            </tr>
            <tr>
                <td colspan="1"></td>
                <td colspan="11">
                    <table class="tabla-sin-bordes">
                    <tr style="border-top:dashed;border-width:3px;height:10px;">
                        <td style="min-width:100%;"></td>
                    </tr>
                    </table>
                </td>
                <td colspan="1"></td>
            </tr>
      </table>
    </div>
    <div class="columns" v-if="flightPlan.client_wind_format == 'Rusline'">
      <div class="column is-12 has-text-centered">
        <rusline-wind-summary v-if="flightPlan.wind_summary" :flightPlan="flightPlan"></rusline-wind-summary>
      </div>
    </div>
    <div class="columns" v-if="flightPlan.client_wind_format == 'Latam'">
      <div class="column is-12 has-text-centered">
        <div class="column is-12 has-text-centered">
          <hr>
          WIND AND TEMPERATURE SUMMARY
        </div>
        <latam-wind-summary v-if="flightPlan.wind_summary" :flightPlan="flightPlan"></latam-wind-summary>
      </div>
    </div>
    <div class="columns" style="margin-top:15px;">
      <table class="tabla-sin-bordes">
          <col v-for="index in 13" :key="index" class="col-1">
          <tr class="missingdata">
            <td colspan="13"><span class="comentario">ETOPS e ICAO FLIGHT PLAN PENDIENTES</span></td>
          </tr>
      </table>
    </div>
    <airports-weather v-if="flightPlan.airports" :flightPlan="flightPlan"></airports-weather>
    <airports-notam v-if="flightPlan.airports" :flightPlan="flightPlan"></airports-notam>
  </section>
</template>
<script>

import RuslineHeader from "./rusline/RuslineHeader.vue";
import RuslineTimeAndFuel from "./rusline/RuslineTimeAndFuel.vue";
import RuslineSummary from "./rusline/RuslineSummary.vue";
import RuslineNavigationLog from "./rusline/RuslineNavigationLog.vue";
import RuslineAlternateNavigationLog from "./rusline/RuslineAlternateNavigationLog.vue";
import RuslineAlternateSummary from "./rusline/RuslineAlternateSummary.vue";
import LatamWindSummary from "../LatamWindSummary.vue";
import RuslineWindSummary from "../RuslineWindSummary.vue";
import AirportsWeather from "../AirportsWeather.vue";
import AirportsNotam from "../AirportsNotam.vue";

export default {
  name: "RuslineFormat",
  props: ['flightPlan'],
  data() {
    return {
      alerts: this.flightPlan.alerts,
      navLogs: this.flightPlan.nav_logs,
      showComments: true
    }
  },
  computed: {
    hasAlerts() {
      return this.alerts.length > 0;
    }
  },
  watch: {
    'flightPlan': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    print () {
      window.print();
    },
    toggleShowComments () {
      this.showComments = !this.showComments
    }
  },
  components: {
    RuslineHeader,
    RuslineTimeAndFuel,
    RuslineSummary,
    RuslineNavigationLog,
    RuslineAlternateNavigationLog,
    RuslineAlternateSummary,
    RuslineWindSummary,
    LatamWindSummary,
    AirportsWeather,
    AirportsNotam
  }
};
</script>
<style>
#RuslineFormatSection {
  font-size: 18px;
}
#RuslineFormatSection span.cls_002 {
    font-family:Courier New,serif;
    color:rgb(0,0,0);
    font-weight:normal;
    font-style:normal;
    text-decoration: none
}
#RuslineFormatSection div.cls_002 {
    font-family:Courier New,serif;
    color:rgb(0,0,0);
    font-weight:normal;
    font-style:normal;
    text-decoration: none
}

#RuslineFormatSection .card-header{
  background-color: lightgrey;
}
#RuslineFormatSection .card {
  min-height: 100%;
}
#RuslineFormatSection .columns {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:1;
}
#RuslineFormatSection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:2px;
}
#RuslineFormatSection .tabla-sin-bordes tr td {
  text-align: left !important;
  padding-right: 8px;
  padding-right:30px;
}
#RuslineFormatSection .tabla-sin-bordes tr td:last-child {
  padding-right:2px!important;
}

#RuslineFormatSection .tabla-sin-bordes {
  width:100%;
  text-align: left !important;
  table-layout: fixed;
  border-bottom:0px !important;
  border:0px !important;
}
#RuslineFormatSection table.navlog {
  font-size:100%!important;
  border-spacing:0 !important;
  border-collapse: collapse !important;
  padding:0!important;
}
#RuslineFormatSection table tr.dotted {
  background-image: radial-gradient(circle at 50% 50%, black 1px, transparent 2px);
  background-size: 8px 50px; /* size in y-axis is equal to td height + padding top + padding bottom */
  background-position: 2px 50px; /* position in y-axis is -1 * size/2 - 2px */
  background-repeat: round; /* makes dots repeat in round manner */
  border-collapse: collapse;
}

#RuslineFormatSection.showcomments .missingdata {
  background-color: red;
}
#RuslineFormatSection .comentario {
  background-color: greenyellow;
}
#RuslineFormatSection:not(.showcomments) .comentario {
  display: none;
}
/* #RuslineFormatSection .comentario {
  display: none;
}
#RuslineFormatSection .missingdata {
  display: none;
}
#RuslineFormatSection .columns.missingdata {
  background-color: white!important;
} */
#RuslineFormatSection .tabla-sin-bordes tr td:first-child{
  font-weight: bolder;
  padding-left:0em;
  width:7.69%;
  margin-left: 0px;
  padding-left: 0px;
}
#RuslineFormatSection table tr td.right {
  text-align: right!important;
}
#RuslineFormatSection col {
  padding-left:0em;
}
#RuslineFormatSection .col-1 {
  width: 7.6923076923076925%;
}
#RuslineFormatSection .col-1-5 {
  width: 11.538461538461538%;
}
#RuslineFormatSection .col-2 {
  width: 15.384615384615385%;
}
#RuslineFormatSection .col-3 {
  width: 23.076923076923077%;
}
#RuslineFormatSection .col-4 {
  width: 30.76923076923077%;
}
#RuslineFormatSection .col-5 {
  width: 38.46153846153846%;
}
#RuslineFormatSection .col-6 {
  width: 46.15384615384615%;
}
#RuslineFormatSection .col-7 {
  width: 53.84615384615385%;
}
#RuslineFormatSection .col-8 {
  width: 61.53846153846154%;
}
#RuslineFormatSection .col-1outof18 {
  width: 5.6%;
}
#RuslineFormatSection .level-item .heading{
  font-size:1em;
}
#RuslineFormatSection .level-item .title{
  font-size:1em;
}
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
