<template>
  <section id="HeaderSection">
    <div class="columns">
      <div class="column is-11">
        <span><strong>FLIGHT RELEASE</strong>&nbsp;{{result.flight.flight_number}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="is-uppercase"><strong>GENERATED AT</strong>&nbsp;{{result.generated_at}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>FLIGHT LEVEL CALCULATED IN MANUAL MODE</span>
      </div>
      <div class="column is-1">
        <span class="is-pulled-right"><strong>PLAN</strong> {{result.id}}</span>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <span><strong>FROM</strong></span>&nbsp;{{result.flight.route.departure_icao}}&nbsp;&nbsp;&nbsp;&nbsp;
        <span><strong>TO</strong></span>&nbsp;{{result.flight.route.destination_icao}}&nbsp;&nbsp;&nbsp;&nbsp;
        <span><strong>ETD</strong>&nbsp;{{result.flight.etd}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span><strong>ETA</strong>&nbsp;{{result.flight.eta}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span><strong>CRUISE</strong>&nbsp;{{result.flight.cruise_speed}}</span>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <strong>REG</strong>&nbsp;<span>{{result.aircraft.tail_number}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>ACFT</strong>&nbsp;<span>{{result.aircraft.fleet.name}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>WEIGHT UNIT</strong><span>&nbsp;{{result.aircraft.aircraft.weight_unit}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>ENGINE</strong>&nbsp;<span>{{result.aircraft.engine}}</span>
      </div>
    </div>
    <div class="columns" style="margin-top:10px;">
      <div class="column is-4">
        <table class="tabla-sin-bordes is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <th style="text-align:right;">FUEL</th>
              <th style="text-align:right;">TIME</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TRIP FUEL</td>
              <td>{{pad(6, header.trip_fuel)}}</td>
              <td>{{header.trip_time}}</td>
            </tr>
            <tr>
              <td>CONTINGENCY</td>
              <td>{{pad(6, header.contingency_fuel)}}</td>
              <td>{{header.contingency_time}}</td>
            </tr>
            <tr>
              <td>ALTERNATE</td>
              <td>{{pad(6, header.alternate_fuel)}}</td>
              <td>{{header.alternate_time}}</td>
            </tr>
            <tr>
              <td>HOLD ALTN</td>
              <td>{{pad(6, header.holding_fuel)}}</td>
              <td>{{header.holding_time}}</td>
            </tr>
            <tr>
              <td>REQD</td>
              <td>{{pad(6, header.reqd_fuel)}}</td>
              <td>{{header.reqd_time}}</td>
            </tr>
            <tr v-if="result.flight.tanker == false">
              <td>XFUEL</td>
              <td>{{pad(6, header.xfuel_fuel)}}</td>
              <td>{{header.xfuel_time}}</td>
            </tr>
            <tr v-if="result.flight.tanker == true">
              <td>TANKER</td>
              <td>{{pad(6, header.tanker_fuel)}}</td>
              <td>{{header.tanker_time}}</td>
            </tr>
            <tr>
              <td>TAXI OUT</td>
              <td>{{pad(6, header.taxi_out_fuel)}}</td>
              <td>{{header.taxi_out_time}}</td>
            </tr>
            <tr>
              <td>TOTAL</td>
              <td>{{pad(6, header.total_fuel)}}</td>
              <td>{{header.total_time}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-1">
      </div>
      <div class="column is-6 is-centered" style="margin-top:20px;">
        <div class="columns is-multiline">
          <div class="column is-12">
            <nav class="level">
              <div class="column is-2">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">OWE</p>
                    <p class="title">{{result.aircraft.eow}}</p>
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">PLD</p>
                    <p class="title">{{result.flight.payload_weight}}</p>
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">ZFWT</p>
                    <p class="title">{{pad(6, result.flight.zero_fuel_weight)}}</p>
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">BRWT</p>
                    <p class="title">{{header.brwt}}</p>
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">LDGWT</p>
                    <p class="title">{{header.ldgwt}}</p>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div class="column is-12">
            <nav class="level">
              <div class="column is-12">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Alternate Routes</p>
                    <p v-for="alternate in result.alternate_airports" :key="alternate.id" class="title" style="margin-bottom:10px;">
                      <span v-for="waypoint in alternate.route.waypoints" :key="waypoint.id">
                        {{waypoint.name}}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile is-centered">
      <div class="column is-8 has-text-centered">
        <span>MODIFY&nbsp;&nbsp;BURN-OFF&nbsp;&nbsp;FUEL&nbsp;&nbsp;IN&nbsp;&nbsp; {{result.lmc}}&nbsp;{{result.aircraft.aircraft.weight_unit}}&nbsp;&nbsp;PER&nbsp;&nbsp;INC&nbsp;&nbsp;IN&nbsp;&nbsp;{{result.aircraft.aircraft.weight_unit == 'LB' ? '2200' : '1000' }}&nbsp;&nbsp;{{result.aircraft.aircraft.weight_unit}} &nbsp;OF&nbsp;&nbsp;WEIGHT&nbsp;&nbsp;VARIATION</span><br>
        <span>REASON&nbsp;&nbsp;FOR&nbsp;&nbsp;MODIFY&nbsp;&nbsp;FUEL&nbsp;&nbsp;:&nbsp;&nbsp;WEATHER&nbsp;&nbsp;/&nbsp;&nbsp;NOTAM&nbsp;&nbsp;/&nbsp;&nbsp;ATC&nbsp;&nbsp;/&nbsp;&nbsp;OTHERS_________________________</span><br>
        <span>ATIS:________________________________________________________________________________________________</span><br>
      </div>
    </div>
    <div class="columns is-mobile is-centered">
      <div class="column is-4">
        <nav class="level">
          <div class="column is-12">
            <div class="level-item has-text-centered">
              <div>
                <p class="title">
                  <span v-for="waypoint in result.flight.route.waypoints" :key="waypoint.id">
                    {{waypoint.name}}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="columns is-mobile is-centered">
      <div class="column is-8 has-text-centered">
        <span>CLEARANCE:______________________________________________________________________________________</span><br>
      </div>
    </div>
    <div class="columns is-mobile is-centered" v-if="result.nav_logs.length > 0">
      <div class="column is-8 has-text-centered">
        <span>AVERAGE: W/C {{result.nav_logs[0].average_wcomp}}</span>&nbsp;&nbsp;<span>TAS {{result.nav_logs[0].average_tas}}</span>&nbsp;&nbsp;<span>G.SPEED {{result.nav_logs[0].average_gs}}</span>&nbsp;&nbsp;<span>ETP {{result.nav_logs[0].etp}}</span>&nbsp;&nbsp;<span>MWSR {{result.nav_logs[0].mwsr}}</span>
      </div>
    </div>
    <div class="columns" style="margin-top:20px;">
      <div class="column is-12">
        <table style="border-bottom:hidden;min-width:100%;font-size:13px;margin-right:0px;margin-left:0px;">
          <tr>
            <td>
              APU ON:________
            </td>
            <td>
          CLOSE DOOR:________
        </td>
          <td>
          START UP:__________
        </td>
        <td>
          APU OFF:________
        </td>
        <td>
          T/O:___________
        </td>
        <td>
          LDG:__________
        </td>
        <td>
          APU ON:________
        </td>
        <td>
          SHUTDOWN:______<br>
          FUEL REM:_________
        </td>
      </tr>
    </table>
      </div>
    </div>
    <div class="columns" style="margin-top:20px;">
      <div class="column is-12">
      FUEL ON BOARD_____________
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "Header",
  props: ['result', 'header'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    pad (size, num) {
      var s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
    }
  },
};
</script>
<style scoped>
#HeaderSection {
  font-size: 16px;
}
#HeaderSection .card-header{
  background-color: lightgrey;
}
#HeaderSection .card {
  min-height: 100%;
}
#HeaderSection .columns {
  margin-top: 3px;
  margin-bottom: 3px;
  padding:2;
}
#HeaderSection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:2px;
}
#HeaderSection .tabla-sin-bordes {
  border-bottom:0px !important;
  border:0px !important;
}
#HeaderSection .tabla-sin-bordes th, .tabla-sin-bordes td {
  border: 1px !important;
  padding-top: 0em;
  padding-bottom: 0em;
}
#HeaderSection .tabla-sin-bordes tr td{
  border: 1px !important;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 1em;
  padding-right: 1.1em;
  text-align: right;
}
#HeaderSection .tabla-sin-bordes tr td:first-child{
  font-weight: bolder;
  text-align: left !important;
}
#HeaderSection .level-item .heading{
  font-size:1em;
}
#HeaderSection .level-item .title{
  font-size:1em;
}
@media print
{
  #HeaderSection {
    font-size: 14px;
  }
}
</style>
