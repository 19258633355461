<template>
  <section id="AirportsWeatherSection">
    <h2 class="title is-5">METAR DATA</h2>
    <div v-for="airport in result.airports" :key="airport.icao">
      <pre v-if="airport.display_metar"><span v-html="airport.raw_metar"></span></pre>
    </div>
    <h2 class="title is-5">TAF DATA</h2>
    <div v-for="(airport, index) in result.airports" :key="index">
      <pre v-if="airport.display_taf"><span v-html="airport.raw_taf"></span></pre>
    </div>
  </section>
</template>
<script>

export default {
  name: "AirportsWeather",
  props: ['result'],
  watch: {
    'result': function (plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style scoped>
#AirportsWeatherSection {
  font-size: 16px;
}

#AirportsWeatherSection .columns {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 2;
}

#AirportsWeatherSection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 2px;
}

#AirportsWeatherSection .tabla-sin-bordes {
  border-bottom: 0px !important;
  border: 0px !important;
}

#AirportsWeatherSection .tabla-sin-bordes th, .tabla-sin-bordes td {
  border: 1px !important;
  padding-top: 0em;
  padding-bottom: 0em;
}

#AirportsWeatherSection .tabla-sin-bordes tr td {
  border: 1px !important;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 1em;
  padding-right: 1.1em;
  text-align: right;
}

#AirportsWeatherSection .tabla-sin-bordes tr td:first-child {
  font-weight: bolder;
  text-align: left !important;
}

@media print {
  #AirportsWeatherSection {
    font-size: 14px;
  }
}
</style>
