<template>
  <section id="LatamTimeAndFuelSection">
    <div class="columns" style="border-top:solid;">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
            <td ><span class="cls_002">ORGN</span></td>
            <td colspan="3"><span class="cls_002">{{result.flight.route.departure}}/ {{result.flight.route.departure_iata}}</span></td>
            <td style="text-align:right!important;"><span class="cls_002">{{ result.flight.route.departure_elev }}FT</span></td>
            <td></td>
            <td colspan="4"><span class="cls_002">ROUTE  {{result.flight.route.id}}<span class="comentario">(route.id)</span></span></td>
            <td colspan="2"><span class="cls_002 missingdata">CI</span></td>
            <td style="text-align:right!important;"><span class="cls_002 missingdata">CRZ9</span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">DEST</span></td>
          <td colspan="3" class="cls_002"><span class="cls_002">{{result.flight.route.destination}}/ {{result.flight.route.destination_iata}}</span></td>
          <td class="cls_002" style="text-align:right!important;"><span class="cls_002">{{ result.flight.route.destination_elev }}FT</span></td>
          <td></td>
          <td colspan="2" class="cls_002"><span class="cls_002 missingdata">G/C DIST</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002 missingdata">595</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">AVG W/C</span></td>
          <td style="text-align:right!important;" class="cls_002"><span class="cls_002">{{result.nav_logs[0].average_wcomp}}</span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">ALTN</span></td>
          <td colspan="3" class="cls_002"><span class="cls_002">{{result.alternate_airports[0].route.destination}}/ {{result.alternate_airports[0].route.destination_iata}}</span></td>
          <td style="text-align:right!important;" class="cls_002"><span class="cls_002">{{ result.alternate_airports[0].route.destination_elev }}FT</span></td>
          <td></td>
          <td colspan="2" class="cls_002"><span class="cls_002 missingdata">AIR DIST</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002 missingdata">629</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002 missingdata">AVG WIND</span></td>
          <td style="text-align:right!important;" class="cls_002 missingdata"><span class="cls_002">240/034</span></td>
        </tr>
        <tr>
          <td colspan="6" class="cls_002"><span class="cls_002 missingdata">WX OBS 0512</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">GND DIST</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">{{result.flight.distance_to_go}}</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002 missingdata">AVG TEMP</span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">M50/P04</span></td>
        </tr>
        <tr>
          <td colspan="6" class="cls_002 missingdata"><span class="cls_002">WX PROG 0506 0512</span></td>
          <td colspan="2" class="cls_002 missingdata"><span class="cls_002">AVG FF</span></td>
          <td colspan="2" class="cls_002 missingdata"><span class="cls_002">2303  KG/H</span></td>
        </tr>
      </table>
    </div>
    <div class="columns">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="3"></td>
          <td class="cls_003" style="text-align:right!important;"><span class="cls_003">FUEL</span></td>
          <td class="cls_003" style="text-align:right!important;"><span class="cls_003">TIME</span></td>
          <td colspan="3"></td>
          <td class="cls_002"><span class="cls_002">PYLD</span></td>
          <td class="cls_002"><span class="cls_002">{{result.flight.payload_weight}}</span></td>
          <td class="cls_002"><span class="cls_002">APLD</span></td>
          <td class="cls_002"><span class="cls_002">_____</span></td>
          <td></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">DEST</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">{{result.flight.route.destination}}</span></td>
          <td class="cls_002 right"><span class="cls_002">{{result.plan_header.trip_fuel}}<br><span class="comentario">Trip</span></span></td>
          <td class="cls_002 right"><span class="cls_002">{{result.plan_header.trip_time}}</span></td>
          <td class="cls_002"><span class="cls_002">&nbsp;&nbsp;____</span></td>
          <td class="cls_002"><span class="cls_002">EZFW</span></td>
          <td class="cls_002"><span class="cls_002">{{result.flight.zero_fuel_weight}}<br><span class="comentario">zfw</span></span></td>
          <td class="cls_002"><span class="cls_002">MZFW</span></td>
          <td class="cls_002"><span class="cls_002">{{result.aircraft.aircraft.mzfw}}</span><br><span class="comentario">mzfw tabla</span></td>
          <td class="cls_002"><span class="cls_002">AZFW</span></td>
          <td class="cls_002"><span class="cls_002">_____</span></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002">RRSV</span></td>
          <td class="cls_002 right"><span class="cls_002">{{result.plan_header.contingency_fuel}}<br><span class="comentario">contingency</span></span></td>
          <td class="cls_002 right"><span class="cls_002">{{result.plan_header.contingency_time}}</span></td>
          <td class="cls_002"><span class="cls_002">&nbsp;&nbsp;____</span></td>
          <td class="cls_002"><span class="cls_002">ETOW</span></td>
          <td class="cls_002"><span class="cls_002">{{result.plan_header.brwt}}<span class="comentario"><br>brwt?</span></span></td>
          <td class="cls_002"><span class="cls_002">MTOW</span></td>
          <td class="cls_002"><span class="cls_002">{{result.aircraft.aircraft.mtow}}</span></td>
          <td class="cls_002"><span class="cls_002">ATOW</span></td>
          <td class="cls_002"><span class="cls_002">_____</span></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002">ALT  SCCF</span></td>
          <td class="cls_002 right"><span class="cls_002">1325</span></td>
          <td class="cls_002 right"><span class="cls_002">0027</span></td>
          <td class="cls_002"><span class="cls_002">&nbsp;&nbsp;____</span></td>
          <td class="cls_002"><span class="cls_002">ELDW</span></td>
          <td class="cls_002"><span class="cls_002">{{result.plan_header.ldgwt}}<br><span class="comentario">ldgwt</span></span></td>
          <td class="cls_002"><span class="cls_002">MLDW</span></td>
          <td class="cls_002"><span class="cls_002">{{result.aircraft.aircraft.mlw}}<br><span class="comentario">mlw tabla</span></span></td>
          <td class="cls_002"><span class="cls_002">ALDW</span></td>
          <td class="cls_002"><span class="cls_002">_____</span></td>
        </tr>
        <tr> 
          <td colspan="3" class="cls_002"><span class="cls_002">HOLD</span></td>
          <td class="cls_002 right"><span class="cls_002">{{result.plan_header.holding_fuel}}<br><span class="comentario">holding_fuel</span></span></td>
          <td class="cls_002 right"><span class="cls_002">0030</span></td>
          <td class="cls_002"><span class="cls_002">&nbsp;&nbsp;____</span></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002 missingdata">ADD</span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.xfuel_fuel}}<br><span class="comentario">x_fuel??</span></span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.xfuel_time}}</span></td>
          <td></td>
        </tr>
        <tr>  
          <td colspan="3" class="cls_002"><span class="cls_002 missingdata">MFR</span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.reqd_fuel}}<br><span class="comentario">reqd??</span></span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.reqd_time}}</span></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002 missingdata">TOF</span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.reqd_fuel}}<br><span class="comentario">reqd??</span></span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.reqd_time}}</span></td>
          <td></td>
          <td colspan="4" class="cls_002"><span class="cls_002">LDGWT INCLUDES RESERVE FUEL<br><span class="comentario">FOD Parece ser el combustible restante al final de la ruta principal?</span></span></td>
          <td class="cls_002"><span class="cls_002 missingdata">FOD</span></td>
          <td class="cls_002"><span class="cls_002 missingdata">2825</span></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002">TAXI</span></td>
          <td class="cls_002 right"><span class="cls_002">{{result.plan_header.taxi_out_fuel}}<br><span class="comentario">taxi_out</span></span></td>
          <td class="cls_002 right"><span class="cls_002">{{result.plan_header.taxi_out_time}}</span></td>
          <td colspan="4"></td>
          <td class="cls_002"><span class="cls_002">MFOD</span></td>
          <td class="cls_002"><span class="cls_002">2398</span></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002 missingdata">BLOCK</span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.total_fuel}}<br><span class="comentario">total?</span></span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">{{result.plan_header.total_time}}</span></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002">EXTRA</span></td>
          <td class="cls_002 right"><span class="cls_002">_____</span></td>
          <td></td>
          <td colspan="3" class="cls_002"><span class="cls_002">REASON _______</span></td>
        </tr>
        <tr>
          <td colspan="3" class="cls_002"><span class="cls_002">TOTAL</span></td>
          <td class="cls_002 right"><span class="cls_002">_____</span></td>
          <td></td>
          <td colspan="3"></td>
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamTimeAndFuel",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #LatamTimeAndFuelSection {
    font-size: 16px;
  }
}
</style>
