var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"LatamWeightAnalysisSection"}},[_c('div',{staticClass:"columns missingdata",staticStyle:{"border-top":"solid"}},[_c('table',{staticClass:"tabla-sin-bordes"},[_vm._l((13),function(index){return _c('col',{key:index,staticClass:"col-1"})}),_vm._m(0),_vm._m(1),_vm._m(2),_c('tr',_vm._l((13),function(i){return _c('td',{key:i},[_vm._v(". .    . .")])}),0),_vm._m(3),_vm._m(4),_c('tr',{attrs:{"height":"30px;"}}),_vm._m(5),_vm._m(6),_c('tr',{attrs:{"height":"30px;"}}),_vm._m(7),_vm._m(8),_c('tr',{attrs:{"height":"30px;"}}),_vm._m(9),_c('tr',{attrs:{"height":"30px;"}}),_vm._m(10),_vm._m(11),_c('tr',_vm._l((13),function(i){return _c('td',{key:i},[_vm._v(". .    . .")])}),0),_vm._m(12),_vm._m(13),_c('tr',_vm._l((13),function(i){return _c('td',{key:i},[_vm._v(". .    . .")])}),0),_vm._m(14),_c('tr',{attrs:{"height":"30px;"}}),_vm._m(15),_vm._m(16)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_003",staticStyle:{"text-align":"center!important"},attrs:{"colspan":"13"}},[_c('span',{staticClass:"cls_003"},[_vm._v("WEIGHT ANALYSIS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("A -")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"10"}},[_c('span',{staticClass:"cls_002"},[_vm._v("MAX ALLOWED TAKEOFF WEIGHT (MALTOW)")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("77000")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"cls_002",attrs:{"colspan":"12"}},[_c('span',{staticClass:"cls_002"},[_vm._v("STRUCTURAL")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("B -")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("MAX TAXI WEIGHT (MALTXW)")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("77000")])]),_c('td',{attrs:{"colspan":"3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TAXI OUT FUEL")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("MINUS")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("180")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("EQ")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("76820")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("C -")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("MAX ZERO FUEL WEIGHT (MAXZFW)")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("62500")])]),_c('td',{attrs:{"colspan":"3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TAKEOFF FUEL")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("PLUS")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("6586")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("EQ")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("69086")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("D -")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("MAX ALLOWED LANDING WEIGHT (MALLAW)")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("66000")])]),_c('td',{attrs:{"colspan":"3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TRIP FUEL")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("PLUS")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("3761")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("EQ")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("69761")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("E -")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("MAX ALLOWED TAKEOFF WEIGHT (MALTOW)")])]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("EQ")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("77000")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("F -")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("OFP LIMITED ZERO FUEL WEIGHT (ZFW)")])]),_c('td',{attrs:{"colspan":"5"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("FUEL TANKAGE")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("PLUS")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("6766")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("EQ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"11"}},[_c('span',{staticClass:"cls_002"},[_vm._v("OPERATIONAL ALLOWED WEIGHT FOR TAKEOFF")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("69086")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"cls_002",attrs:{"colspan":"8"}},[_c('span',{staticClass:"cls_002"},[_vm._v("(THE LEAST OF B C D E F)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"11"}},[_c('span',{staticClass:"cls_002"},[_vm._v("OPERATIONAL ALLOWED WEIGHT FOR TAKEOFF")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("69086")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"4"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TAKEOFF FUEL")])]),_c('td',{attrs:{"colspan":"3"}}),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("6586")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"4"}},[_c('span',{staticClass:"cls_002"},[_vm._v("ALLOWED TRAFFIC LOAD")])]),_c('td',{attrs:{"colspan":"3"}}),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("19485")])])])
}]

export { render, staticRenderFns }