<template>
  <section id="ScatATCSection">
    <div class="columns">
      <table class="tabla-sin-bordes">
        <col v-for="index in 18" :key="index" class="col-1outof18">
        <tr>
          <td colspan="18" class="cls_002">
            <span class="cls_002">
              <span v-for="(waypoint, index) in result.flight.route.waypoints" :key="waypoint.id">
                <span v-if="index == 0 || !result.flight.route.waypoints[index +1] || result.flight.route.waypoints[index +1].airway !== waypoint.airway">
                  {{waypoint.name}}
                </span>
                <span v-if="index > 0 && result.flight.route.waypoints[index - 1].airway != waypoint.airway">
                  {{waypoint.airway}}
                </span>
              </span>
            </span>
          </td>
        </tr>
        <tr class="missingdata">
          <td colspan="18" class="comentario">Aquí según lo que entendí de scat, muestran el AP y luego muestran siempre el último punto de cada airway, seguido del airway nuevo y finalmente el AP.  Abajo muestro los waypoints y sus airways entre corchetes para comparar, muchos puntos no tienen awy.  Pero según lo dicho arriba debería quedar así, solo tengo dudas con waypoints sin airways después del AP, porque si hay varios igual mostraría ese punto antes de mostrar el nuevo airway (Como pasa en el ejemplo que yo revisé, tengo varios puntos sin awy después del AP y según la lógica que pusea arriba, estoy mostrando el último de esos y luego el nuevo airway (!= ' ') </td>
        </tr>
        <tr class="comentario">
          <td colspan="18" class="cls_002">
            <span class="cls_002">
              <span v-for="waypoint in result.flight.route.waypoints" :key="waypoint.id">
                {{waypoint.name}} [{{waypoint.airway}}]
              </span>
            </span></td>
        </tr>
        <tr style="height:15px;"></tr>
      </table>
    </div>
    <div class="columns">
        <table class="tabla-sin-bordes">
            <col v-for="index in 16" :key="index" class="col-1outof16">
            <tr>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">QNH(QFE)</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002 right"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">ALTIMETER</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">&nbsp;&nbsp; .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
            </tr>
            <tr>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">ATIS INFORM</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002 right"><span class="cls_002">..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
            </tr>
            <tr>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">ATC CLEARANCE</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002 right"><span class="cls_002">..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">.. .. ..</span></td>
            </tr>
            <tr style="height:40px;"></tr>
        </table>
    </div>
  </section>
</template>
<script>

export default {
  name: "ScatATC",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    pad (size, num) {
      var s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
};
</script>
<style>
@media print
{
  #ScatATCSection {
    font-size: 20px;
  }
}
</style>
