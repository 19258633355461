var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"WindRoseDepartureAtisInformationSection"}},[_c('div',{staticClass:"columns"},[_c('table',{staticClass:"tabla-sin-bordes"},[_vm._l((18),function(index){return _c('col',{key:index,staticClass:"col-1outof18"})}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"18"}},[_c('span',{staticClass:"cls_002"},[_vm._v("DEPARTURE INFO")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("ATIS")])]),_c('td',{attrs:{"colspan":"15"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{attrs:{"colspan":"16"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{attrs:{"colspan":"16"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("ATC")])]),_c('td',{attrs:{"colspan":"15"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{attrs:{"colspan":"16"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{attrs:{"colspan":"16"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("EOSID")])]),_c('td',{attrs:{"colspan":"15"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{attrs:{"colspan":"16"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"height":"50px"}},[_c('td',{attrs:{"colspan":"16"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"18px"}},[_c('tr',{staticStyle:{"border-bottom":"3px dashed"}},[_c('td')])])])])
}]

export { render, staticRenderFns }