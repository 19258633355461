<template>
  <section id="PlanReportSection" class="printable">
    <div>
      <a style="position:fixed;right:30px;" class="button is-info is-medium is-rounded no-print" @click="print"><i class="fa fa-print"></i>&nbsp;Print</a>
      <article class="message is-warning no-print" v-if="hasAlerts">
        <div class="message-body">
          <p v-for="(alert, key) in alerts" :key="key">{{alert.description}}</p>
        </div>
      </article>
    </div>
    <avia-solutions-format v-if="reportFormat === 'AviaSolutions'" :flightPlan="flightPlan" :result="result"></avia-solutions-format>
    <latam-format v-if="reportFormat === 'Latam'" :flightPlan="flightPlan" :result="result"></latam-format>
    <rusline-format v-if="reportFormat === 'Rusline'" :flightPlan="flightPlan" :result="result"></rusline-format>
    <wind-rose-format v-if="reportFormat === 'WindRose'" :flightPlan="flightPlan" :result="result"></wind-rose-format>
    <scat-format v-if="reportFormat === 'Scat'" :flightPlan="flightPlan" :result="result"></scat-format>
  </section>
</template>
<script>

import AviaSolutionsFormat from "./plan-report/formats/AviaSolutionsFormat.vue"
import LatamFormat from "./plan-report/formats/LatamFormat.vue"
import RuslineFormat from "./plan-report/formats/RuslineFormat.vue"
import WindRoseFormat from "./plan-report/formats/WindRoseFormat.vue"
import ScatFormat from "./plan-report/formats/ScatFormat.vue"

export default {
  name: "PlanReport",
  props: ['flightPlan', 'result'],
  data() {
    return {
      alerts: this.result ? this.result.alerts : []
    }
  },
  computed: {
    hasAlerts() {
      return this.alerts && this.alerts.length > 0;
    },
    reportFormat() {
      return this.result ? this.result.client && this.result.client.report_format.name : null;
    }
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    print () {
      window.print();
    }
  },
  mounted() {
    this.flightPlan.showComments = true
  },
  components: {
    AviaSolutionsFormat,
    LatamFormat,
    RuslineFormat,
    WindRoseFormat,
    ScatFormat
  }
};
</script>
<style>
#PlanReportSection .card-header{
  background-color: lightblue;
}
#PlanReportSection .card {
  min-height: 100%;
}
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
