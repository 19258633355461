var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"WindRoseTakeoffPerformanceCalculationsSection"}},[_c('div',{staticClass:"columns"},[_c('table',{staticClass:"tabla-sin-bordes"},[_vm._l((18),function(index){return _c('col',{key:index,staticClass:"col-1outof18"})}),_vm._m(0),_c('tr',{staticStyle:{"height":"20px"}}),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('tr',{staticClass:"comentario",staticStyle:{"height":"20px"}}),_vm._m(5)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"14px"}},[_c('tr',{staticStyle:{"border-top":"3px dashed"}},[_c('td')])])]),_c('td',{staticClass:"cls_002 center",attrs:{"colspan":"8"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TAKEOFF PERFORMANCE CALCULATIONS")])]),_c('td',{attrs:{"colspan":"5"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"14px"}},[_c('tr',{staticStyle:{"margin-top":"19px","border-bottom":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("RWY ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("WMAX= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("WACT= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("FLAPS ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("V1= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("VR= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("V2= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TFLEX= ...")])]),_c('td',{attrs:{"colspan":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("RWY ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("WMAX= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("WACT= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("FLAPS ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("V1= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("VR= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("V2= ...")])]),_c('td',{staticClass:"cls_002",attrs:{"nowrap":"nowrap","colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("TFLEX= ...")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("RES")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"3"}},[_c('span',{staticClass:"cls_002"},[_vm._v("MAC= ......")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"15"}},[_c('span',{staticClass:"cls_002"},[_vm._v("STAB SETTING ...")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"18"}},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"14px"}},[_c('tr',{staticStyle:{"border-top":"3px dashed"}},[_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"comentario"},[_c('td',{staticClass:"missingdata",attrs:{"colspan":"18"}},[_vm._v("Sección 8.1.10.9 Air Traffic Clearance va? pendiente? Dudas con la parte de emisiones CO2 sobre todo ")])])
}]

export { render, staticRenderFns }