<template>
  <section id="ScatTimeAndFuelSection">
    <div class="columns">
      <table class="tabla-sin-bordes">
        <col v-for="index in 18" :key="index" class="col-1outof18">
        <tr>
            <td colspan="3"></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">FUEL</span></td>
            <td class="cls_002 right"><span class="cls_002">TIME</span></td>
            <td nowrap="nowrap" colspan="3" class="cls_002 center"><span class="cls_002">FUEL CORR</span></td>
            <td class="cls_002"><span class="cls_002">OWE</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.aircraft.eow }}</span></td>
            <td class="cls_002"><span class="cls_002">PYLD</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.flight.payload_weight}}</span></td>
            <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">APLD ________</span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">DEST {{ result.flight.route.destination }}</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.trip_fuel }}<span class="comentario"><br>trip?</span></span></td>
            <td class="cls_002 right"><span class="cls_002">{{ result.plan_header.trip_time }}</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td class="cls_002"><span class="cls_002">EZFW</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.flight.zero_fuel_weight }}</span></td>
            <td class="cls_002"><span class="cls_002">MZFW</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.aircraft.aircraft.mzfw }}</span></td>
            <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">AZFW ________</span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">RRSV <span class="missingdata">{{result.aircraft.aircraft.deg}} PC <span class="comentario">degradation?</span></span></span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.contingency_fuel}}<span class="comentario"><br>contingency?</span></span></td>
            <td class="cls_002 right"><span class="cls_002">{{result.plan_header.contingency_time}}</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td class="cls_002"><span class="cls_002">ETOW</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.brwt}}<span class="comentario"><br>brwt?</span></span></td>
            <td class="cls_002"><span class="cls_002">MTOW</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.aircraft.aircraft.mtow}}</span></td>
            <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">ATOW ________</span></td>
        </tr>
        <tr>
            <td colspan="9"></td>
            <td class="cls_002"><span class="cls_002">ELDW</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.ldgwt }}</span></td>
            <td class="cls_002 right"><span class="cls_002">MLDW</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.aircraft.aircraft.mlw }}</span></td>
            <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">ALDW ________</span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">ALTERNATE</span><span class="comentario">alternate</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.alternate_fuel }}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ result.plan_header.alternate_time }}</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td colspan="3" class="cls_002"><span class="cls_002">{{result.alternate_airports[0].route.destination}} FL {{result.alternate_airports[0].flight_level}}<span class="comentario"><br>fl ingresado para ruta alt?</span></span></td>
            <td colspan="6" class="cls_002"><span class="cls_002">{{result.nav_logs[1].footer.dist}} NM  <span class="missingdata">M.70</span> W/C {{result.nav_logs[1].average_wcomp}}</span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">HOLD-ALT<span class="comentario"><br>holding</span></span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.holding_fuel}}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{result.plan_header.holding_time}}</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td colspan="9" class="cls_002"><span class="cls_002">COST INDEX {{ result.flight.cruise_speed }}<span class="comentario">cruise speed seleccionado?</span></span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">REQD</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.reqd_fuel}}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{result.plan_header.reqd_time}}</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td colspan="2" nowrap="nowrap" class="cls_002"><span class="cls_002">NOTE&nbsp;&nbsp;&nbsp;-</span></td>
            <td colspan="7" class="cls_002"><span class="cls_002">LDGWT INCLUDES RESERVE FUEL</span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">ADD<span class="comentario"><br>additional? no tenemos.  Creo que en Latam usamos el xfuel para el ADD, pero no había un extra, en scat hay un extra, ese debería ser el xfuel y este el additional me parece, en latam estaría mal?</span></span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">0</span></td>
            <td class="cls_002 right"><span class="cls_002">0000</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td colspan="2" nowrap="nowrap" class="cls_002"><span class="cls_002">NOTE&nbsp;&nbsp;&nbsp;-</span></td>
            <td colspan="7" class="cls_002"><span class="cls_002">INCREMENTAL BURN PER 2000KG<span class="comentario"><br>Aquí siempre en KG o si el weight unit del aircraft está en LB pasar a LB?</span></span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">EXTRA</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{result.plan_header.xfuel_fuel}}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{result.plan_header.xfuel_time}}</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td colspan="2"></td>
            <td colspan="8" class="cls_002"><span class="cls_002">INCREASE/DECREASE IN TOW:{{result.lmc}}<span class="comentario"><br>lmc? si es así, aquí usar lmc y weight unit del aircraft o pasarlo siempre a KG</span></span></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">TAXI</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.taxi_out_fuel }}</span></td>
            <td colspan="13"></td>
        </tr>
        <tr>
            <td colspan="3" class="cls_002"><span class="cls_002">TOTAL<span class="comentario"><br>Revisando por qué el total no sumaba el taxi, vi que en la clase de combustibles para sacar el total estamos sumando el req y el taxi in, en lugar del out, supongo que esdtá mal?</span></span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ result.plan_header.total_fuel }}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ result.plan_header.total_time }}</span></td>
            <td colspan="3" class="cls_002 center"><span class="cls_002">_______</span></td>
            <td colspan="9" class="cls_002"><span class="cls_002">CAPTAIN ______________________</span></td>
        </tr>
      </table>
    </div>
  </section>
</template>
<script>

export default {
  name: "ScatTimeAndFuel",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    pad (size, num) {
      var s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
};
</script>
<style>
@media print
{
  #ScatTimeAndFuelSection {
    font-size: 20px;
  }
}
</style>
