<template>
  <section id="WindRoseDestinationAlternateDataSection">
      <div class="column">
        <table class="tabla-sin-bordes">
            <col v-for="index in 18" :key="index" class="col-1outof18">
            <tr>
              <td colspan="6">
                <table class="tabla-sin-bordes" style="margin-top:14px;">
                  <tr style="border-top: 3px dashed;">
                    <td></td>
                  </tr>
                </table>
              </td>
              <td colspan="6" class="cls_002 center"><span class="cls_002">DESTINATION ALTERNATE DATA</span></td>
              <td colspan="6">
                <table class="tabla-sin-bordes" style="margin-top:14px;">
                  <tr style="margin-top:19px;border-bottom: 3px dashed;">
                    <td></td>
                  </tr>
                </table>
              </td>
            </tr>
        </table>
      </div>
      <div v-for="(navLog, index) in navLogs" :key="index" class="column">
        <template v-if="index > 0">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">ALTERNATE - {{index}}</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">EGCC/MAN</span></td>
                <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">ELEV 257FT</span></td>
                <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">GDIST 152</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">FL 120</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">AWC P027</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">AVGT M03</span></td>
                <td></td>
              </tr>
              <tr>
                <td colspan="3"></td>  
                <td nowrap="nowrap" colspan="4" class="cls_002"><span class="cls_002">N5321.2 W00216.5</span></td>
                <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">TIME 00.29</span></td>
                <td nowrap="nowrap" colspan="3" class="cls_002"><span class="cls_002">BURN 1725</span></td>
                <td nowrap="nowrap" colspan="4" class="cls_002"><span class="cls_002">MIN FUEL REQ 3244</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">ROUTE:</span></td>
              </tr>
              <tr>
                <td colspan="18" class="cls_002"><span class="cls_002">EGSS BKY2S D358G DCT MOGLI UP6 LESTA DAYNE1B EGCC</span></td>
              </tr>
          </table>
          <table v-if="index > 0" class="tabla-sin-bordes" style="margin-top:20px;">
              <col v-for="index in 16" :key="index" class="col-1outof16">
              <tr>
                <td class="cls_002"><span class="cls_002">AWY</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">WPT</span></td>
                <td class="cls_002"><span class="cls_002">FREQ</span></td>
                <td class="cls_002"><span class="cls_002">FL</span></td>
                <td class="cls_002 right"><span class="cls_002">MAC</span></td>
                <td class="cls_002"><span class="cls_002">TAS</span></td>
                <td class="cls_002"><span class="cls_002">GS</span></td>
                <td class="cls_002"><span class="cls_002">MCSE</span></td>
                <td class="cls_002 right"><span class="cls_002">DTGO</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">ETMGO</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">FBGO</span></td>
                <td class="cls_002"><span class="cls_002">ETO</span></td>
                <td class="cls_002"><span class="cls_002">/ATO</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">LAT / LONG</span></td>
                <td class="cls_002"><span class="cls_002">MORA</span></td>
                <td class="cls_002 right"><span class="cls_002">MT</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">WIND</span></td>
                <td class="cls_002"><span class="cls_002">ITCS</span></td>
                <td class="cls_002 right"><span class="cls_002">DREM</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">TTMEX</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">FREM</span></td>
                <td class="cls_002"><span class="cls_002">EFO</span></td>
                <td class="cls_002"><span class="cls_002">/AFO</span></td>
              </tr>
              <tr style="height:10px;"></tr>
              <tr style="margin-top:19px;border-bottom: 3px dashed;">
                <td colspan="16"></td>
              </tr>
              <tr style="height:10px;"></tr>
            <template v-for="(point) in navLog.points">
              <tr :key="point.name + 'tr1'">
                <td class="cls_002"><span class="cls_002">{{ point.airway == '' ? '...' : point.airway }}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.freq != 0 ? point.freq : '' }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? point.mach : point.flvl}}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.mach }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.tas }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.gs }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.mcrs }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.znd }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.znt }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.fuel }}</span></td>
                <td class="cls_002"><span class="cls_002">.....</span></td>
                <td class="cls_002"><span class="cls_002">/.....</span></td>
              </tr>
              <tr :key="point.name + 'tr2'">
                <td colspan="4" class="cls_002"><span class="cls_002">{{ point.latitude }} {{ point.longitude }}</span></td>
                <td class="cls_002"><span class="cls_002">16</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? point.mach : point.temp }}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">({{ formatWind(point.wind) }})</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.mhdg }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.distance_to_go }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.time }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.ttl_fuel }}</span></td>
                <td class="cls_002"><span class="cls_002">.....</span></td>
                <td class="cls_002"><span class="cls_002">/.....</span></td>
              </tr>
              <tr :key="point.name + 'tr3'" style="height:10px;"></tr>
              <tr :key="point.name + 'tr4'" style="margin-top:19px;border-bottom: 3px dashed;">
                <td colspan="16"></td>
              </tr>
              <tr :key="point.name + 'tr5'" style="height:10px;"></tr>
            </template>
            <tr style="height:20px;"></tr>
            <tr style="height:40px;">
              <td colspan="16" class="cls_002"></td>
            </tr>
            <tr style="height:10px;margin-top:19px;border-bottom: 3px dashed;">
              <td colspan="16"></td>
            </tr>
          </table> 
        </template>
      </div>
      <div class="column">
        <table class="tabla-sin-bordes">
            <col v-for="index in 18" :key="index" class="col-1outof18">
            <tr v-if="!navLogs[1]">
              <td colspan="18" class="cls_002"><span class="cls_002">ALTERNATE - 1 NOT REQUESTED</span></td>
            </tr>
            <tr v-if="!navLogs[2]">
              <td colspan="18" class="cls_002"><span class="cls_002">ALTERNATE - 2 NOT REQUESTED</span></td>
            </tr>
            <tr v-if="!navLogs[3]">
              <td colspan="18" class="cls_002"><span class="cls_002">ALTERNATE - 3 NOT REQUESTED</span></td>
            </tr>
            <tr v-if="!navLogs[4]">
              <td colspan="18" class="cls_002"><span class="cls_002">ALTERNATE - 4 NOT REQUESTED</span></td>
            </tr>
            <tr style="height:50px;"></tr> 
            <tr class="missingdata">
              <td colspan="18">One engine operative and depressurization procedures (ETP) no lo tenemos</td>
            </tr>
            <tr style="height:50px;"></tr> 
            <tr class="missingdata">
              <td colspan="18">ATC Flight Plan pendiente</td>
            </tr>
            <tr style="height:50px;"></tr> 
            <tr class="missingdata">
              <td colspan="18">Minimum Navigation Performance Specification (MNPS) / Reduced Vertical Separation Minima (RVSM) operations.  Va?</td>
            </tr>
        </table>
      </div>
  </section>
</template>
<script>

export default {
  name: "WindRoseDestinationAlternateData",
  props: ['result', 'navLogs'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    deg(deg) {
      if (deg < 0) {
        return 'M'+Math.abs(deg);
      }
      if (deg > 0) {
        return 'P'+deg;
      }
      return 0;
    },
    formatWind (wind) {
      wind = String(wind)
      return wind.substring(0, 3) + '/' + wind.substring(3);
    }
  }
};
</script>
<style>
@media print
{
  #WindRoseDestinationAlternateDataSection {
    font-size: 16px;
  }
}
</style>
