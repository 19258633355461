<template>
  <section id="WindRoseEnrouteDataSection">
      <div class="columns pagebreak">
          <table class="tabla-sin-bordes">
              <col v-for="index in 16" :key="index" class="col-1outof16">
              <tr>
                <td colspan="16" class="cls_002"><span class="cls_002">EALT:</span></td>
              </tr>
              <tr>
                <td colspan="16" class="cls_002"><span class="cls_002">{{ result.flight.route.departure}} ELEV {{ result.flight.route.departure_elev }}FT {{ result.flight.route.departure_lat }} {{ result.flight.route.departure_long }} <span class="missingdata">(MOST CRITICAL MORA 2300FT/NIGUG)</span></span></td>
              </tr>
              <tr>
                <td class="cls_002"><span class="cls_002">AWY</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">WPT</span></td>
                <td class="cls_002"><span class="cls_002">FREQ</span></td>
                <td class="cls_002"><span class="cls_002">FL</span></td>
                <td class="cls_002 right"><span class="cls_002">MAC</span></td>
                <td class="cls_002"><span class="cls_002">TAS</span></td>
                <td class="cls_002"><span class="cls_002">GS</span></td>
                <td class="cls_002"><span class="cls_002">MCSE<span class="comentario"><br>rhumbline es nuestro mcrs?</span></span></td>
                <td class="cls_002 right"><span class="cls_002">DTGO</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">ETMGO</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">FBGO</span></td>
                <td class="cls_002"><span class="cls_002">ETO</span></td>
                <td class="cls_002"><span class="cls_002">/ATO</span></td>
              </tr>
              <tr>
                <td colspan="4" class="cls_002"><span class="cls_002">LAT / LONG</span></td>
                <td class="cls_002 missingdata"><span class="cls_002">MORA</span></td>
                <td class="cls_002 right"><span class="cls_002">MT</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">WIND</span></td>
                <td class="cls_002"><span class="cls_002">ITCS<span class="comentario"><br>Great circle será  nuestro mhdg (heading) ?</span></span></td>
                <td class="cls_002 right"><span class="cls_002">DREM</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">TTMEX</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">FREM</span></td>
                <td class="cls_002"><span class="cls_002">EFO</span></td>
                <td class="cls_002"><span class="cls_002">/AFO</span></td>
              </tr>
              <tr style="height:10px;"></tr>
              <tr style="margin-top:19px;border-bottom: 3px dashed;">
                <td colspan="16"></td>
              </tr>
              <tr style="height:10px;"></tr>
              <tr>
                <td class="cls_002"><span class="cls_002">X</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">X</span></td>
                <td class="cls_002"><span class="cls_002">X</span></td>
                <td class="cls_002"><span class="cls_002">X</span></td>
                <td class="cls_002 right"><span class="cls_002">X</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">X</span></td>
                <td class="cls_002"><span class="cls_002">X</span></td>
                <td class="cls_002 right"><span class="cls_002">{{result.flight.distance_to_go}}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">X</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">13120</span></td>
                <td class="cls_002"><span class="cls_002">.....</span></td>
                <td class="cls_002"><span class="cls_002">/.....</span></td>
              </tr>
              <tr style="height:10px;"></tr>
              <tr style="margin-top:19px;border-bottom: 3px dashed;">
                <td colspan="16"></td>
              </tr>
              <tr style="height:10px;"></tr>
              <!-- <tr class="comentario">
                <td class="cls_002"><span class="cls_002">DCT</span></td>
                <td colspan="3" class="cls_002"><span class="cls_002">50195N</span></td>
                <td class="cls_002"><span class="cls_002">CLB</span></td>
                <td class="cls_002 right"><span class="cls_002">CLB</span></td>
                <td class="cls_002"><span class="cls_002">259</span></td>
                <td class="cls_002"><span class="cls_002">245</span></td>
                <td class="cls_002"><span class="cls_002">154</span></td>
                <td class="cls_002 right"><span class="cls_002">001</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">0</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">49</span></td>
                <td class="cls_002"><span class="cls_002">.....</span></td>
                <td class="cls_002"><span class="cls_002">/.....</span></td>
              </tr>
              <tr class="comentario">
                <td colspan="4" class="cls_002"><span class="cls_002">N5019.5 E03054.2</span></td>
                <td class="cls_002"><span class="cls_002">16</span></td>
                <td class="cls_002 right"><span class="cls_002">CLB</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">(106/024)</span></td>
                <td class="cls_002"><span class="cls_002">154</span></td>
                <td class="cls_002 right"><span class="cls_002">1220</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">00.00</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">13071</span></td>
                <td class="cls_002"><span class="cls_002">.....</span></td>
                <td class="cls_002"><span class="cls_002">/.....</span></td>
              </tr>
              <tr class="comentario" style="height:10px;"></tr>
              <tr class="comentario" style="margin-top:19px;border-bottom: 3px dashed;">
                <td colspan="16"></td>
              </tr>
              <tr class="comentario" style="height:10px;"></tr> -->
              <!-- INICIO REAL -->
            <template v-for="(point, index) in navLog.points">
              <tr :key="point.name + 'tr1'">
                <td class="cls_002"><span class="cls_002">{{ point.airway == '' ? '...' : point.airway }}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.freq != 0 ? point.freq : '' }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? point.mach : point.flvl}}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.mach }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.tas }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.gs }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.mcrs }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.znd }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.znt }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.fuel }}</span></td>
                <td class="cls_002"><span class="cls_002">.....</span></td>
                <td class="cls_002"><span class="cls_002">/.....</span></td>
              </tr>
              <tr :key="point.name + 'tr2'">
                <td colspan="4" class="cls_002"><span class="cls_002">{{ point.latitude }} {{ point.longitude }}</span></td>
                <td class="cls_002 missingdata"><span class="cls_002"></span></td>
                <td class="cls_002 right"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? point.mach : point.temp }}</span></td>
                <td colspan="2" class="cls_002"><span class="cls_002">({{ formatWind(point.wind) }})</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.mhdg }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.distance_to_go }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.time }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.ttl_fuel }}</span></td>
                <td class="cls_002"><span class="cls_002">.....</span></td>
                <td class="cls_002"><span class="cls_002">/.....</span></td>
              </tr>
              <tr :key="point.name + 'tr3'" style="height:10px;"></tr>
              <tr :key="point.name + 'tr4'" style="margin-top:19px;border-bottom: 3px dashed;">
                <td colspan="16"></td>
              </tr>
              <tr :key="point.name + 'tr5'" style="height:10px;"></tr>
            </template>
            <tr style="height:20px;"></tr>
            <tr>
              <td colspan="16" class="cls_002"><span class="cls_002">{{ result.flight.route.destination}} ELEV {{ result.flight.route.destination_elev }}FT {{ result.flight.route.destination_lat }} {{ result.flight.route.destination_long }}</span></td>
            </tr>
            <tr style="height:10px;margin-top:19px;border-bottom: 3px dashed;">
              <td colspan="16"></td>
            </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "WindRoseEnrouteData",
  props: ['result', 'navLog'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    deg(deg) {
      if (deg < 0) {
        return 'M'+Math.abs(deg);
      }
      if (deg > 0) {
        return 'P'+deg;
      }
      return 0;
    },
    formatWind (wind) {
      wind = String(wind)
      return wind.substring(0, 3) + '/' + wind.substring(3);
    }
  }
};
</script>
<style>
@media print
{
  #WindRoseEnrouteDataSection {
    font-size: 16px;
  }
}
</style>
