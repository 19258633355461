<template>
  <section id="RuslineSummarySection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr>
                    <td colspan="8" class="cls_002"><span class="cls_002">FLIGHT PLAN ROUTE</span></td>
                    <td colspan="10" class="cls_002"><span class="cls_002">COMPANY ROUTE: {{result.flight.route.code}}</span></td>
                </tr>
                <tr>
                <td colspan="18" class="cls_002">
                    <span class="cls_002">
                    <span v-for="(waypoint, index) in result.flight.route.waypoints" :key="waypoint.id">
                        <span v-if="index == 0 || !result.flight.route.waypoints[index +1] || result.flight.route.waypoints[index +1].airway !== waypoint.airway">
                        {{waypoint.name}}
                        </span>
                        <span v-if="index > 0 && result.flight.route.waypoints[index - 1].airway != waypoint.airway">
                        {{waypoint.airway}}
                        </span>
                    </span>
                    </span>
                </td>
                </tr>
                <tr class="missingdata">
                <td colspan="18" class="comentario">Aquí según lo que entendí de de WindRose (que era igual a lo de SCAT), muestran el AP y luego muestran siempre el último punto de cada airway, seguido del airway nuevo y finalmente el AP.  Abajo muestro los waypoints y sus airways entre corchetes para comparar, muchos puntos no tienen awy.  Pero según lo dicho arriba debería quedar así, solo tengo dudas con waypoints sin airways después del AP, porque si hay varios igual mostraría ese punto antes de mostrar el nuevo airway (Como pasa en el ejemplo que yo revisé, tengo varios puntos sin awy después del AP y según la lógica que pusea arriba, estoy mostrando el último de esos y luego el nuevo airway (!= ' ') </td>
                </tr>
                <tr class="comentario">
                <td colspan="18" class="cls_002">
                    <span class="cls_002">
                    <span v-for="waypoint in result.flight.route.waypoints" :key="waypoint.id">
                        {{waypoint.name}} [{{waypoint.airway}}]
                    </span>
                    </span></td>
                </tr>
                <tr style="margin-top:10px;">
                    <td class="cls_002"><span class="cls_002">FL</span></td>
                    <td class="cls_002 right"><span class="cls_002">{{ result.flight.flight_level }}</span></td>
                    <td colspan="16"></td>
                </tr>
                <tr style="height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr>
                    <td colspan="4" class="cls_002"><span class="cls_002">TAKE OFF ALTN</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">TRACK(T)</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">DIST</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">TIME</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">ETA</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">MORA</span></td>
                    <td colspan="3" class="cls_002"><span class="cls_002">MAX LNDG WT</span></td>
                    <td></td>
                </tr>
                <tr class="comentario">
                    <td colspan="18">Esta parte decía dejar en blanco.. así??</td>
                </tr>
                <tr style="height:30px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td colspan="15" class="cls_002"><span class="cls_002">FUEL CORR</span></td>
                </tr>
                <tr>
                    <td class="cls_002"><span class="cls_002">TOW</span></td>
                    <td class="cls_002"><span class="cls_002">CORR</span></td>
                    <td class="cls_002"><span class="cls_002">PS</span></td>
                    <td class="cls_002"><span class="cls_002">2000</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">PLNTOF</span></td>
                    <td class="cls_002"><span class="cls_002">PS</span></td>
                    <td colspan="11"></td>
                </tr>
                <tr>
                    <td class="cls_002"><span class="cls_002">TOW</span></td>
                    <td class="cls_002"><span class="cls_002">CORR</span></td>
                    <td class="cls_002"><span class="cls_002">PS</span></td>
                    <td class="cls_002"><span class="cls_002">2000</span></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">PLNTOF</span></td>
                    <td class="cls_002"><span class="cls_002">MS</span></td>
                    <td colspan="11"></td>
                </tr>
                <tr>
                    <td class="cls_002"><span class="cls_002">FL</span></td>
                    <td class="cls_002"><span class="cls_002">CORR</span></td>
                    <td></td>
                    <td></td>
                    <td colspan="2" class="cls_002"><span class="cls_002">PLNTOF</span></td>
                    <td colspan="12"></td>
                </tr>
                <tr class="comentario">
                    <td colspan="18">Esta parte decía dejar "tal cual"... así??</td>
                </tr>
                <tr style="height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="height:20px;">
                    <td colspan="18"></td>
                </tr>
                <tr>
                    <td colspan="9" class="cls_002"><span class="cls_002">DISP NAME {{result.flight.flight_dispatcher}}</span></td>
                    <td colspan="9" class="cls_002"><span class="cls_002">CAPT NAME {{result.flight.captain}}</span></td>
                </tr>
                <tr style="height:60px;">
                    <td colspan="18"></td>
                </tr>
                <tr style="border-top:dashed;border-width:3px;height:10px;">
                    <td colspan="18"></td>
                </tr>
                <tr>
                    <td colspan="9" class="cls_002"><span class="cls_002">CLEARANCES</span></td>
                    <td colspan="3"></td>
                    <td colspan="5" style="text-align:center!important;" class="cls_002"><span class="cls_002">RVSM ALT CHK</span></td>
                </tr>
                <tr>
                    <td colspan="11"></td>
                    <td class="cls_002"><span class="cls_002">T</span></td>
                    <td class="cls_002" style="text-align:center!important;"><span class="cls_002">:</span></td>
                    <td class="cls_002"><span class="cls_002">CAPT</span></td>
                    <td class="cls_002" style="text-align:center!important;"><span class="cls_002">:</span></td>
                    <td class="cls_002"><span class="cls_002">S/BY</span></td>
                    <td class="cls_002" style="text-align:center!important;"><span class="cls_002">:</span></td>
                    <td class="cls_002"><span class="cls_002">F/O</span></td>
                </tr>
                <tr>
                    <td colspan="11"></td>
                    <td colspan="7">
                        <table class="tabla-sin-bordes">
                            <tr style="height:10px;">
                                <td colspan="18"></td>
                            </tr>
                            <tr style="border-top:dashed;border-width:3px;height:10px;">
                                <td style="min-width:100%;"></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="11"></td>
                    <td class="cls_002"><span class="cls_002"></span></td>
                    <td class="cls_002" style="text-align:center!important;"><span class="cls_002">:</span></td>
                    <td class="cls_002"><span class="cls_002"></span></td>
                    <td class="cls_002" style="text-align:center!important;"><span class="cls_002">:</span></td>
                    <td class="cls_002"><span class="cls_002"></span></td>
                    <td class="cls_002" style="text-align:center!important;"><span class="cls_002">:</span></td>
                    <td class="cls_002"><span class="cls_002"></span></td>
                </tr>
                <tr class="comentario">
                    <td colspan="18">Arriba ni idea... tal cual?</td>
                </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "RuslineSummary",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style>
@media print
{
  #RuslineSummarySection {
    font-size: 16px;
  }
}
</style>
