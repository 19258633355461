<template>
  <section id="AirportsNotamSection">
    <div v-for="(airport, index) in result.airports" :key="'notam_airport_' + index">
      <h2 class="title is-5" v-if="airport.display_notam && airport.raw_notam">{{ airport.icao }} NOTAM (RAW)</h2>
      <div v-for="(notam, index) in airport.raw_notam" :key="'raw_notam_' + index">
          <pre v-html="notam"></pre>
      </div>
      <h2 class="title is-5" v-if="airport.display_notam && airport.report_notam">{{ airport.icao }} NOTAM (REPORT)</h2>
      <div v-for="(notam, index) in airport.report_notam" :key="'report_notam_' + index">
          <pre v-html="notam"></pre>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "AirportsNotam",
  props: ['result'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style scoped>
#AirportsNotamSection {
  font-size: 16px;
}
#AirportsNotamSection .columns {
  margin-top: 3px;
  margin-bottom: 3px;
  padding:2;
}
#AirportsNotamSection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:2px;
}
#AirportsNotamSection .tabla-sin-bordes {
  border-bottom:0px !important;
  border:0px !important;
}
#AirportsNotamSection .tabla-sin-bordes th, .tabla-sin-bordes td {
  border: 1px !important;
  padding-top: 0em;
  padding-bottom: 0em;
}
#AirportsNotamSection .tabla-sin-bordes tr td{
  border: 1px !important;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 1em;
  padding-right: 1.1em;
  text-align: right;
}
#AirportsNotamSection .tabla-sin-bordes tr td:first-child{
  font-weight: bolder;
  text-align: left !important;
}
@media print
{
  #AirportsNotamSection {
    font-size: 14px;
  }
}
</style>
