<template>
  <section id="LatamNavigationSummarySection">
    <div class="columns" style="border-top:solid;margin-top:30px;">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="13" style="text-align:center!important;" class="cls_003"><span class="cls_003">NAVIGATION SUMMARY</span></td>
        </tr>
        <tr>
          <td colspan="13">
            <div class="columns is-multiline" style="margin-left:0px;">
              <div class="column is-1 cls_002"><span class="cls_002">SCELR17R</span></div>
              <div class="column is-1 cls_002"><span class="cls_002">DONTI1B</span></div>
              <div class="column is-1 cls_002"><span class="cls_002">DONTI</span></div>
              <div class="column is-1 cls_002"><span class="cls_002">UQ802</span></div>
              <div class="column is-1 cls_002"><span class="cls_002">SUNRU</span></div>
              <div class="column is-1 cls_002"><span class="cls_002">SUNRU1</span></div>
              <div class="column is-1 cls_002"><span class="cls_002">SCFAR19</span></div>
            </div>
          </td>
        </tr>
        <tr class="comentario">
          <td colspan="13">Esto no está implementado pero debería ser solo un listado de las diferentes airways?</td>
        </tr>
        <tr class="missingdata">
          <td colspan="4" class="cls_002"><span class="cls_002">F80  SEKSU/F380</span></td>
        </tr>
        <tr class="missingdata" style="margin-top:10px!important;">
          <td colspan="4" class="cls_002"><span class="cls_002">MXWSR 06/ILSOT</span></td>
            <!-- </div>  -->
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamNavigationSummary",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #LatamNavigationSummarySection {
    font-size: 16px;
  }
}
</style>
