var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"LatamOperationalImpactsSection"}},[_c('div',{staticClass:"columns",staticStyle:{"margin-top":"15px","border-top":"solid"}},[_c('table',{staticClass:"tabla-sin-bordes missingdata"},[_vm._l((13),function(index){return _c('col',{key:index,staticClass:"col-1"})}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_003",staticStyle:{"text-align":"center!important"},attrs:{"colspan":"13"}},[_c('span',{staticClass:"cls_003"},[_vm._v("OPERATIONAL IMPACTS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("VARIATIONS")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("ZFW")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("TIME")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("BURN")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("FL STEPS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("1000 KG")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("56000KG")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("0139")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("3792")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("EDRON/ 380  DOVRI/ 380")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("-1000 KG")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("54000KG")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("0138")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("3693")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("EDRON/ 380  DOVRI/ 380")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("FL +1")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("55000KG")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("0138")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("3733")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("EDRON/ 380  DOVRI/ 380")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("FL -1")])]),_c('td',{staticClass:"cls_002 right",attrs:{"colspan":"2"}},[_c('span',{staticClass:"cls_002"},[_vm._v("55000KG")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("0138")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("3755")])]),_c('td',{staticClass:"cls_002",attrs:{"colspan":"7"}},[_c('span',{staticClass:"cls_002"},[_vm._v("ITAVA/ 360  DOVRI/ 360")])])])
}]

export { render, staticRenderFns }