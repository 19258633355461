<template>
  <section id="LatamNavigationLogSection">
    <div class="columns" style="margin-top:15px;border-top:solid">
      <table class="tabla-sin-bordes">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="13" class="cls_003" style="text-align:center!important;"><span class="cls_003">NAVIGATION LOG</span></td>
        </tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">ATC CLRNC:</span></td>
          <td colspan="11" style="border-bottom:1px solid!important;"></td>
        </tr>
      </table>
    </div>
    <div class="columns" style="margin-top:30px;border: solid">
      <table class="tabla-sin-bordes navlog" cellspacing="0" cellpadding="0">
        <col v-for="index in 18" :key="index" class="col-1outof18">
        <thead>
        <tr>
          <td colspan="2" class="cls_002 right"><span class="cls_002">POSN</span></td>
          <td colspan="2" class="cls_002 missingdata"><span class="cls_002">FREQ</span></td>
          <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">AWY</span></td>
          <td class="cls_002 right"><span class="cls_002">FL</span></td>
          <td class="cls_002 right missingdata"><span class="cls_002">MAC<br><span class="comentario">mach?</span></span></td>
          <td class="cls_002 missingdata"><span class="cls_002">TRK</span></td>
          <td class="cls_002 right missingdata"><span class="cls_002">OAT</span></td>
          <td class="cls_002 right missingdata"><span class="cls_002">TP</span></td>
          <td class="cls_002 right"><span class="cls_002">ZND</span></td>
          <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">ZNBOF</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">ZNT</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">ETO/ATO</span></td>
        </tr>
        <tr>
          <td colspan="4" class="cls_002" style="text-align:center!important;"><span class="cls_002">COORD</span></td>
          <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">TMORA</span></td>
          <td colspa="2" class="cls_002 right"><span class="cls_002">GS</span></td>
          <td class="cls_002 right"><span class="cls_002 missingdata">AS<br><span class="comentario">tas?</span></span></td>
          <td class="cls_002"><span class="cls_002">WIND</span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">WSR</span></td>
          <td class="cls_002 right"><span class="cls_002">DTGO</span></td>
          <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">ACBOF</span></td>
          <td colspan="2" class="cls_002 right missingdata"><span class="cls_002">ACT<br><span class="comentario">time?</span></span></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">FUELRM</span></td>
        </tr>
        </thead>
        <tr>
          <td colspan="4" class="cls_003"><span class="cls_003">{{ result.flight.route.departure }}/<span class="missingdata">17R</span></span></td>
          <td colspan="7"></td>
          <td class="cls_002 right"><span class="cls_002">{{result.flight.distance_to_go}}</span></td>
          <td colspan="4"></td>
          <td colspan="2" class="cls_002 right"><span class="cls_002">6586</span></td>
        </tr>
        <template v-for="(point, index) in navLog.points">
          <tr :key="point.id">
            <td colspan="4" class="cls_003"><span class="cls_003">{{ point.name }}</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002 missingdata"><span class="comentario">airway?</span></span></td>
            <td class="cls_002 right"><span class="cls_002">{{ point.flvl }}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? '000' : point.mach}}</span></td>
            <td class="cls_002"><span class="cls_002 missingdata">243</span></td>
            <td class="cls_002 right"><span class="cls_002 missingdata">+07</span></td>
            <td class="cls_002 right"><span class="cls_002 missingdata">42</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ point.znd }}</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002 missingdata">371</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.znt }}</span></td>
            <td></td>
            <td class="cls_002"><span class="cls_002">/</span></td>
          </tr>
          <tr :key="index">
            <td colspan="2" class="cls_002"><span class="cls_002">{{ point.latitude }}</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.longitude }}</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002 missingdata">085</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ point.gs }}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ point.tas }}</span></td>
            <td colspan="2" class="cls_002"><span class="cls_002">{{ formatWind(point.wind) }}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ point.wsr }}</span></td>
            <td class="cls_002 right"><span class="cls_002">{{ point.distance_to_go }}</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002 missingdata">551</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.time }}</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.ttl_fuel }}</span></td>
          </tr>
          <tr style="height:15px;"></tr>
        </template>
      </table>
    </div>
    <div class="columns">
      <table class="tabla-sin-bordes" style="margin-top:15px;">
        <col v-for="index in 13" :key="index" class="col-1">
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">ATC ARRIV:</span></td>
          <td colspan="11" style="border-bottom:1px solid!important;"></td>
        </tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "LatamNavigationLog",
  props: ['navLog', 'navKey', 'result'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    formatWind (wind) {
      wind = String(wind)
      return wind.substring(0, 3) + '/' + wind.substring(3);
    }
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #LatamNavigationLogSection {
    font-size: 16px;
  }
}
</style>
