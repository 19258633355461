<template>
  <section id="ScatBurnOffSection">
    <div class="columns">
      <table class="tabla-sin-bordes missingdata">
        <col v-for="index in 14" :key="index" class="col-1outof14">
        <tr style="height:30px;"></tr> 
        <tr>
            <td colspan="2" class="cls_002"><span class="cls_002">BELOW ZFW</span></td>
            <td class="cls_002"><span class="cls_002">-2000</span></td>
            <td class="cls_002"><span class="cls_002">TIME</span></td>
            <td class="cls_002"><span class="cls_002">0504</span></td>
            <td class="cls_002"><span class="cls_002">BRNOF</span></td>
            <td class="cls_002"><span class="cls_002">16697</span></td>
            <td class="cls_002"><span class="cls_002">ZFW</span></td>
            <td class="cls_002"><span class="cls_002">70330</span></td>
            <td class="cls_002"><span class="cls_002">LRC</span></td>
            <td colspan="4"></td>
        </tr>
        <tr>
            <td colspan="2" class="cls_002"><span class="cls_002">390/KUNRU</span></td>
            <td colspan="2" class="cls_002"><span class="cls_002">380/KUA</span></td>
            <td class="cls_002"><span class="cls_002">390</span></td>
            <td colspan="9"></td>
        </tr>
        <tr style="height:15px;"></tr>
        <tr>
            <td colspan="2" class="cls_002"><span class="cls_002">ABOVE ZFW</span></td>
            <td class="cls_002"><span class="cls_002">2000</span></td>
            <td class="cls_002"><span class="cls_002">TIME</span></td>
            <td class="cls_002"><span class="cls_002">0505</span></td>
            <td class="cls_002"><span class="cls_002">BRNOF</span></td>
            <td class="cls_002"><span class="cls_002">17522</span></td>
            <td class="cls_002"><span class="cls_002">ZFW</span></td>
            <td class="cls_002"><span class="cls_002">74330</span></td>
            <td class="cls_002"><span class="cls_002">LRC</span></td>
            <td colspan="4"></td>
        </tr>
        <tr>
            <td colspan="2" class="cls_002"><span class="cls_002">370/KUNRU</span></td>
            <td colspan="2" class="cls_002"><span class="cls_002">380/KUA</span></td>
            <td class="cls_002"><span class="cls_002">390</span></td>
            <td colspan="9"></td>
        </tr>
        <tr><td colspan="14"><span class="comentario">Ayuda con esta sección</span></td></tr>
      </table>
    </div>
  </section>
</template>
<script>

export default {
  name: "ScatBurnOff"
};
</script>
<style>
@media print
{
  #ScatBurnOffSection {
    font-size: 20px;
  }
}
</style>
