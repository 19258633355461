<template>
  <section id="WindRoseTakeoffPerformanceCalculationsSection">
      <div class="columns">
          <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr>
                <td colspan="5">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td colspan="8" class="cls_002 center"><span class="cls_002">TAKEOFF PERFORMANCE CALCULATIONS</span></td>
                <td colspan="5">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="margin-top:19px;border-bottom: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style="height:20px;"></tr>
              <tr>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">RWY ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">WMAX= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">WACT= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">FLAPS ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">V1= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">VR= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">V2= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">TFLEX= ...</span></td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">RWY ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">WMAX= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">WACT= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">FLAPS ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">V1= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">VR= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">V2= ...</span></td>
                <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">TFLEX= ...</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">RES</span></td>
              </tr>
              <tr>
                <td colspan="3" class="cls_002"><span class="cls_002">MAC= ......</span></td>
                <td colspan="15" class="cls_002"><span class="cls_002">STAB SETTING ...</span></td>
              </tr>
              <tr>
                <td colspan="18">
                  <table class="tabla-sin-bordes" style="margin-top:14px;">
                    <tr style="border-top: 3px dashed;">
                      <td></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr class="comentario" style="height:20px;"></tr>
              <tr class="comentario">
                <td class="missingdata" colspan="18">Sección 8.1.10.9 Air Traffic Clearance va? pendiente? Dudas con la parte de emisiones CO2 sobre todo </td>
              </tr>
          </table> 
      </div>
  </section>
</template>
<script>

export default {
  name: "WindRoseTakeoffPerformanceCalculations",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    deg(deg) {
      if (deg < 0) {
        return 'M'+Math.abs(deg);
      }
      if (deg > 0) {
        return 'P'+deg;
      }
      return 0;
    }
  }
};
</script>
<style>
@media print
{
  #WindRoseTakeoffPerformanceCalculationsSection {
    font-size: 16px;
  }
}
</style>
