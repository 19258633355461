<template>
  <section id="RuslineWindSummarySection">
    <div class="columns">
      <div class="column is-12 has-text-left">
       <hr>
       CLIMB WIND AND TEMPERATURE SUMMARY 
      </div>
    </div> 
    <div class="columns">
      <div class="column is-1 has-text-left">
        <strong>WAYPOINT</strong>
      </div>
      <div class="column is-2 has-text-centered" v-for="(flvl, index) in result.wind_summary.climb.flight_levels" :key="index">
        <strong>FL{{flvl}}</strong> 
      </div>
    </div> 
    <div class="columns" v-for="(waypoint, key) in result.wind_summary.climb.waypoints" :key="key">
      <div class="column is-1 has-text-left">
        <span>{{key}}</span>  
      </div>
      <div class="column is-2" v-for="(value, key2) in waypoint" :key="key2">
        {{value.wind}}&nbsp;{{value.temp}}  
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 has-text-left">
        <hr>
        ENROUTE WIND AND TEMPERATURE SUMMARY 
      </div>
    </div>
    <div class="columns">
      <div class="column is-1 has-text-left">
        <strong>WAYPOINT</strong>
      </div>
      <div class="column is-2 has-text-centered" v-for="(flvl, index) in result.wind_summary.cruise.flight_levels" :key="index">
        <strong>FL{{flvl}}</strong> 
      </div>
    </div> 
    <div class="columns" v-for="(waypoint, key) in result.wind_summary.cruise.waypoints" :key="key">
      <div class="column is-1 has-text-left">
        <span>{{key}}</span>  
      </div>
      <div class="column is-2" v-for="(value, key2) in waypoint" :key="key2">
        {{value.wind}}&nbsp;{{value.temp}}  
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 has-text-left">
        <hr>
        DESCENT WIND AND TEMPERATURE SUMMARY 
      </div>
    </div>
    <div class="columns">
      <div class="column is-1 has-text-left">
        <strong>WAYPOINT</strong>
      </div>
      <div class="column is-2 has-text-centered" v-for="(flvl, index) in result.wind_summary.descent.flight_levels" :key="index">
        <strong>FL{{flvl}}</strong> 
      </div>
    </div> 
    <div class="columns" v-for="(waypoint, key) in result.wind_summary.descent.waypoints" :key="key">
      <div class="column is-1 has-text-left">
        <span>{{key}}</span>  
      </div>
      <div class="column is-2" v-for="(value, key2) in waypoint" :key="key2">
        {{value.wind}}&nbsp;{{value.temp}}  
      </div>
    </div>   
  </section>
</template>
<script>

export default {
  name: "RuslineWindSummary",
  props: ['result'],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
#RuslineWindSummarySection {
  font-size: 16px;
}
#RuslineWindSummarySection .columns {
  margin-top: 3px;
  margin-bottom: 3px;
  padding:2;
}
#RuslineWindSummarySection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:2px;
}
#RuslineWindSummarySection .tabla-sin-bordes {
  border-bottom:0px !important;
  border:0px !important;
}
#RuslineWindSummarySection .tabla-sin-bordes th, .tabla-sin-bordes td {
  border: 1px !important;
  padding-top: 0em;
  padding-bottom: 0em;
}
#RuslineWindSummarySection .tabla-sin-bordes tr td{
  border: 1px !important;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 1em;
  padding-right: 1.1em;
  text-align: right;
}
#RuslineWindSummarySection .tabla-sin-bordes tr td:first-child{
  font-weight: bolder;
  text-align: left !important;
}
@media print
{
  #RuslineWindSummarySection {
    font-size: 14px;
  }
}
</style>
