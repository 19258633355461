<template>
  <section id="ScatHeaderSection">
    <div class="columns">
      <table class="tabla-sin-bordes">
        <col v-for="index in 18" :key="index" class="1outof18">
        <tr> 
          <td ></td>
          <td colspan="17" class="cls_002"><span class="cls_002">Operation & ATC Flight Plan</span></td>
        </tr>
        <tr style="height:30px;"></tr> 
        <tr>
            <td colspan="18" class="cls_002"><span class="cls_002">CFP INPUT MESSAGE DATE TIME REF 011708<span class="comentario">mmddyy?</span></span></td>
        </tr>
        <tr>
            <td colspan="18" class="cls_002">
              <span class="cls_002">
                START OF CFP REF : 
                <span class="missingdata">BAMX3</span>  -  
                {{result.flight.flight_release}}
                <span class="comentario">Flight release? (flight number ingresado para nosotros)</span> 
                {{ result.id }}
                <span class="comentario">Id interno del plan?</span> 
                {{result.flight.route.departure}} {{result.flight.route.destination}}</span>
              </td>
        </tr>
        <tr style="height:30px;"></tr> 
       <tr> 
            <td colspan="18" class="cls_002 missingdata"><span class="cls_002">SCAT OCC: T:7010345167. 7017382576. WA:7018065934</span></td>
       </tr>
       <tr>
            <td colspan="18" class="cls_002 missingdata"><span class="cls_002">EMAIL: NAV4@SCAT.KZ  CDS@SCAT.KZ  SKYPE: VSV.OCC</span></td>
       </tr>
       <tr>
            <td colspan="18" class="cls_002 missingdata"><span class="cls_002">PAX 127 CH 23 INF 4</span></td>
       </tr>
       <tr class="comentario">
         <td colspan="18">Esto es información estática de la empresa? Sería de alguna forma configurable o debiese cambiarse algo? PAX, CH e INF sobre todo me intrigan</td>  
        </tr>
        <tr>
          <td colspan="18" class="cls_002 center"><span class="cls_002">----- SCAT FLIGHT PLAN -----</span></td>
        </tr>
        <tr style="height:30px;"></tr>
        <tr>
          <td colspan="18" class="cls_002"><span class="cls_002">NOTE - ALL SPEEDS -WIND-TAS-G/S IN KTS DISTANCE IN NMS</span></td>
        </tr>
        <tr>
          <td colspan="18" class="cls_002 missingdata"><span class="cls_002">NOTE - NAV D.B VALID FROM 08 NOVEMBER 2018  TILL 06 DECEMBER 2018</span><span class="comentario"><br>Relativo a la fecha de creación? me tienen un poco confundido las fechas en scat.</span></td>
        </tr>
        <tr>
          <td colspan="18" class="cls_002"><span class="cls_002">NOTE - NEW CFP REQUIRED IF ACTUAL TOW DIFFERENT MORE THEN FOR</span></td>
        </tr>
        <tr class="missingdata">
          <td></td>
          <td colspan="4" class="cls_002"><span class="cls_002">B767 - 2000KG</span></td>
          <td colspan="4" class="cls_002"><span class="cls_002">B757 - 1500KG</span></td>
          <td colspan="4" class="cls_002"><span class="cls_002">B737 - 1000KG</span></td>
          <td colspan="4" class="cls_002"><span class="cls_002">CRJ200 - 500KG</span></td>
          <td></td>
        </tr>
        <tr class="comentario">
          <td colspan="18">
            <span class="comentario"><br>Esto queda tal cual? para las aeronaves que se muestran ahí siempre? o dependería de las aeronaves que tenga
configuradas el cliente que ocupe este formato y si es así, los valores serían el MTOW o es una diferencia máxima que puede haber con el MTOW u otro valor? si es así, agregar un margen configurable?</span>
          </td>
        </tr>
        <tr>
          <td colspan="9" style="margin-right:0px;padding-right:0;">
            <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="1outof18">
                <tr>
                  <td colspan="18">
                    <table class="tabla-sin-bordes" style="margin-top:20px;">
                      <tr style="border-top: 3px dashed;">
                        <td></td>
                      </tr>
                      <tr>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002"><span class="cls_002">{{result.flight.route.departure}}</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002"><span class="cls_002"></span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002 right"><span class="cls_002">|</span></td>
                </tr>
                <tr>
                  <td colspan="18">
                    <table class="tabla-sin-bordes" style="margin-top:5px;">
                      <tr style="border-top: 3px dashed;">
                        <td></td>
                      </tr>
                      <tr>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002"><span class="cls_002">TOTAL</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002"><span class="cls_002"></span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002 right"><span class="cls_002">|</span></td>
                </tr>
                <tr>
                  <td colspan="18">
                    <table class="tabla-sin-bordes" style="margin-top:5px;">
                      <tr style="border-top: 3px dashed;">
                        <td></td>
                      </tr>
                      <tr>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002"><span class="cls_002">{{result.flight.route.destination}}</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002"><span class="cls_002"></span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="2" class="cls_002"><span class="cls_002">|</span></td>
                  <td style="padding-top:10px;padding-bottom:10px;" colspan="4" class="cls_002 right"><span class="cls_002">|</span></td>
                </tr>
                <tr>
                  <td colspan="18">
                    <table class="tabla-sin-bordes" style="margin-top:5px;">
                      <tr style="border-top: 3px dashed;">
                        <td></td>
                      </tr>
                      <tr>
                      </tr>
                    </table>
                  </td>
                </tr>
            </table>
          </td>
          <td colspan="9">
            <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="1outof18">
              <tr>
                <td colspan="5"></td>
                <td style="padding-top:10px;padding-bottom:10px;" class="cls_002"><span class="cls_002">PF</span></td>
                <td style="padding-top:10px;padding-bottom:10px;" colspan="12" class="cls_002">
                  <table class="tabla-sin-bordes" style="margin-top:20px;">
                    <tr style="border-top: 3px solid;">
                      <td></td>
                    </tr>
                    <tr>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <td style="padding-top:10px;padding-bottom:10px;" class="cls_002"><span class="cls_002">PM</span></td>
                <td style="padding-top:10px;padding-bottom:10px;" colspan="12" class="cls_002">
                  <table class="tabla-sin-bordes" style="margin-top:20px;">
                    <tr style="border-top: 3px solid;">
                      <td></td>
                    </tr>
                    <tr>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <td colspan="13" class="cls_002"><span class="cls_002"><span class="missingdata">SR/SS</span> {{result.flight.route.departure}} <span class="missingdata">0428/1456</span><span class="comentario"><br>Será sunrise/sunset (hora) ?</span></span></td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <td colspan="13" class="cls_002"><span class="cls_002">SR/SS {{result.flight.route.destination}} <span class="missingdata">0238/1151</span></span></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr style="height:30px;"></tr>
      </table>
    </div>
    <div class="columns">
      <table class="tabla-sin-bordes">
        <col v-for="index in 16" :key="index" class="col-1outof16">
        <tr>
          <td colspan="16" class="cls_002"><span class="cls_002">COMPANY ROUTE ID {{ result.id }} <span class="comentario">Nuevamente id interno del plan?</span></span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">G/C</span></td>
          <td class="cls_002"><span class="cls_002">DIST</span></td>
          <td class="cls_002"><span class="cls_002">1996</span></td>
          <td colspan="3"></td>
          <td nowrap="nowrap" colspan="5" class="cls_002"><span class="cls_002">CRZ WIND P45</span></td>
          <td nowrap="nowrap" colspan="5" class="cls_002"><span class="cls_002">AVG G/C TRACK 063</span></td>
        </tr>
        <tr>
          <td class="cls_002"><span class="cls_002">GR</span></td>
          <td class="cls_002"><span class="cls_002">DIST</span></td>
          <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">{{result.flight.distance_to_go}} NM /</span></td>
          <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">{{result.flight.distance_to_go_km}} KM /</span></td>
          <td colspan="5" class="cls_002 missingdata"><span class="cls_002">AVG ISA DEV M02<span class="comentario"><br>Desviación del ISA? cálculo nuevo? creo que calculamos una vareación de isa pero para otro fin, quizás habrá que guardarlo</span></span></td>
          <td colspan="5" class="cls_002"><span class="cls_002">MAX SHEAR {{ pad(2, result.nav_logs[0].mwsr) }} / {{ result.nav_logs[0].mwsr_point }}</span></td>
        </tr>
        <tr style="height:30px;"></tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">FLT</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">AC/REG</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">DATE</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">ROUTE</span></td>
          <td colspan="2" class="cls_002 missingdata"><span class="cls_002">MACH</span></td>
          <td class="cls_002"><span class="cls_002">STD</span></td>
          <td class="cls_002"><span class="cls_002">ETA</span></td>
          <td class="cls_002"><span class="cls_002">STA</span></td>
          <td class="cls_002"><span class="cls_002">ALTN</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">DATE MET</span></td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td colspan="2">
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td colspan="2">
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td colspan="2">
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td colspan="2">
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td>
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td>
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td>
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td>
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
          <td colspan="2">
            <table class="tabla-sin-bordes">
              <tr style="border-top: 3px dashed;">
                <td></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="cls_002"><span class="cls_002">{{result.flight.flight_release}}</span></td>
          <td colspan="2" class="cls_002"><span class="cls_002">{{ result.aircraft.aircraft.tail_number }}</span></td>
          <td colspan="2" class="cls_002 missingdata"><span class="cls_002">02/12/18<span class="comentario"><br>Ya comenté que estoy un poco confundido con las fechas en este plan?</span></span></td>
          <td class="cls_002"><span class="cls_002">{{result.flight.route.departure}}</span></td>
          <td class="cls_002"><span class="cls_002">{{result.flight.route.destination}}</span></td>
          <td colspan="2" class="cls_002 missingdata"><span class="cls_002">LRC</span></td>
          <td class="cls_002"><span class="cls_002">{{result.flight.etd}}<span class="comentario"><br>Usé ETD, pero debería ser un scheduled time?</span></span></td>
          <td class="cls_002"><span class="cls_002">{{result.flight.eta}}</span></td>
          <td class="cls_002 missingdata"><span class="cls_002">{{result.flight.eta}}<span class="comentario"><br>scheduled?</span></span></td>
          <td class="cls_002"><span class="cls_002">{{result.alternate_airports[0].route.destination}}</span></td>
          <td colspan="2" class="cls_002 missingdata"><span class="cls_002">011200<span class="comentario"><br>favor necesito aclarar fechas del plan</span></span></td>
        </tr>
        <tr>
          <td colspan="2"></td>  
          <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">DF {{result.aircraft.aircraft.deg}} PCT</span></td>
          <td colspan="2"></td>
          <td class="cls_002 missingdata"><span class="cls_002">{{ result.flight.route.departure_iata }}</span></td>
          <td class="cls_002 missingdata"><span class="cls_002">{{ result.flight.route.destination_iata }}</span></td>
          <td colspan="5"></td>
          <td class="cls_002"><span class="cls_002">{{ result.alternate_airports[0].route.destination_iata }}</span></td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td colspan="4"></td>
          <td colspan="2" class="cls_002"><span class="cls_002">APT ELEV</span></td>
          <td class="cls_002"><span class="cls_002">{{ result.flight.route.departure_elev }}</span></td>
          <td class="cls_002"><span class="cls_002">{{ result.flight.route.destination_elev }}</span></td>
          <td colspan="5"></td>
          <td class="cls_002"><span class="cls_002">{{result.alternate_airports[0].route.destination_elev}}</span></td>
          <td colspan="2"></td>
        </tr>
        <tr style="height:30px;"></tr>
      </table>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>

export default {
  name: "ScatHeader",
  props: ['result'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    pad (size, num) {
      var s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
    },
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
@media print
{
  #ScatHeaderSection {
    font-size: 20px;
  }
}
</style>
