<template>
  <section id="ScatNavigationLogSection">
    <div class="columns">
      <table class="tabla-sin-bordes">
        <col v-for="index in 14" :key="index" class="col-1outof14">
        <tr>
            <td class="cls_002"><span class="cls_002">TO</span></td>
            <td class="cls_002"><span class="cls_002">AWY</span></td>
            <td class="cls_002"><span class="cls_002">FL</span></td>
            <td class="cls_002 missingdata"><span class="cls_002">ISA</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">WIND</span></td>
            <td class="cls_002 right"><span class="cls_002">TAS</span></td>
            <td class="cls_002 right"><span class="cls_002">G/S</span></td>
            <td class="cls_002 missingdata"><span class="cls_002">IMT</span></td>
            <td class="cls_002 right"><span class="cls_002">DIST</span></td>
            <td class="cls_002"><span class="cls_002">ETO</span></td>
            <td class="cls_002 right"><span class="cls_002">ET</span></td>
            <td class="cls_002 right"><span class="cls_002">USED<span class="comentario"><br>no lo tnms para cda pto. pero puse reqd menos ttl_fuel de cada pto.  se debería tener?</span></span></td>
            <td class="cls_002 right"><span class="cls_002">FUSE</span></td>
        </tr>
        <tr>
            <td class="cls_002 missingdata"><span class="cls_002">FREQY</span></td>
            <td class="cls_002 missingdata"><span class="cls_002">GRMORA</span></td>
            <td colspan="2" class="cls_002"><span class="cls_002">LAT</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">LONG</span></td>
            <td class="cls_002 right"><span class="cls_002">MAC</span></td>
            <td class="cls_002 right"><span class="cls_002">SR</span></td>
            <td class="cls_002 missingdata"><span class="cls_002">FMT</span></td>
            <td class="cls_002"><span class="cls_002">DTGO</span></td>
            <td class="cls_002"><span class="cls_002">ATO</span></td>
            <td class="cls_002 right"><span class="cls_002">T/T</span></td>
            <td class="cls_002 right"><span class="cls_002">REM</span></td>
            <td class="cls_002 right"><span class="cls_002">FREM</span></td>
        </tr>
        <tr>
            <td></td>
            <td class="cls_002 missingdata"><span class="cls_002">FIR</span></td>
            <td colspan="5"></td>
            <td class="cls_002 missingdata right"><span class="cls_002">TRP</span></td>
            <td colspan="7"></td>
        </tr>
        <tr>
            <td>
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td colspan="2" class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td colspan="2" class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
            <td class="cls_002">
                <table class="tabla-sin-bordes">
                <tr style="border-top: 3px dashed;">
                    <td></td>
                </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td class="cls_002"><span class="cls_002">HESH</span></td>
            <td class="cls_002"><span class="cls_002">X</span></td>
            <td class="cls_002"><span class="cls_002">X</span></td>
            <td class="cls_002"><span class="cls_002">X</span></td>
            <td class="cls_002 right"><span class="cls_002"></span></td>
            <td class="cls_002"><span class="cls_002">X</span></td>
            <td class="cls_002 right"><span class="cls_002">X</span></td>
            <td class="cls_002 right"><span class="cls_002">X</span></td>
            <td class="cls_002"><span class="cls_002">X</span></td>
            <td class="cls_002"><span class="cls_002">X</span></td>
            <td></td>
            <td class="cls_002 right"><span class="cls_002">X</span></td>
            <td class="cls_002 right"><span class="cls_002">X</span></td>
            <td class="cls_002 right"><span class="cls_002">X</span></td>
        </tr>
        <tr>
            <td ></td>
            <td ></td>
            <td colspan="2" class="cls_002"><span class="cls_002">N2758.7</span></td>
            <td colspan="2" class="cls_002 right"><span class="cls_002">E03423.6</span></td>
            <td colspan="3"></td>
            <td class="cls_002"><span class="cls_002">2434</span></td>
            <td colspan="4"></td>
        </tr>
        <tr>
            <td></td>
            <td>&nbsp;</td>
            <td colspan="7"></td>
            <td></td>
            <td colspan="4"></td>
        </tr>
        <template v-for="(point, index) in navLog.points">
            <tr :key="point.name + 'tr1'">
                <td class="cls_002"><span class="cls_002">{{ point.name }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ point.airway }}</span></td>
                <td class="cls_002"><span class="cls_002">{{ (point.mach == 'CLB' || point.mach == 'DSC') ? point.mach : point.flvl}}</span></td>
                <td class="cls_002 missingdata"><span class="cls_002">M04</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.wind }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.tas }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.gs }}</span></td>
                <td class="cls_002 missingdata"><span class="cls_002">010</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.znd }}</span></td>
                <td nowrap="nowrap" class="cls_002"><span class="cls_002">.. ..</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.znt }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ result.plan_header.reqd_fuel - point.ttl_fuel }}</span></td>
                <td nowrap="nowrap" class="cls_002 right"><span class="cls_002">.. ..</span></td>
            </tr>
            <tr :key="point.name + 'tr2'">
                <td></td>
                <td class="cls_002 missingdata"><span class="cls_002">24</span></td>
                <td colspan="2" nowrap="nowrap" class="cls_002"><span class="cls_002">{{ point.latitude }}</span></td>
                <td colspan="2" class="cls_002 right"><span class="cls_002">{{ point.longitude }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.mach }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.wsr }}</span></td>
                <td class="cls_002"><span class="cls_002">010</span></td>
                <td class="cls_002 right"><span class="cls_002">{{result.flight.distance_to_go}}</span></td>
                <td nowrap="nowrap" class="cls_002"><span class="cls_002">.. ..</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.time }}</span></td>
                <td class="cls_002 right"><span class="cls_002">{{ point.ttl_fuel }}</span></td>
                <td nowrap="nowrap" class="cls_002 right"><span class="cls_002">.. ..</span></td>
            </tr>
            <tr :key="point.name + 'tr3'">
                <td></td>
                <td class="cls_002 missingdata"><span class="cls_002">&nbsp;</span></td>
                <td colspan="5">
                <td class="cls_002 missingdata right"><span class="cls_002">&nbsp;</span></td>
                <td colspan="7"></td>
            </tr>
        </template>
        <tr style="height:15px;">
            <td colspan="14" class="cls_002"><span class="cls_002">DIST/T 2434  AI<R/D 2207  AVG FF 03392  AVG W/C P45 MAX SHEAR 04/SOGOT</span></td>
        </tr>
        <tr>
            <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">ATIS INFORM</span></td>
            <td colspan="2" v-for="index in 6" :key="index" class="cls_002 right"><span class="cls_002">.. .. .. ..</span></td>
        </tr>
        <tr>
            <td colspan="2" v-for="index in 7" :key="index" class="cls_002 right"><span class="cls_002">.. .. .. ..</span></td>
        </tr>
        <tr style="height:20px;"></tr>
        <tr>
            <td colspan="3" class="cls_002 right"><span class="cls_002">DISPATCHED BY</span></td>
            <td colspan="2" class="cls_002"><span class="cls_002">______________</span></td>
            <td colspan="4" class="cls_002 right"><span class="cls_002">ACCEPTED BY CAPTAIN</span></td>
            <td colspan="2" class="cls_002"><span class="cls_002">______________</span></td>
            <td colspan="3"></td>
        </tr>
        <tr>
            <td colspan="3"></td>
            <td nowrap="nowrap" colspan="2" class="cls_002"><span class="cls_002">{{ result.flight.flight_dispatcher }}</span></td>
            <td colspan="4"></td>
            <td colspan="5" class="cls_002"><span class="cls_002">{{ result.flight.captain }}<span class="comentario">No salía en el ejemplo pero supongo que si aparece el dispatcher, el capitán también?</span></span></td>
        </tr>
      </table>
    </div>
  </section>
</template>
<script>

export default {
  name: "ScatNavigationLog",
  props: ['result', 'navLog'],
  watch: {
    'result': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    pad (size, num) {
      var s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
};
</script>
<style>
@media print
{
  #ScatNavigationLogSection {
    font-size: 20px;
  }
}
</style>
