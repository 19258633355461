var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"LatamAlternateSummarySection"}},[_c('div',{staticClass:"columns",staticStyle:{"border-top":"solid"}},[_c('table',{staticClass:"tabla-sin-bordes"},[_vm._l((13),function(index){return _c('col',{key:index,staticClass:"col-1"})}),_vm._m(0),_vm._m(1),_vm._m(2)],2)]),_c('div',{staticClass:"columns"},[_c('table',{staticClass:"tabla-sin-bordes",staticStyle:{"margin-top":"15px"}},[_vm._l((13),function(index){return _c('col',{key:index,staticClass:"col-1"})}),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_003",staticStyle:{"text-align":"center!important"},attrs:{"colspan":"13"}},[_c('span',{staticClass:"cls_003"},[_vm._v("ALTERNATE SUMMARY")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("ALTN")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("DIST")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("ROUTE")])]),_c('td',{attrs:{"colspan":"5"}}),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("FL")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("WC")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("TIME")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("FUEL")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("MFOD")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("SCCF")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("145")])]),_c('td',{attrs:{"colspan":"6"}},[_c('span',{staticClass:"cls_002"},[_vm._v("SCFA  ")]),_c('span',{staticClass:"cls_002"},[_vm._v("TATIO1B  ")]),_c('span',{staticClass:"cls_002"},[_vm._v("TATIO  ")]),_c('span',{staticClass:"cls_002"},[_vm._v("TATIO2B  ")]),_c('span',{staticClass:"cls_002"},[_vm._v("SCCF  ")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("290")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("T010")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("0028")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("1325")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("2398")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002",attrs:{"colspan":"13"}},[_c('span',{staticClass:"cls_002"},[_vm._v("Additional Alternates")])]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("ALTN")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("DIST")])]),_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("ROUTE")])]),_c('td',{attrs:{"colspan":"5"}}),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("FL")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("WC")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("TIME")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("FUEL")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("MFOD")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("SCDA")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("219")])]),_c('td',{attrs:{"colspan":"6"}}),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("300")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("T004")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("0039")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("1669")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("2742")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"cls_002"},[_c('span',{staticClass:"cls_002"},[_vm._v("SCAT")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("274")])]),_c('td',{attrs:{"colspan":"6"}}),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("390")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("H012")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("0047")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("2039")])]),_c('td',{staticClass:"cls_002 right"},[_c('span',{staticClass:"cls_002"},[_vm._v("3112")])])])
}]

export { render, staticRenderFns }