<template>
  <section id="ScatFormatSection" class="printable" v-bind:class="{'showcomments' : showComments}">
    <div class="no-print">
      <a style="position:fixed;right:30px;" class="button is-info is-medium is-rounded" @click="print"><i class="fa fa-print"></i>&nbsp;Print</a>
      <article class="message is-warning no-print" v-if="hasAlerts">
        <div class="message-body">
          <p v-for="(alert, key) in alerts" :key="key">{{alert.description}}</p>
        </div>
      </article>
    </div>
    <button v-if="showComments" type="button" class="button is-block is-danger no-print" @click="toggleShowComments">Ocultar Comentarios</button>
    <button v-if="!showComments" type="button" class="button is-block is-success no-print" @click="toggleShowComments">Mostrar Comentarios</button>
    <scat-header v-if="flightPlan.plan_header" :result="result"></scat-header>
    <scat-time-and-fuel v-if="flightPlan.plan_header" :result="result"></scat-time-and-fuel>
    <scat-a-t-c v-if="flightPlan.plan_header" :result="result"></scat-a-t-c>
    <scat-navigation-log v-if="flightPlan.plan_header" :result="result" :navLog="navLogs[0]"></scat-navigation-log>
    <table class="tabla-sin-bordes">
    </table>
    <div class="columns" style="margin-top:15px;" v-if="flightPlan.client_wind_format != 'None'">
      <table class="tabla-sin-bordes">
        <col v-for="index in 18" :key="index" class="col-1outof18">
        <tr>
          <td colspan="4"><span class="cls_002"><span class="missingdata">BAMX3<span class="comentario">qué es esto?</span></span></span></td>
          <td colspan="10">
            <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr style="border-bottom: 3px dashed;">
                <td colspan="18" class="cls_002 center"><span class="cls_002">START OF WIND AND TEMPERATURE SUMMARY {{ flightPlan.flight.route.departure }} TO {{ flightPlan.flight.route.destination }}</span></td>
              </tr>
            </table>
          </td>
          <td colspan="4"></td>
        </tr>
      </table>
    </div>
    <div class="columns" v-if="flightPlan.client_wind_format == 'Rusline'">
      <div class="column is-12 has-text-centered">
        <rusline-wind-summary v-if="flightPlan.wind_summary" :flightPlan="flightPlan"></rusline-wind-summary>
      </div>
    </div>
    <div class="columns" v-if="flightPlan.client_wind_format == 'Latam'">
      <div class="column is-12 has-text-centered">
        <latam-wind-summary v-if="flightPlan.wind_summary" :flightPlan="flightPlan"></latam-wind-summary>
      </div>
    </div>
    <div class="columns" style="margin-top:15px;" v-if="flightPlan.client_wind_format != 'None'">
      <table class="tabla-sin-bordes">
        <col v-for="index in 18" :key="index" class="col-1outof18">
        <tr>
          <td colspan="4"><span class="cls_002">BAMX3</span></td>
          <td colspan="10">
            <table class="tabla-sin-bordes">
              <col v-for="index in 18" :key="index" class="col-1outof18">
              <tr style="border-bottom: 3px dashed;">
                <td colspan="18" class="cls_002 center"><span class="cls_002">END OF WIND AND TEMPERATURE SUMMARY {{ flightPlan.flight.route.departure }} TO {{ flightPlan.flight.route.destination }}</span></td>
              </tr>
            </table>
          </td>
          <td colspan="4"></td>
        </tr>
      </table>
    </div>
    <scat-burn-off v-if="flightPlan.plan_header"></scat-burn-off>
    <scat-alternate-nav-logs v-if="flightPlan.plan_header" :result="result" :navLogs="navLogs"></scat-alternate-nav-logs>
    <airports-weather v-if="flightPlan.airports" :flightPlan="flightPlan"></airports-weather>
    <airports-notam v-if="flightPlan.airports" :flightPlan="flightPlan"></airports-notam>
    <table class="tabla-sin-bordes">
    <tr class="comentario">
      <td colspan="18">ICAO FLIGHT PLAN PENDIENTE</td>
    </tr>
    </table>
  </section>
</template>
<script>

import ScatHeader from "./scat/ScatHeader.vue"
import ScatTimeAndFuel from "./scat/ScatTimeAndFuel.vue"
import ScatATC from "./scat/ScatATC.vue"
import ScatNavigationLog from "./scat/ScatNavigationLog.vue"
import LatamWindSummary from "../LatamWindSummary.vue";
import RuslineWindSummary from "../RuslineWindSummary.vue";
import ScatBurnOff from "./scat/ScatBurnOff.vue"
import ScatAlternateNavLogs from "./scat/ScatAlternateNavLogs.vue"
import AirportsWeather from "../AirportsWeather.vue";
import AirportsNotam from "../AirportsNotam.vue";

export default {
  name: "ScatFormat",
  props: ['flightPlan'],
  data() {
    return {
      alerts: this.flightPlan.alerts,
      navLogs: this.flightPlan.nav_logs,
      showComments: true
    }
  },
  computed: {
    hasAlerts() {
      return this.alerts.length > 0;
    }
  },
  watch: {
    'flightPlan': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    print () {
      window.print();
    },
    toggleShowComments () {
      this.showComments = !this.showComments
    }
  },
  components: {
      ScatHeader,
      ScatTimeAndFuel,
      ScatATC,
      ScatNavigationLog,
      RuslineWindSummary,
      LatamWindSummary,
      ScatBurnOff,
      ScatAlternateNavLogs,
      AirportsWeather,
      AirportsNotam
  }
};
</script>
<style>
#ScatFormatSection {
  font-size: 18px;
}
#ScatFormatSection span.cls_002 {
    font-family:Courier New,serif;
    color:rgb(0,0,0);
    font-weight:normal;
    font-style:normal;
    text-decoration: none
}
#ScatFormatSection div.cls_002 {
    font-family:Courier New,serif;
    color:rgb(0,0,0);
    font-weight:normal;
    font-style:normal;
    text-decoration: none
}

#ScatFormatSection .card-header{
  background-color: lightgrey;
}
#ScatFormatSection .card {
  min-height: 100%;
}
#ScatFormatSection .columns {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:1;
}
#ScatFormatSection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:2px;
}
#ScatFormatSection .tabla-sin-bordes tr td {
  text-align: left !important;
  padding-right: 8px;
  padding-right:30px;
}
#ScatFormatSection .text-underlined-10 {
  border-bottom: 3px dashed #000;
  padding-bottom: 8px;
}
#ScatFormatSection .tabla-sin-bordes tr td:last-child {
  padding-right:2px!important;
}

#ScatFormatSection .tabla-sin-bordes {
  width:100%;
  text-align: left !important;
  table-layout: fixed;
  border-bottom:0px !important;
  border:0px !important;
}
#ScatFormatSection table.navlog {
  font-size:100%!important;
  border-spacing:0 !important;
  border-collapse: collapse !important;
  padding:0!important;
}
#ScatFormatSection table tr.dotted {
  background-image: radial-gradient(circle at 50% 50%, black 1px, transparent 2px);
  background-size: 8px 50px; /* size in y-axis is equal to td height + padding top + padding bottom */
  background-position: 2px 50px; /* position in y-axis is -1 * size/2 - 2px */
  background-repeat: round; /* makes dots repeat in round manner */
  border-collapse: collapse;
}

#ScatFormatSection.showcomments .missingdata {
  background-color: red;
}
#ScatFormatSection .comentario {
  background-color: greenyellow;
}
#ScatFormatSection:not(.showcomments) .comentario {
  display: none;
}
/* #ScatFormatSection .comentario {
  display: none;
}
#ScatFormatSection .missingdata {
  display: none;
}
#ScatFormatSection .columns.missingdata {
  background-color: white!important;
} */
#ScatFormatSection .tabla-sin-bordes tr td:first-child{
  font-weight: bolder;
  padding-left:0em;
  width:7.69%;
  margin-left: 0px;
  padding-left: 0px;
}
#ScatFormatSection table tr td.right {
  text-align: right!important;
}
#ScatFormatSection table tr td.center {
  text-align: center!important;
}
#ScatFormatSection col {
  padding-left:0em;
}
#ScatFormatSection .col-1 {
  width: 7.6923076923076925%;
}
#ScatFormatSection .col-1-5 {
  width: 11.538461538461538%;
}
#ScatFormatSection .col-2 {
  width: 15.384615384615385%;
}
#ScatFormatSection .col-3 {
  width: 23.076923076923077%;
}
#ScatFormatSection .col-4 {
  width: 30.76923076923077%;
}
#ScatFormatSection .col-5 {
  width: 38.46153846153846%;
}
#ScatFormatSection .col-6 {
  width: 46.15384615384615%;
}
#ScatFormatSection .col-7 {
  width: 53.84615384615385%;
}
#ScatFormatSection .col-8 {
  width: 61.53846153846154%;
}
#ScatFormatSection .col-1outof18 {
  width: 5.6%;
}
#ScatFormatSection .col-1outof16 {
  width: 6.25%;
}
#ScatFormatSection .col-1outof14 {
  width: 7.1428%;
}
#ScatFormatSection .level-item .heading{
  font-size:1em;
}
#ScatFormatSection .level-item .title{
  font-size:1em;
}
@media print
{
    #ScatFormatSection {
      font-size: 14px!important;
    }
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
