<template>
  <section id="LatamFormatSection" class="printable" v-bind:class="{'showcomments' : showComments}">
    <div class="no-print">
      <a style="position:fixed;right:30px;" class="button is-info is-medium is-rounded" @click="print"><i class="fa fa-print"></i>&nbsp;Print</a>
      <article class="message is-warning no-print" v-if="hasAlerts">
        <div class="message-body">
          <p v-for="(alert, key) in alerts" :key="key">{{alert.description}}</p>
        </div>
      </article>
    </div>
    <button v-if="showComments" type="button" class="button is-block is-danger no-print" @click="toggleShowComments">Ocultar Comentarios</button>
    <button v-if="!showComments" type="button" class="button is-block is-success no-print" @click="toggleShowComments">Mostrar Comentarios</button>
    <latam-header v-if="flightPlan.plan_header" :result="result"></latam-header>
    <latam-weight-calculations v-if="flightPlan.plan_header" :result="result"></latam-weight-calculations>
    <latam-time-and-fuel v-if="flightPlan.plan_header" :result="result"></latam-time-and-fuel>
    <latam-navigation-summary v-if="flightPlan.plan_header" :result="result"></latam-navigation-summary>
    <latam-mel-cdl-items v-if="flightPlan.plan_header" :result="result"></latam-mel-cdl-items>
    <latam-alternate-summary v-if="flightPlan.plan_header" :result="result"></latam-alternate-summary>
    <latam-navigation-log v-if="flightPlan.plan_header" :result="result" :navLog="navLogs[0]" :navKey="0"></latam-navigation-log>
    <latam-operational-impacts v-if="flightPlan.plan_header" :result="result"></latam-operational-impacts>
    <latam-weight-analysis v-if="flightPlan.plan_header" :result="result"></latam-weight-analysis>
    <div class="columns" v-if="flightPlan.client_wind_format == 'Rusline'">
      <div class="column is-12 has-text-centered">
        <div class="column is-12 has-text-centered" style="border-top:solid;">
          <span class="cls_003">WIND AND TEMPERATURE SUMMARY</span>
        </div>
      </div> 
      <rusline-wind-summary v-if="flightPlan.wind_summary" :flightPlan="flightPlan"></rusline-wind-summary>
    </div>
    <div class="columns is-multiline" v-if="flightPlan.client_wind_format == 'Latam'">
      <div class="column is-12 has-text-centered" style="border-top:solid;">
          <span class="cls_003">WIND AND TEMPERATURE SUMMARY</span>
      </div>
      <latam-wind-summary v-if="flightPlan.wind_summary" :flightPlan="flightPlan"></latam-wind-summary>
    </div>
    <latam-alternate-navigation-log v-if="flightPlan.plan_header" :flightPlan="flightPlan" :navLog="navLogs[1]" :navKey="1"></latam-alternate-navigation-log>  
    <airports-weather v-if="flightPlan.airports" :flightPlan="flightPlan"></airports-weather>
    <airports-notam v-if="flightPlan.airports" :flightPlan="flightPlan"></airports-notam>
  </section>
</template>
<script>

import LatamHeader from "./latam/LatamHeader.vue";
import LatamWeightCalculations from "./latam/LatamWeightCalculations.vue";
import LatamTimeAndFuel from "./latam/LatamTimeAndFuel.vue";
import LatamNavigationSummary from "./latam/LatamNavigationSummary.vue";
import LatamMelCdlItems from "./latam/LatamMelCdlItems.vue";
import LatamAlternateSummary from "./latam/LatamAlternateSummary.vue";
import LatamNavigationLog from "./latam/LatamNavigationLog.vue";
import LatamOperationalImpacts from "./latam/LatamOperationalImpacts.vue";
import LatamWeightAnalysis from "./latam/LatamWeightAnalysis.vue";
import LatamAlternateNavigationLog from "./latam/LatamAlternateNavigationLog.vue";
import LatamWindSummary from "../LatamWindSummary.vue";
import RuslineWindSummary from "../RuslineWindSummary.vue";
import AirportsWeather from "../AirportsWeather.vue";
import AirportsNotam from "../AirportsNotam.vue";

export default {
  name: "LatamFormat",
  props: ['flightPlan', 'result'],
  data() {
    return {
      alerts: this.flightPlan.alerts,
      navLogs: this.flightPlan.nav_logs,
      showComments: true
    }
  },
  computed: {
    hasAlerts() {
      return this.alerts.length > 0;
    }
  },
  watch: {
    'flightPlan': function(plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    print () {
      window.print();
    },
    toggleShowComments () {
      this.showComments = !this.showComments
    }
  },
  components: {
    LatamHeader,
    LatamWeightCalculations,
    LatamTimeAndFuel,
    LatamNavigationSummary,
    LatamMelCdlItems,
    LatamAlternateSummary,
    LatamNavigationLog,
    LatamOperationalImpacts,
    LatamWeightAnalysis,
    LatamAlternateNavigationLog,
    RuslineWindSummary,
    LatamWindSummary,
    AirportsWeather,
    AirportsNotam
  }
};
</script>
<style>
#LatamFormatSection {
  font-size: 18px;
}
#LatamFormatSection span.cls_002 {
  font-family:Courier New,serif;
  color:rgb(0,0,0);
  font-weight:normal;
  font-style:normal;
  text-decoration: none
}
#LatamFormatSection div.cls_002 {
  font-family:Courier New,serif;
  color:rgb(0,0,0);
  font-weight:normal;
  font-style:normal;
  text-decoration: none
}
#LatamFormatSection .cls_003 {
  font-family:Courier New,serif;
  color:rgb(0,0,0);
  font-weight:bold;
  font-style:normal;
  text-decoration: none
}
#LatamFormatSection div.cls_003 {
  font-family:Courier New,serif;
  color:rgb(0,0,0);
  font-weight:bold;
  font-style:normal;
  text-decoration: none
}
#LatamFormatSection .card-header{
  background-color: lightgrey;
}
#LatamFormatSection .card {
  min-height: 100%;
}
#LatamFormatSection .columns {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:1;
}
#LatamFormatSection .column {
  margin-top: 1px;
  margin-bottom: 1px;
  padding:2px;
}
#LatamFormatSection .tabla-sin-bordes tr td {
  text-align: left !important;
  padding-right: 8px;
  padding-right:30px;
}
#LatamFormatSection .tabla-sin-bordes tr td:last-child {
  padding-right:2px!important;
}

#LatamFormatSection .tabla-sin-bordes {
  width:100%;
  text-align: left !important;
  table-layout: fixed;
  border-bottom:0px !important;
  border:0px !important;
}
#LatamFormatSection table.navlog {
  font-size:100%!important;
  border-spacing:0 !important;
  border-collapse: collapse !important;
  padding:0!important;
}
#LatamFormatSection table tr.dotted {
  background-image: radial-gradient(circle at 50% 50%, black 1px, transparent 2px);
  background-size: 8px 50px; /* size in y-axis is equal to td height + padding top + padding bottom */
  background-position: 2px 50px; /* position in y-axis is -1 * size/2 - 2px */
  background-repeat: round; /* makes dots repeat in round manner */
  border-collapse: collapse;
}

#LatamFormatSection.showcomments .missingdata {
  background-color: red;
}
#LatamFormatSection .comentario {
  background-color: greenyellow;
}
#LatamFormatSection:not(.showcomments) .comentario {
  display: none;
}
/* #LatamFormatSection .comentario {
  display: none;
}
#LatamFormatSection .missingdata {
  display: none;
}
#LatamFormatSection .columns.missingdata {
  background-color: white!important;
} */
#LatamFormatSection .tabla-sin-bordes tr td:first-child{
  font-weight: bolder;
  padding-left:0em;
  width:7.69%;
  margin-left: 0px;
  padding-left: 0px;
}
#LatamFormatSection table tr td.right {
  text-align: right!important;
}
#LatamFormatSection col {
  padding-left:0em;
}
#LatamFormatSection .col-1 {
  width: 7.6923076923076925%;
}
#LatamFormatSection .col-1-5 {
  width: 11.538461538461538%;
}
#LatamFormatSection .col-2 {
  width: 15.384615384615385%;
}
#LatamFormatSection .col-3 {
  width: 23.076923076923077%;
}
#LatamFormatSection .col-4 {
  width: 30.76923076923077%;
}
#LatamFormatSection .col-5 {
  width: 38.46153846153846%;
}
#LatamFormatSection .col-6 {
  width: 46.15384615384615%;
}
#LatamFormatSection .col-7 {
  width: 53.84615384615385%;
}
#LatamFormatSection .col-8 {
  width: 61.53846153846154%;
}
#LatamFormatSection .col-1outof18 {
  width: 5.6%;
}
#LatamFormatSection .level-item .heading{
  font-size:1em;
}
#LatamFormatSection .level-item .title{
  font-size:1em;
}
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
