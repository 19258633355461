<script >
export default {
  name: "NavigationLogFooter",
  props: ['logs']
};
</script>

<template>
  <table class="table is-fullwidth is-hoverable">
    <caption>SUMMARY OF ALTERNATE AIRPORTS</caption>
    <thead>
    <tr>
      <th>
        <abbr title="Position">APTO</abbr>
      </th>
      <th>
        <abbr title="Position">T.FUEL</abbr>
      </th>
      <th>
        <abbr title="Position">TIME</abbr>
      </th>
      <th>
        <abbr title="Position">FL</abbr>
      </th>
      <th>
        <abbr title="Position">DIST</abbr>
      </th>
      <th>
        <abbr title="Position">W/C</abbr>
      </th>
      <th>
        <abbr title="Position">FOB</abbr>
      </th>
      <th>
        <abbr title="Position">PLD</abbr>
      </th>
      <th>
        <abbr title="Position">BRWT</abbr>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="altLog in logs" >
      <td>{{ altLog.footer.apto }}</td>
      <td>{{ altLog.footer.t_fuel }}</td>
      <td>{{ altLog.footer.time }}</td>
      <td>{{ altLog.footer.fl }}</td>
      <td>{{ altLog.footer.dist }}</td>
      <td>{{ altLog.footer.average_wcomp }}</td>
      <td>{{ altLog.footer.fob }}</td>
      <td>{{ altLog.footer.pld }}</td>
      <td>{{ altLog.footer.brwt }}</td>
    </tr>
    </tbody>
  </table>
</template>